import { useForm } from '@chiroup/hooks';
import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useContext, useState } from 'react';
import Button, { ButtonColors } from '../common/Button';
import IconButton from '../common/IconButton';

import { ConfirmModal, LoadingPage, Textarea } from '@chiroup/components';
import { createDayjs } from '@chiroup/core/functions/time';
import { MeContext } from '../../contexts/me.context';

type InvoiceNote = {
  id: number;
  note: string;
  createdAt: string;
  userId: number;
  createdBy?: string;
};

type InvoiceNotesLogProps = {
  invoiceNotes: {
    data: any;
    refetch: () => Promise<any>;
    save: (val: Partial<{ note: string }>) => Promise<any>;
    remove: (noteId: number) => Promise<any>;
    isFetching: boolean;
  };
};

const InvoiceNotesLog: React.FC<InvoiceNotesLogProps> = ({ invoiceNotes }) => {
  const { data, save, remove, isFetching } = invoiceNotes;
  const { selectedLocationFull } = useContext(MeContext);
  const { value, registerSubmit, onChange, isDirty, isSubmitting, reset } =
    useForm<{ note: string }>({ note: '' }, {});
  const [isDeletingNote, setIsDeletingNote] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showEditor, setShowEditor] = useState(false);

  const notes = data?.rows || [];

  const onSuccess = async () => {
    reset();
    setShowEditor(false);
    // await refetch();
  };

  const handleDeleteNote = async () => {
    setIsDeleting(true);
    try {
      await remove(isDeletingNote!);
      setIsDeletingNote(null);
      // await refetch();
    } catch (err) {
      console.error('Error deleting invoice note:', err);
    } finally {
      setIsDeleting(false);
    }
  };

  if (isFetching) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="flex flex-col space-y-4">
        {!notes || notes.length === 0 ? (
          <div className="flex justify-center text-gray-600">- no notes -</div>
        ) : (
          <ul className="divide-y divide-gray-100">
            {notes.map((note: InvoiceNote) => (
              <li
                key={note.id}
                className="flex items-center justify-between gap-x-6 py-5"
              >
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm/6 font-semibold text-gray-900">
                      {note.note}
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2 text-xs/5 text-gray-500">
                    <span>
                      Created on{' '}
                      {createDayjs({
                        datetime: note.createdAt,
                        timezone: selectedLocationFull.timezone || 'UTC',
                      })?.format('MM/DD/YYYY [at] h:mm A')}{' '}
                      by {note.createdBy}
                    </span>
                  </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <IconButton
                    className="h-6 w-6 col-span-1 text-gray-400 hover:text-gray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
                    icon={<TrashIcon />}
                    tooltip="Delete"
                    onClick={() => setIsDeletingNote(note.id)}
                  />
                </div>
              </li>
            ))}
          </ul>
        )}

        <div className="rounded bg-white dark:bg-darkGray-800">
          {showEditor ? (
            <>
              <Textarea
                name="note"
                value={value.note}
                onChange={onChange('note')}
              />
              <div className="flex justify-end flex-row space-x-4 mt-6">
                <Button
                  text="Cancel"
                  onClick={() => {
                    setShowEditor(false);
                    reset(); // Reset the form when canceling
                  }}
                  color={ButtonColors.plainWithBorder}
                />
                <Button
                  text="Save"
                  onClick={registerSubmit(save, { onSuccess })}
                  color={ButtonColors.primary}
                  loading={isSubmitting}
                  disabled={!isDirty}
                />
              </div>
            </>
          ) : (
            <div className="flex justify-end">
              <Button
                text="+ Add"
                onClick={() => setShowEditor(true)}
                color={ButtonColors.primary}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        title="Delete Note"
        isOpen={!!isDeletingNote}
        confirm={handleDeleteNote}
        close={() => setIsDeletingNote(null)}
        description="Are you sure you want to delete this note?"
        loading={isDeleting}
      />
    </>
  );
};

export default InvoiceNotesLog;
