import { ConfirmModal, ReasonModal, Select } from '@chiroup/components';
import {
  AppointmentForUI,
  AppointmentStatuses,
} from '@chiroup/core/types/Appointment.type';
import { TransactionItemSubtypeEnum } from '@chiroup/core/types/PatientTransaction.type';
import { useForm } from '@chiroup/hooks';
import React, { useState } from 'react';

let reasonOptions = [
  { text: 'Scheduling conflict', value: 'Scheduling conflict' },
  { text: 'Personal health issue', value: 'Personal health issue' },
  { text: 'Family issue', value: 'Family issue' },
  { text: 'Transportation issue', value: 'Transportation issue' },
  { text: 'Financial constraint', value: 'Financial constraint' },
  { text: 'Feeling better', value: 'Feeling better' },
  { text: 'Sore after treatment', value: 'Sore after treatment' },
  { text: 'Lack of benefit', value: 'Lack of benefit' },
  { text: 'Seeking alternative care', value: 'Seeking alternative care' },
  { text: 'Other', value: 'Other' },
];
const reasonOptionsObj: { [key: string]: boolean } = {
  'Scheduling conflict': true,
  'Personal health issue': true,
  'Family issue': true,
  'Transportation issue': true,
  'Financial constraint': true,
  'Feeling better': true,
  'Sore after treatment': true,
  'Lack of benefit': true,
  'Seeking alternative care': true,
  Other: true,
};
type Props = {
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  error?: {
    active: boolean;
    title?: string;
    text: string;
    cancelText?: string;
  };
  close: () => void;
  loading?: boolean;
  icon?: React.ReactNode;
  primaryColor?: string;
  noColor?: boolean;
  children?: React.ReactNode;
  noShowCancelFees?: {
    ID: number;
    name: string;
    type: string;
    structure: string;
    value: number;
    clinicId: number;
    deleted: number;
  }[];
  appointment?: AppointmentForUI | null;
  priceOfTreatment?: number;
  selectedAppointmentId: string | null;
  setCancelModalOpen: (arg: boolean) => void;
  saveAppointment?: ({
    appointment,
    notify,
    removeRecurringId,
    fee,
  }: {
    appointment: Partial<AppointmentForUI>;
    notify?: boolean | undefined;
    removeRecurringId?: boolean | undefined;
    fee?:
      | {
          amount: number;
          subtype: string;
          description: string;
          name: string;
        }
      | undefined;
  }) => Promise<any>;
  refetchTransaction?: () => Promise<any>;
  saveAppointmentFromHealthComSchedule?: (id: string, reason: string) => void;
  fromChiroUp?: boolean;
};

export const CancelConfirmModal: React.FC<Props> = ({
  title = 'Are you sure?',
  description,
  confirmText = 'Yes',
  cancelText = 'Cancel',
  error = { active: false, title: 'Error', text: '', cancelText: 'Close' },
  close,
  isOpen,
  noShowCancelFees,
  appointment,
  priceOfTreatment,
  selectedAppointmentId,
  setCancelModalOpen,
  saveAppointment,
  refetchTransaction,
  saveAppointmentFromHealthComSchedule,
  fromChiroUp,
}) => {
  const cancellationFeesOptions = noShowCancelFees?.reduce(
    (arr: { text: string; value: number; disabled: boolean }[], fee) => {
      //TODO: @Jeremy. I'm not sure how cancellation fees are showing in prod
      // The added fees are set to TransactionItemSubtypeEnum.Canceled not 'Cancellation'
      if (fee.type === TransactionItemSubtypeEnum.Canceled) {
        arr.push({
          text: fee.name + ' ' + fee.value + ' ' + fee.structure,
          value: fee.ID,
          disabled:
            !priceOfTreatment &&
            !fee.structure.includes(TransactionItemSubtypeEnum.Override),
        });
      }
      return arr;
    },
    [],
  );
  const { value, onChange, errors, isSubmitting, registerSubmit } = useForm<{
    reason: string;
    feeId: number;
    value: number;
    type: string;
  }>(
    {},
    cancellationFeesOptions?.length
      ? {
          feeId: {
            required: {
              message: 'Choosing type of fee is required',
            },
          },
          reason: {
            required: {
              message: 'Reason is required.',
            },
          },
        }
      : {
          reason: {
            required: {
              message: 'Reason is required.',
            },
          },
        },
  );
  const [otherReason, setOtherReason] = useState<{
    value: string;
    onChange: (val: string) => void;
  } | null>(null);

  const onChangeReason = () => (val: string) => {
    onChange('reason')('');
    if (val === 'Other') {
      setOtherReason({
        value: value.reason || '',
        onChange: onChange('reason'),
      });
    } else {
      onChange('reason')(val);
    }
  };

  const markAndApplyFees = async () => {
    //if noShowCancelFees then it is being called from healthcom-schedule
    if (!fromChiroUp) {
      await saveAppointmentFromHealthComSchedule?.(
        selectedAppointmentId || '',
        value.reason || '',
      );
      await refetchTransaction?.();
      return;
    }

    const fee = noShowCancelFees?.find((fee) => fee.ID === value.feeId);
    const feeToSend = { amount: 0, name: '', description: '', subtype: '' };
    if (fee) {
      feeToSend.name = fee.name;
      feeToSend.description = fee.name;
      feeToSend.subtype = fee.type;
      if (fee.structure === '$ Override') {
        feeToSend.amount = Number(fee.value);
      } else if (priceOfTreatment && fee.structure === '$ Additional Fee') {
        feeToSend.amount = Number(fee.value) + Number(priceOfTreatment);
      } else if (priceOfTreatment && fee.structure === '% Additional Fee') {
        feeToSend.amount =
          (Number(fee.value) / 100) * Number(priceOfTreatment) +
          Number(priceOfTreatment);
      } else if (
        priceOfTreatment &&
        fee.structure === '$ Discount From Total'
      ) {
        feeToSend.amount = Number(priceOfTreatment) - Number(fee.value);
      } else if (
        priceOfTreatment &&
        fee.structure === '% Discount From Total'
      ) {
        feeToSend.amount =
          Number(priceOfTreatment) -
          (Number(fee.value) / 100) * Number(priceOfTreatment);
      }
    }
    await cancelAppointmentConfirm(feeToSend);
    await refetchTransaction?.();
  };

  const cancelAppointmentConfirm = async (fee?: {
    amount: number;
    subtype: string;
    description: string;
    name: string;
  }) => {
    if (!selectedAppointmentId) return;
    try {
      await saveAppointment?.({
        appointment: {
          ...appointment,
          id: selectedAppointmentId,
          status: AppointmentStatuses.Canceled,
          cancellationReason: value.reason,
          slots: appointment?.slots || [],
        },
        fee,
      });
      await refetchTransaction?.();
    } catch (err) {
      console.error(err);
    }
    setCancelModalOpen(false);
  };

  reasonOptions = reasonOptions.filter((option) => {
    return (
      option.value === value.reason || reasonOptionsObj[option.value as string]
    );
  });

  if (
    value?.reason?.length &&
    !reasonOptions?.some((option) => option.value === value.reason)
  ) {
    reasonOptions.push({
      text: value.reason,
      value: value.reason,
    });
  }

  return (
    <ConfirmModal
      isOpen={isOpen}
      close={close}
      title={title}
      error={error}
      description={description}
      cancelText={error.active ? error.cancelText || cancelText : cancelText}
      confirmText={confirmText}
      confirm={registerSubmit(markAndApplyFees, {})}
      loading={isSubmitting}
      confirmDisabled={isSubmitting || error.active}
      noColor={true}
    >
      <form>
        <Select
          className="col-span-6"
          name="reason"
          label="Cancellation reason"
          options={reasonOptions}
          value={value.reason}
          onChange={onChangeReason()}
          errors={errors?.fieldErrors?.reason}
          limit={1}
        />
        {!!cancellationFeesOptions?.length && (
          <Select
            className="col-span-6"
            name="feeId"
            label="Fee"
            options={[
              ...cancellationFeesOptions,
              {
                text: 'Cancel - No fee',
                value: 'Cancel - No fee',
                onClick: true,
              },
            ]}
            value={value.feeId}
            onChange={onChange('feeId')}
            errors={errors?.fieldErrors?.feeId}
            limit={1}
          />
        )}
      </form>
      <ReasonModal
        close={() => setOtherReason(null)}
        value={otherReason?.value || ''}
        onChange={otherReason?.onChange}
        openDialog={!!otherReason}
        result="Other reason"
      />
    </ConfirmModal>
  );
};
