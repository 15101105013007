import { SELECT_OPTION_TYPE } from '@chiroup/core/constants/globals';
import { Room } from '@chiroup/core/types/Room.type';
import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { MeContext } from '../contexts/me.context';
import clinicService from '../services/clinic.service';

const getQuery = (
  clinicId?: number,
  locationId?: number,
  hasAccess?: boolean,
) => {
  return () => {
    if (!clinicId || !hasAccess) {
      return [];
    }

    return clinicService.getLocationRooms(clinicId, locationId);
  };
};

type Props = {
  clinicId?: number;
  locationId?: number;
  supportSelectNone?: boolean;
  includeArchivedInSelect?: boolean;
};

const useLocationRooms = ({
  clinicId,
  locationId,
  supportSelectNone = true,
  includeArchivedInSelect = true,
}: Props) => {
  const { hasAccess } = useContext(MeContext);
  const { data, isFetching, refetch } = useQuery<Room[]>(
    ['locationRoom', clinicId, locationId, includeArchivedInSelect],
    getQuery(clinicId, locationId, hasAccess('scheduling')),
    {
      refetchOnWindowFocus: false,
      staleTime: 1500000,
      retry: 0,
      keepPreviousData: true,
    },
  );
  const options = useMemo(() => {
    if (!data) return [];
    const base = (
      supportSelectNone
        ? [{ value: '-1', text: '- none -', data: { color: 'white' } }]
        : []
    ) as any[];
    return base.concat(
      data
        ?.filter((room) => (includeArchivedInSelect ? true : !room.archived))
        .map((room: any) => ({
          value: room.id,
          text: room.name,
          data: { color: room?.color },
        })) ?? ([] as SELECT_OPTION_TYPE[]),
    );
  }, [data, includeArchivedInSelect, supportSelectNone]);
  const save = async (room: Partial<Room>) => {
    const res = await clinicService.updateLocationRoom({
      clinicId: clinicId as number,
      locationId: locationId as number,
      room,
    });
    refetch();
    return res;
  };

  return {
    isFetching,
    rooms: data,
    options,
    save,
  };
};

export default useLocationRooms;
