import {
  ACTIVITY_CHANGE_TYPE,
  CRUD_ENUM,
} from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import {
  getTransactionActivityDisplay,
  PatientTransactionActivity,
} from '@chiroup/core/types/PatientTransaction.type';
import React, { useMemo } from 'react';
import { transactionItemActivityMessage } from './TabActivity';
import { titleCase } from '@chiroup/core/functions/titleCase';

interface ActivitySummaryProps {
  // activityList: ACTIVITY_CHANGE_TYPE[];
  activityItem?: PatientTransactionActivity;
  // activityList={activityItem.data?.updates || []}
  trace?: boolean;
}

const TabActivitySummary: React.FC<ActivitySummaryProps> = ({
  activityItem,
  trace = false,
}) => {
  const activityList = useMemo(() => {
    return activityItem?.data?.updates || [];
  }, [activityItem]);

  const version = useMemo(() => {
    return activityItem?.version || 0;
  }, [activityItem]);

  const updates = useMemo(() => {
    const items: ACTIVITY_CHANGE_TYPE[] = [];
    activityList.forEach((item: ACTIVITY_CHANGE_TYPE) => {
      if (!item) return;
      if (item?.field === 'summary') return; // Calculated field.
      if ((item?.field ?? '').indexOf('Payor Claim') > -1) return; // Who cares?
      if ((item?.msg ?? '').indexOf('"Tax') > -1) return; // Who cares?
      items.push(item);
    });
    return items;
  }, [activityList]);

  if (!updates?.length) {
    return (
      <div className="w-full rounded-md p-3 ring-1 ring-inset ring-gray-300 dark:ring-darkGray-600 dark:bg-darkGray-800">
        <div className="ml-2 text-sm leading-6 text-gray-500 dark:text-darkGray-400 italic">
          Detail not available.
        </div>
      </div>
    );
  }

  // console.log({ TabActivitySummary: { activityItem, updates } });

  return (
    <div className="w-full rounded-md p-3 ring-1 ring-inset ring-gray-300 dark:ring-darkGray-600 dark:bg-darkGray-800">
      <ul className="list-disc ml-5 text-sm leading-6 text-gray-500 dark:text-darkGray-400">
        {updates.length > 0 ? (
          updates.map((item, index) => (
            <li key={index} className="text-gray-900 dark:text-darkGray-100">
              <SingleActivityItem item={item} version={version} trace={trace} />
            </li>
          ))
        ) : (
          <li className="text-gray-900 dark:text-darkGray-100">{`${getTransactionActivityDisplay(
            activityItem,
          )}.`}</li>
        )}
      </ul>
      {trace ? <pre>{ChiroUpJSON.pretty({ version })}</pre> : null}
    </div>
  );
};

interface SingleActivityItemProps {
  item: ACTIVITY_CHANGE_TYPE;
  version?: number;
  trace?: boolean;
}

const SingleActivityItem: React.FC<SingleActivityItemProps> = ({
  item,
  version,
  trace = false,
}) => {
  if (!item) return null;
  // Summary is a computed field, any changes will be captured by changes to the items.

  const messageHasPeriod = /\.$/.test(item?.msg ?? '');
  if (!!item?.msg && !messageHasPeriod) {
    item.msg = `${item.msg}.`;
  }

  if (item?.updates?.length && version === 2) {
    // console.log({ parent: { item, version } });
    const parentDescription =
      titleCase(item?.field ?? '') || '- unknown field -';
    return (
      <>
        <div>{parentDescription}</div>
        <ul className="list-disc ml-5 text-sm leading-6 text-gray-500 dark:text-darkGray-400">
          {item.updates.map((update, index) => (
            <li key={index} className="text-gray-900 dark:text-darkGray-100">
              {transactionItemActivityMessage(update, version)}
              {trace ? <pre>{ChiroUpJSON.pretty(update)}</pre> : null}
            </li>
          ))}
          {trace ? <pre>{ChiroUpJSON.pretty(item)}</pre> : null}
        </ul>
      </>
    );
  }

  // Guess if it is an outlier from first pass at activity.
  if (
    item?.operation === CRUD_ENUM.Update &&
    item?.to === item?.from &&
    item?.msg
  ) {
    return (
      <div>
        {trace ? <pre>{ChiroUpJSON.pretty(item)}</pre> : null}
        {item.msg}
      </div>
    );
  }

  return (
    <div>
      {trace ? <pre>{ChiroUpJSON.pretty(item)}</pre> : null}
      {transactionItemActivityMessage(item, version)}
    </div>
  );
};

export default TabActivitySummary;
