import {
  ButtonColors,
  ConfirmModal,
  NewButton,
  OpenClosedStates,
  Select,
} from '@chiroup/components';
import { classNames } from '@chiroup/core/functions/classNames';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import {
  DisciplineInsuranceBenefit,
  Insurance,
} from '@chiroup/core/types/PatientInsurance.type';
import {
  PatientTransaction,
  PatientTransactionItemType,
} from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import SubContainer from '../../../../../../components/layout/SubContainer';
import { MeContext } from '../../../../../../contexts/me.context';
import usePatient from '../../../../hooks/usePatient';
import {
  RootCallbackEvent,
  RootCallbackProps,
} from '../../../billing/transactions/ConsolidatedTransactionModal';
import { InsuranceError } from '../services/CodeEncounterDiagnosisServices';
import EncounterInsuranceModal from './EncounterInsuranceModal';
import EncounterInsurancePolicy from './EncounterInsurancePolicy';

type Props = {
  activePolicies: any[];
  allowBillingPriorityChange: boolean;
  billingKey: string;
  billingProfileId?: number;
  courtesyBilling?: boolean;
  disciplineId?: number;
  handleBillingProfileChange?: (e: number | null) => void;
  handleCourtesyBillingChange: (e: boolean) => void;
  handlePolicyChange: (e: any) => void;
  handleSuperBillChange?: (e: boolean) => void;
  horizontal?: boolean;
  insuranceErrors: InsuranceError[];
  isBillingStarted?: boolean;
  onRemoveInsurance: (insurance: Partial<AppointmentInsuranceType>) => void;
  parentIsa?: string;
  patientId: string;
  policies: Insurance[] | any[] | undefined | null;
  readOnly: boolean;
  rootCallback?: (props: RootCallbackProps) => void;
  services: PatientTransactionItemType[];
  superBill?: boolean;
  transaction: PatientTransaction | null | undefined;
};

const EncounterInsurance: React.FC<Props> = ({
  activePolicies,
  allowBillingPriorityChange = false,
  billingKey,
  disciplineId,
  handleBillingProfileChange,
  handlePolicyChange,
  horizontal = false,
  insuranceErrors,
  isBillingStarted = false,
  onRemoveInsurance,
  patientId,
  policies,
  readOnly = false,
  rootCallback,
  services,
  transaction,
}) => {
  const { hasRole } = useContext(MeContext);
  const { data: patient, isFetching: isFetchingPatient } = usePatient({
    id: patientId,
  });
  const [insuranceModalOpen, setInsuranceModalOpen] =
    useState<OpenClosedStates>(OpenClosedStates.Closed);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [selectedInsurance, setSelectedInsurance] = useState<any>(null);

  // const { data: userBillingProfiles, refetch: refetchUserBillingProfiles } =
  //   useUserBillingProfiles({ userId: providerId || '' });

  // useEffect(() => {
  //   if (providerId) refetchUserBillingProfiles();
  // }, [providerId, refetchUserBillingProfiles]);

  const insurances = useMemo(() => {
    return transaction?.insurances ?? [];
  }, [transaction]);

  const usedNonBillableInsurance = useMemo(() => {
    const items = insurances ?? [];
    if (items.length === 0) return false;
    return items.some((i) => !i.billable);
  }, [insurances]);

  const onChangePolicy = (e: any) => {
    if (!e) return;
    if (e?.billable) {
      handlePolicyChange(e);
    } else if (!e?.billable && !isBillingStarted) {
      setConfirmModalOpen(true);
      setSelectedInsurance(e);
    }
  };

  const onConfirmNonBillableInsurance = () => {
    handleBillingProfileChange?.(null);
    handlePolicyChange(selectedInsurance);
    setConfirmModalOpen(false);
    setSelectedInsurance(null);
  };

  const policyOptions = useMemo(() => {
    return activePolicies;
    // return activePolicies.filter((ap) => {
    //   const alreadyThere = insurances.some((i: any) => {
    //     const insurancePayorID = i.payorID;
    //     const apPayorID = ap?.value?.payor || ap.payorID;
    //     if (!insurancePayorID || !apPayorID) return false;
    //     return insurancePayorID === apPayorID;
    //   });
    //   return !alreadyThere;
    // });
  }, [activePolicies]);

  return (readOnly && insurances?.length) || !readOnly ? (
    <div className={classNames(horizontal ? 'flex flex-col w-full' : 'mt-4')}>
      <div
        className={classNames(
          'relative flex items-center mb-4',
          horizontal ? 'flex-col' : 'flex-row justify-between', // Counterintuitive, but true
        )}
      >
        <div
          id="insurance-bar"
          className={classNames(
            'text-lg font-medium leading-5',
            horizontal
              ? 'mb-2.5 relative sm:mt-px sm:pt-2 flex justify-start w-full mt-4'
              : 'block mt-4',
            isBillingStarted ? 'text-accent-600' : 'text-primary-600',
          )}
        >
          Insurance
        </div>
        {/* <pre>
          {ChiroUpJSON.pretty({
            hasRole: hasRole([
              UserRoles.Admin,
              UserRoles.Biller,
              UserRoles.Staff,
            ]),
            readOnly,
            some: !insurances.some((p) => !p.billable),
            // policies,
            insurances,
          })}
        </pre> */}
        {hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) &&
          !usedNonBillableInsurance &&
          !readOnly && (
            <div
              className={classNames(
                'flex flex-row gap-2',
                horizontal ? 'w-full justify-end' : undefined,
              )}
            >
              {transaction?.provider && transaction.provider.id !== '-1' ? (
                <>
                  <div className="flex flex-row">
                    <div className="text-sm italic font-light pt-4 pr-2">
                      {readOnly || insurances.some((i) => !i.billable)
                        ? ''
                        : activePolicies?.length
                          ? 'Select a policy to add'
                          : 'No more active policies'}
                    </div>
                    <div className="flex-grow flex">
                      {!readOnly &&
                        !insurances.some((i) => !i.billable) &&
                        !!activePolicies.length && (
                          <Select
                            name="activePolicies"
                            label=""
                            onChange={onChangePolicy}
                            options={policyOptions}
                            limit={1}
                            className="w-48 mb-4"
                            disabled={policyOptions.length === 0 || readOnly}
                          />
                        )}
                    </div>
                  </div>

                  <div className="whitespace-nowrap pt-2">
                    <NewButton
                      text="Insurance"
                      onClick={() =>
                        setInsuranceModalOpen(OpenClosedStates.Open)
                      }
                      color={
                        isBillingStarted
                          ? ButtonColors.accent
                          : ButtonColors.primary
                      }
                    />
                  </div>
                </>
              ) : (
                <div className="text-sm ml-1 mt-4">
                  Please merge this purchase or select a provider to add
                  insurance.
                </div>
              )}
            </div>
          )}
      </div>
      {insurances?.some((p) => !p.billable) && (
        <div className={'text-sm text-gray-400 col-span-10 ml-4 mb-4'}>
          <cite>
            No other policies can be added with a non-billable policy.
          </cite>
        </div>
      )}
      {!policies?.some((p: Insurance) => p.active) && (
        <cite className={'text-sm text-gray-400 col-span-10 p-2 ml-2'}>
          Patient has no active policies.
        </cite>
      )}
      {patientId &&
        (!!policies?.some((p: Insurance) => p.active) ||
          !!insurances?.length) && (
          <SubContainer title={''} subtitle={''} divider={false}>
            <div className="flex flex-col gap-1">
              {insurances?.map((p, i) => {
                const disciplineBenefit = policies
                  ?.find(
                    (insurance: Insurance) => insurance.id === p.insuranceID,
                  )
                  ?.disciplineBenefits?.find(
                    (db: DisciplineInsuranceBenefit) =>
                      db.disciplineId === disciplineId,
                  );

                return (
                  <Fragment key={p.insuranceID}>
                    {/* <pre>{ChiroUpJSON.pretty(p)}</pre> */}
                    <EncounterInsurancePolicy
                      allowBillingPriorityChange={allowBillingPriorityChange}
                      appointmentInsurance={p}
                      appointmentInsuranceIndex={i}
                      disciplineBenefit={disciplineBenefit}
                      insuranceErrors={insuranceErrors}
                      insurances={insurances}
                      key={p.insuranceID}
                      onRemoveInsurance={onRemoveInsurance}
                      readOnly={readOnly}
                      rootCallback={rootCallback}
                      services={services}
                      transaction={transaction}
                    />
                  </Fragment>
                );
              })}
            </div>
          </SubContainer>
        )}

      {!isFetchingPatient && patient?.ID && (
        <EncounterInsuranceModal
          isOpen={insuranceModalOpen}
          close={() => setInsuranceModalOpen(OpenClosedStates.Closed)}
          patient={patient}
          billingKey={billingKey}
          serviceCodes={
            services?.length
              ? services.reduce<string[]>((acc, s) => {
                  if (s?.code) acc.push(s.code);
                  return acc;
                }, [])
              : []
          }
          onInsuranceAdded={(ins) => {
            rootCallback?.({
              event: RootCallbackEvent.UpdatePolicies,
              policies: [...(policies ?? []), ins],
              item: ins?.serviceAllowedAmounts || [],
            });
          }}
        />
      )}
      <ConfirmModal
        isOpen={confirmModalOpen}
        close={() => setConfirmModalOpen(false)}
        confirm={onConfirmNonBillableInsurance}
        icon={<ExclamationTriangleIcon className="h-6 w-6 text-orange-300" />}
        title="Non-billable insurance"
        description="This insurance policy is not billable. If you add this policy, all others will be removed and no further policies may be added. Are you sure you want to add this policy?"
      />
    </div>
  ) : null;
};

export default EncounterInsurance;
