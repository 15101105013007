import { classNames } from '@chiroup/core/functions/classNames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { LIST_LIMIT } from '../../../constants';
import { Dispatch, SetStateAction, useMemo } from 'react';

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  count?: number;
  limit?: number;
};

const Pagination = ({
  setPage,
  currentPage,
  count = 0,
  limit = LIST_LIMIT,
}: Props) => {
  const totalPages = useMemo(() => {
    return count <= limit ? 1 : Math.ceil(count / limit);
  }, [count, limit]);

  const pages = useMemo(() => {
    if (totalPages <= 7) {
      return [Array.from({ length: totalPages }, (_, i) => i + 1)];
    }

    let firstPages = [1, 2, 3];
    let lastPages = [totalPages - 2, totalPages - 1, totalPages];
    const pageArr: Array<number[] | string> = [firstPages];

    if (currentPage > 3 && currentPage < totalPages - 2) {
      const between = [currentPage - 1, currentPage, currentPage + 1];
      const betweenIncludedInFirst3Pages = between.some((page) =>
        firstPages.includes(page),
      );
      const betweenIncludedInLast3Pages = between.some((page) =>
        lastPages.includes(page),
      );

      const largestPossible =
        !betweenIncludedInFirst3Pages && !betweenIncludedInLast3Pages;
      if (largestPossible) {
        firstPages = [1, 2];
        lastPages = [totalPages - 1, totalPages];
      }

      if (!betweenIncludedInFirst3Pages) {
        pageArr.push('...');
      }
      pageArr.push(
        between.filter(
          (page) => !firstPages.includes(page) && !lastPages.includes(page),
        ),
      );
      if (!betweenIncludedInLast3Pages) {
        pageArr.push('...');
      }
    } else {
      pageArr.push('...');
    }
    pageArr.push(lastPages);
    return pageArr;
  }, [totalPages, currentPage]);

  const firstPage = useMemo(() => {
    return currentPage === 1;
  }, [currentPage]);

  const lastPage = useMemo(() => {
    return totalPages === currentPage;
  }, [currentPage, totalPages]);

  const firstItemNumber = useMemo(() => {
    return (currentPage - 1) * limit + 1;
  }, [currentPage, limit]);

  const lastItemNumber = useMemo(() => {
    return currentPage * limit > count ? count : currentPage * limit;
  }, [currentPage, limit, count]);

  if (totalPages === 1) {
    return null;
  }

  return (
    <div>
      <div className="flex items-center justify-between border-t border-gray-300 dark:border-darkGray-600 bg-white dark:bg-darkGray-600 dark:text-white px-10 py-3 sm:px-4">
        <div className="flex flex-1 justify-between sm:hidden">
          <span
            onClick={() => !firstPage && setPage((prev) => --prev)}
            className="relative inline-flex items-center rounded-md border border-gray-300 dark:border-darkGray-600 bg-white dark:bg-darkGray-600 dark:text-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </span>
          <span
            onClick={() => !lastPage && setPage((prev) => ++prev)}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 dark:border-darkGray-600 bg-white dark:bg-darkGray-600 dark:text-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </span>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing <span className="font-medium">{firstItemNumber}</span> to{' '}
              <span className="font-medium">{lastItemNumber}</span> of{' '}
              <span className="font-medium">{count}</span> results
            </p>
          </div>
          <div>
            <nav
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <span
                onClick={() => !firstPage && setPage((prev) => --prev)}
                className={
                  firstPage
                    ? 'relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-300 ring-1 ring-inset ring-gray-100 dark:ring-darkGray-400 focus:z-20 focus:outline-offset-0 cursor-not-allowed'
                    : 'relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-darkGray-400 hover:bg-gray-50 dark:hover:bg-darkGray-700 focus:z-20 focus:outline-offset-0 cursor-pointer'
                }
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </span>
              {pages?.map((page, i) => (
                <div key={`arr-${i}`}>
                  {Array.isArray(page) ? (
                    page?.map((pageNumber: number) => (
                      <span
                        key={pageNumber}
                        onClick={() => setPage(pageNumber)}
                        className={classNames(
                          currentPage === pageNumber
                            ? 'relative z-10 inline-flex items-center bg-primary-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 cursor-default'
                            : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-darkGray-400 hover:bg-gray-50 dark:hover:bg-darkGray-700 focus:z-20 focus:outline-offset-0 cursor-pointer',
                        )}
                      >
                        {pageNumber}
                      </span>
                    ))
                  ) : (
                    <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-darkGray-400 hover:bg-gray-50 dark:hover:bg-darkGray-700 focus:z-20 focus:outline-offset-0">
                      {page}
                    </span>
                  )}
                </div>
              ))}
              <span
                onClick={() => !lastPage && setPage((prev) => ++prev)}
                className={
                  lastPage
                    ? 'relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-300 ring-1 ring-inset ring-gray-100 dark:ring-darkGray-400 focus:z-20 focus:outline-offset-0 cursor-not-allowed'
                    : 'relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-darkGray-400 hover:bg-gray-50 dark:hover:bg-darkGray-700 focus:z-20 focus:outline-offset-0 cursor-pointer'
                }
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
