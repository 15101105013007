import { Fragment } from 'react';
import { Button, ButtonColors, icons, SelectOption } from '@chiroup/components';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

type Props = {
  options?: SelectOption[];
  onClick: (patientTransactionId?: number) => Promise<void>;
  disabled?: boolean;
  loading?: boolean;
  refill: () => Promise<any>;
  refillingSaltShaker?: boolean;
};

const IntegratedTransactionSaltButton: React.FC<Props> = ({
  options = [],
  onClick,
  disabled = false,
  loading = false,
  refill,
  refillingSaltShaker = false,
}) => {
  return (
    <div className="inline-flex items-center" role="group">
      <Button
        text="SALT"
        icon={icons.saltShaker}
        onClick={() => onClick()}
        disabled={disabled || loading}
        loading={loading}
        color={ButtonColors.primary}
        className="rounded-r-none"
        tooltip={[
          'Same as Last Time: Imports the services and diagnosis from the',
          'most-recent purchase transaction. The allowed amounts are set to',
          'the primary insurance when available. Only available when this',
          'transaction has no existing services.',
        ].join(' ')}
      />
      <Menu as="div" className="relative">
        <Menu.Button
          disabled={disabled || loading}
          onClick={() => {
            if (options.length === 0) {
              refill();
            }
          }}
          className="
                      inline-flex items-center justify-center
                      p-2 text-sm leading-5
                      bg-primary-600 text-white
                      hover:bg-primary-400
                      border-l border-white
                      focus:outline-none
                      rounded-l-none rounded-r
                      disabled:bg-gray-300
                      disabled:cursor-not-allowed
                    "
        >
          {refillingSaltShaker ? (
            // Spinner SVG instead of chevron
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
          ) : (
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95 -translate-y-1"
          enterTo="transform opacity-100 scale-100 translate-y-0"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100 translate-y-0"
          leaveTo="transform opacity-0 scale-95 -translate-y-1"
        >
          <Menu.Items
            className="absolute right-0 bottom-full mb-2 w-56 origin-bottom-right
                         divide-y divide-gray-100 rounded-md bg-white shadow-lg
                         ring-1 ring-black ring-opacity-5 focus:outline-none z-100"
          >
            <div className="px-1 py-1">
              {options.map(
                (option) =>
                  !option.disabled && (
                    <Menu.Item key={option.value}>
                      {({ active }) => (
                        <button
                          onClick={() => onClick(option.value)}
                          className={`group flex items-center w-full 
                                        px-4 py-2 text-sm leading-5 text-gray-700 
                                        focus:outline-none ${
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : ''
                                        }`}
                        >
                          {option.text}
                        </button>
                      )}
                    </Menu.Item>
                  ),
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default IntegratedTransactionSaltButton;
