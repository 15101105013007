import { OpenClosedStates } from '@chiroup/components';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { Fragment, useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Tooltip } from 'react-tooltip';

type Props = {
  title: string;
  subtitle?: string | React.ReactNode;
  slideOverState: OpenClosedStates;
  updateSlideOverState: (val: OpenClosedStates) => void;
  buttons?: React.ReactNode;
  children: React.ReactNode;
  postCloseCallback?: () => void;
  noPadding?: boolean;
  stickyButtons?: boolean;
  pushBody?: boolean;
};

const SlideOver: React.FC<Props> = ({
  title,
  subtitle,
  slideOverState,
  updateSlideOverState,
  buttons,
  children,
  postCloseCallback,
  noPadding = false,
  stickyButtons = false,
  pushBody = false,
}) => {
  const hasButtons = useMemo(() => {
    if (Array.isArray(buttons)) {
      return buttons.length > 0;
    }
    return !!buttons;
  }, [buttons]);

  return pushBody ? (
    <>
      <div
        className={classNames(
          slideOverState === OpenClosedStates.Open
            ? 'w-full h-full relative'
            : 'display-none',
        )}
      >
        <div
          className={classNames(
            'absolute top-0 right-0 w-full flex h-full flex-col bg-white dark:bg-darkGray-900 border-l border-gray-300 dark:border-darkGray-600 shadow-xl',
            stickyButtons ? '' : 'overflow-y-scroll',
          )}
        >
          <div className="py-6 px-4 sm:px-6 bg-gray-50 dark:bg-darkGray-700 border-b border-gray-300 dark:border-darkGray-600 shadow-sm">
            <div className="flex items-start justify-between">
              <div className="flex flex-col">
                <div className="text-lg font-medium text-gray-900 dark:text-white">
                  {title}
                </div>
                {!!subtitle && typeof subtitle === 'string' ? (
                  <div className="text-sm text-gray-500">{subtitle}</div>
                ) : (
                  subtitle
                )}
              </div>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    updateSlideOverState(OpenClosedStates.Closed);
                    if (postCloseCallback) {
                      postCloseCallback();
                    }
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          {stickyButtons ? (
            <Scrollbars className="h-full w-full">
              <div
                className={
                  noPadding
                    ? 'relative flex-1'
                    : 'relative my-6 flex-1 px-4 sm:px-6'
                }
              >
                {children}
              </div>
            </Scrollbars>
          ) : (
            <div
              className={
                noPadding
                  ? 'relative flex-1'
                  : 'relative my-6 flex-1 px-4 sm:px-6'
              }
            >
              {children}
            </div>
          )}
          {hasButtons && (
            <div className="flex-shrink-0 px-4 border-t border-gray-300 dark:border-darkGray-600 py-5 dark:bg-darkGray-700">
              <div className="space-x-3 flex justify-end">{buttons}</div>
            </div>
          )}
        </div>
      </div>
      <Tooltip id={`slideover-tooltip`} className="w-56" />
    </>
  ) : (
    <Transition.Root
      show={slideOverState === OpenClosedStates.Open}
      as={Fragment}
    >
      <Dialog as="div" className="relative z-40" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div
            className={classNames(
              'pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10',
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                <div
                  className={`flex h-full flex-col ${
                    stickyButtons ? '' : 'overflow-y-scroll'
                  } bg-white dark:bg-darkGray-900 shadow-xl`}
                >
                  <div className="py-6 px-4 sm:px-6 bg-gray-50 dark:bg-darkGray-700">
                    <div className="flex items-start justify-between">
                      <div className="flex flex-col">
                        <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white">
                          {title}
                        </Dialog.Title>
                        {!!subtitle && typeof subtitle === 'string' ? (
                          <div className="text-sm text-gray-500">
                            {subtitle}
                          </div>
                        ) : (
                          subtitle
                        )}
                      </div>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                          onClick={() => {
                            updateSlideOverState(OpenClosedStates.Closed);
                            if (postCloseCallback) {
                              postCloseCallback();
                            }
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {stickyButtons ? (
                    <Scrollbars className="h-full w-full">
                      <div
                        className={
                          noPadding
                            ? 'relative flex-1'
                            : 'relative my-6 flex-1 px-4 sm:px-6'
                        }
                      >
                        {children}
                      </div>
                    </Scrollbars>
                  ) : (
                    <div
                      className={
                        noPadding
                          ? 'relative flex-1'
                          : 'relative my-6 flex-1 px-4 sm:px-6'
                      }
                    >
                      {children}
                    </div>
                  )}
                  {hasButtons && (
                    <div className="flex-shrink-0 px-4 border-t border-gray-300 dark:border-darkGray-600 py-5 dark:bg-darkGray-700">
                      <div className="space-x-3 flex justify-end">
                        {buttons}
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        <Tooltip id={`slideover-tooltip`} className="w-56" />
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
