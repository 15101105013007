import { Currency, TrivialTooltip } from '@chiroup/components';
import {
  STRING_BOOLEAN_HASH,
  STRING_NUMBER_HASH,
} from '@chiroup/core/constants/globals';
import { subtotalAndTaxFromItems } from '@chiroup/core/functions/balanceFromItems';
import { Responsibilities } from '@chiroup/core/functions/calculateResponsibilities';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import {
  PatientTransaction,
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { useContext, useMemo } from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import useProductTaxes from '../../../../../../hooks/useProductTaxes';
import {
  RootCallbackEvent,
  RootCallbackProps,
} from '../../../billing/transactions/ConsolidatedTransactionModal';

type Props = {
  treatmentName?: string;
  editing?: boolean;
  insurancesToUse: {
    billable: boolean;
    payorId: number;
    payorName: string;
    billingPriority: number;
    deductible: number;
    coPay: number;
    coInsurance: number;
    allowedAmount: number;
    serviceAllowedAmounts: STRING_NUMBER_HASH;
    maxPerVisit: number;
  }[];
  responsibilities:
    | Responsibilities
    | {
        payorResponsibilities: Record<
          number,
          {
            amount: string;
            name: string;
            payorId: number;
          }
        >;
        finalPatientResponsibility: number | string;
        contractualAdjustment?: number | string;
        finalInsurancePatientResponsibility?: number | string;
      };
  payorResponsibilityTotal: any;
  patientInsuranceResponsibility?: Decimal;
  patientResponsibility: any;
  balanceAllocatedToPatient: Decimal;
  workingTransaction: PatientTransaction | null;
  rootCallback?: (props: RootCallbackProps) => void;
};
const EncounterBillingView: React.FC<Props> = ({
  treatmentName = '',
  editing,
  insurancesToUse,
  responsibilities,
  payorResponsibilityTotal: prt,
  balanceAllocatedToPatient,
  workingTransaction,
  rootCallback,
  patientInsuranceResponsibility,
}) => {
  const { me } = useContext(MeContext);
  const { data: canItBeTaxed } = useProductTaxes();

  const items = useMemo(() => {
    return workingTransaction?.items ?? [];
  }, [workingTransaction]);

  const services = useMemo(() => {
    return items?.filter(
      (item) => item.subtype === TransactionItemSubtypeEnum.Service,
    );
  }, [items]);

  const patientServices = useMemo(() => {
    return items?.filter(
      (item) => item.subtype === TransactionItemSubtypeEnum.PatientService,
    );
  }, [items]);

  const hasService = useMemo(() => {
    return !!services?.length || !!patientServices?.length;
  }, [services, patientServices]);

  const itemsToUse = useMemo(() => {
    if (!hasService) {
      return items;
    }
    return items?.filter(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Service ||
        item?.subtype === TransactionItemSubtypeEnum.PatientService,
    );
  }, [items, hasService]);

  const servicesAndAdjustments = useMemo(() => {
    if (!hasService) {
      return items;
    }
    return items?.filter(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Service ||
        item?.subtype === TransactionItemSubtypeEnum.PatientService ||
        item?.subtype === TransactionItemSubtypeEnum.Adjustment,
    );
  }, [items, hasService]);

  const locationId = useMemo(() => {
    return workingTransaction?.locationId as number;
  }, [workingTransaction?.locationId]);

  const disciplineId = useMemo(() => {
    return workingTransaction?.disciplineId as number;
  }, [workingTransaction?.disciplineId]);

  const isBillingStarted = useMemo(() => {
    return !!workingTransaction?.isBillingStarted;
  }, [workingTransaction?.isBillingStarted]);

  const { subtotal, tax, total, balance } = useMemo(() => {
    const locationToUse = me?.selectedClinic?.locations?.find(
      (loc) => loc.ID === locationId,
    );
    const productTaxRate = locationToUse?.productTaxRate || 0;
    const serviceTaxRate = locationToUse?.serviceTaxRate || 0;
    const packageApplied = servicesAndAdjustments?.some(
      (item) =>
        item?.subtype === TransactionItemSubtypeEnum.Adjustment &&
        !!item?.packageId,
    );
    const hasSalesTax = items?.some((item) => item?.salesTax === true);
    // console.log({
    //   items,
    //   productTaxRate,
    //   serviceTaxRate,
    //   hasSalesTax,
    //   packageApplied,
    // });
    return subtotalAndTaxFromItems({
      items: items ?? [],
      productTaxRate: productTaxRate,
      serviceTaxRate: serviceTaxRate,
      makeRateZero: !hasSalesTax || packageApplied,
    });
  }, [
    me?.selectedClinic?.locations,
    items,
    servicesAndAdjustments,
    locationId,
  ]);

  const applyTax = itemsToUse?.some((item) => item?.salesTax) === true;

  const savedTax = useMemo(() => {
    return items?.find(
      (item) => item.subtype === TransactionItemSubtypeEnum.Tax,
    )?.amount;
  }, [items]);

  // [8653819084] No responsibilities for the patient unless there is
  // AT LEAST one debit item on it.
  const summaryFinalPatientResponsibilities = useMemo(() => {
    // No responsibilities for the patient unless there's at least one debit item.
    const hasDebit = services?.some(
      (service) => service?.type === TransactionItemTypeEnum.Debit,
    );

    if (!hasDebit) {
      return {
        totalPatientResponsibility: '0',
        totalPatientInsuranceResponsibility: '0',
        totalPatientNonBillableResponsibility: '0',
      };
    }

    const finalPatientResponsibility = createDecimal(
      responsibilities?.finalPatientResponsibility ?? 0,
    );

    const finalInsurancePatientResponsibility = createDecimal(
      responsibilities?.finalInsurancePatientResponsibility ?? 0,
    );

    const nonBillablePatientResponsibility = finalPatientResponsibility.minus(
      finalInsurancePatientResponsibility,
    );

    return {
      totalPatientResponsibility: finalPatientResponsibility.toFixed(2),
      totalPatientInsuranceResponsibility:
        finalInsurancePatientResponsibility.toFixed(2),
      totalPatientNonBillableResponsibility:
        nonBillablePatientResponsibility.toFixed(2),
    };
  }, [
    services,
    responsibilities?.finalPatientResponsibility,
    responsibilities?.finalInsurancePatientResponsibility,
  ]);

  const showTaxButton =
    editing &&
    itemsToUse?.some(
      (item) =>
        (item?.subtype === TransactionItemSubtypeEnum.Supplement &&
          item.description &&
          canItBeTaxed?.supplementsItemTaxHash[item?.description]) ||
        (item?.subtype === TransactionItemSubtypeEnum.Treatment &&
          item.description &&
          canItBeTaxed?.treatmentsItemTaxHash[disciplineId]),
    );

  const figureOutIfWeCanChangeSalesTax = (
    subtype: TransactionItemSubtypeEnum | null,
    item: PatientTransactionItemType,
  ) => {
    if (subtype === TransactionItemSubtypeEnum.Supplement) {
      return item?.description &&
        canItBeTaxed?.supplementsItemTaxHash[item?.description]
        ? !item?.salesTax
        : false;
    }
    if (subtype === TransactionItemSubtypeEnum.Treatment) {
      return disciplineId && canItBeTaxed?.treatmentsItemTaxHash[disciplineId]
        ? !item?.salesTax
        : false;
    }
    return false;
  };

  const changeSalesTax = () => {
    // console.log('EncounterBillingView1');
    rootCallback?.({
      event: RootCallbackEvent.UpdateSingleTransaction,
      transaction: workingTransaction
        ? {
            ...workingTransaction,
            items: workingTransaction?.items?.map((item) => {
              return {
                ...item,
                salesTax: figureOutIfWeCanChangeSalesTax(item.subtype, item),
              };
            }),
          }
        : null,
    });
  };

  const notBillableByPayorId = useMemo(() => {
    return insurancesToUse?.reduce((acc, cur) => {
      acc[cur.payorId] = !cur.billable;
      return acc;
    }, {} as STRING_BOOLEAN_HASH);
  }, [insurancesToUse]);

  const maxPerVisitByPayorId = useMemo(() => {
    return insurancesToUse?.reduce((acc, cur) => {
      acc[cur.payorId] = cur.maxPerVisit;
      return acc;
    }, {} as STRING_NUMBER_HASH);
  }, [insurancesToUse]);

  const perPayorArray = useMemo(() => {
    const rAsAny = responsibilities as any,
      memo = Object.entries(responsibilities?.payorResponsibilities ?? {}).map(
        ([key, value]) => {
          // console.log(`key: ${key}, value: ${value}`);
          return {
            patientAmount: rAsAny?.patientResponsibilities?.[key],
            payorAmount: value?.amount,
            name: value?.name,
            payorId: value?.payorId,
          };
        },
      );
    return memo;
  }, [responsibilities]);

  return (
    <div
      data-component="encounter-billing-view"
      className="border overflow-hidden rounded-md border-gray-400 border-spacing-4 p-4"
    >
      {/* <pre>
        {ChiroUpJSON.pretty({
          responsibilities,
          patientResponsibility,
          perPayorArray,
        })}
      </pre> */}
      {!!insurancesToUse?.length && !!services.length && (
        <>
          {perPayorArray.map((item, idx) => (
            <div key={`ppa-${idx}`} className="flex flex-col mt-4">
              <div
                className={classNames(
                  isBillingStarted ? 'text-accent-600' : 'text-primary-500',
                  'font-sans font-bold pb-2 flex flex-row justify-between',
                )}
              >
                <div>{item.name}</div>
                {notBillableByPayorId[item?.payorId ?? -1] ? (
                  <div
                    className={
                      'rounded-lg bg-red-500 pt-1 px-2 text-[10px] leading-[10px] font-light text-white h-5'
                    }
                  >
                    Non-billable
                  </div>
                ) : null}
              </div>
              <div className="pl-4 w-full flex flex-row justify-between">
                <div>
                  <span>Payor total</span>
                  <span>
                    {!!maxPerVisitByPayorId[item.payorId] && (
                      <TrivialTooltip
                        iconClassName="h-3 w-3 text-gray-400 inline-block ml-1 mr-1 align-text-top"
                        text={`This payor allows a maximum charge of $${createDecimal(
                          maxPerVisitByPayorId[item.payorId] ?? 0,
                        ).toFixed(
                          2,
                        )}, encompassing both patient and payor contributions.`}
                      />
                    )}
                  </span>
                </div>
                <Currency value={item.payorAmount} />
              </div>
            </div>
          ))}

          {!!perPayorArray?.length && (
            <>
              <div className="flex justify-between flex-row mt-4 mb-2">
                <span>Contractual adjustment</span>
                <Currency
                  value={responsibilities?.contractualAdjustment ?? 0}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className="flex justify-between flex-row mt-4 mb-2">
                <span>Total patient responsibility</span>
                <Currency
                  value={
                    summaryFinalPatientResponsibilities?.totalPatientResponsibility ??
                    0
                  }
                  negativeClassName="text-red-500"
                />
              </div>
              {+summaryFinalPatientResponsibilities?.totalPatientNonBillableResponsibility >
                0 && (
                <>
                  <div className="flex justify-between flex-row my-2 ml-6 text-sm">
                    <span>Insurance patient responsibility</span>
                    <Currency
                      value={
                        summaryFinalPatientResponsibilities?.totalPatientInsuranceResponsibility ??
                        0
                      }
                      negativeClassName="text-red-500"
                    />
                  </div>

                  <div className="flex justify-between flex-row  ml-6 text-sm">
                    <span>Self-pay patient responsibility</span>
                    <Currency
                      value={
                        summaryFinalPatientResponsibilities?.totalPatientNonBillableResponsibility
                      }
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      <div className="pb-4 font-bold">{treatmentName ?? ''}</div>

      <div
        className={`flex justify-between ${
          perPayorArray?.length ? 'mb-6' : 'mb-2'
        }`}
      >
        <span className="font-semibold">Subtotal</span>
        <Currency value={subtotal} />
      </div>
      <div className="flex justify-between flex-row mb-2">
        <div className="font-semibold">Tax</div>

        <div className="flex flex-row">
          {balance > 0 && changeSalesTax && showTaxButton && (
            <div
              onClick={() => changeSalesTax()}
              className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs mr-8 whitespace-nowrap"
            >
              {applyTax ? 'Remove Tax' : 'Apply Tax'}
            </div>
          )}
          <Currency value={editing ? tax : savedTax} />
        </div>
      </div>
      <div className="flex justify-between mb-2">
        <span className="font-semibold">Total</span>
        <Currency value={total} />
      </div>
      {!!balance && (
        <div
          className="border-black flex justify-between mb-2 font-sans font-bold mt-4 pt-2"
          style={{ borderTopWidth: 'thin' }}
        >
          <span>Balance</span>
          <Currency value={balance} />
        </div>
      )}
    </div>
  );
};

export default EncounterBillingView;
