import { InputMasked } from '@chiroup/components';
import {
  ArrowUpCircleIcon,
  CheckCircleIcon as CheckCircleIconOutline,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { MeContext } from '../../../../../contexts/me.context';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';
import { classNames } from '@chiroup/core/functions/classNames';
import { formatCurrency } from '@chiroup/core/functions/format';
import { AppointmentStatuses } from '@chiroup/core/types/Appointment.type';
import { PatientPayment } from '@chiroup/core/types/PatientPayment.type';
import {
  PatientTransaction,
  PatientTransactionClass,
  TransactionTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';

type Props = {
  item: PatientTransaction | PatientTransactionClass | null;
  order: number;
  handleItemSelect: (
    item: PatientTransaction | PatientTransactionClass,
    deselect: boolean,
    order: number,
    available: boolean,
    amount?: number,
  ) => void;
  updateSelectedItems: (
    id: number,
    amount: number,
    order: number,
    available: boolean,
  ) => void;
  value: Partial<PatientPayment>;
  isNew: boolean;
  disabled?: boolean;
  available?: boolean;
  editing?: boolean;
  setUnapplyTransactionList?: React.Dispatch<React.SetStateAction<string[]>>;
  isApplied?: boolean;
  transactionLength?: number;
  showUnapply?: boolean;
  patientId?: string;
};

const PaymentTransactionItem: React.FC<Props> = ({
  item,
  handleItemSelect,
  updateSelectedItems,
  value,
  isNew,
  disabled = false,
  available = false,
  editing = false,
  order,
  setUnapplyTransactionList,
  isApplied,
  transactionLength,
  showUnapply = false,
  patientId,
}) => {
  const { selectedLocationFull } = useContext(MeContext);
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [internalValue, setInternalValue] = useState<number | null>(null);

  useEffect(() => {
    const localItem = { ...item } as any;
    const itemAmount = localItem.paymentAmount ?? 0;

    if (!available && itemAmount > 0) {
      setIsChecked(true);
    }

    setInternalValue(itemAmount);
    setIsChecked(!!internalValue && internalValue > 0);
  }, [value, item, available, isChecked, internalValue]);

  const handleCheckboxChange = (checked: boolean, amount: number) => {
    setIsChecked(!checked);
    handleItemSelect(item as any, !checked, order, available, amount);
  };

  const handleAmountChange = (amount: number) => {
    const balance = !Number.isNaN(item?.patientBalance)
      ? Number(item?.patientBalance)
      : item?.balance || 0;

    const amountToUse = amount > balance ? balance : amount;

    setInternalValue(amountToUse);
    setIsChecked(!!amountToUse && amountToUse > 0);

    handleItemSelect(
      item as any,
      amountToUse > 0,
      order,
      available,
      amountToUse,
    );

    if (item?.id) {
      updateSelectedItems(item.id, amountToUse, order, available);
    } else {
      // Handle the case where item or item.id is not available
      console.error('Item or item ID is undefined');
    }
  };

  if (!item) return null;

  return (
    <li
      key={item.id}
      className={`flex sm:flex-row flex-col justify-between gap-x-6 py-3 ${
        editing || isNew ? '' : 'cursor-pointer'
      }`}
      onClick={() => {
        if (!editing && !isNew) {
          navigate(`/consolidated/transaction/${item.billingKey}`);
        }
      }}
    >
      <div className="flex sm:flex-row flex-col min-w-0 gap-x-4">
        {setUnapplyTransactionList && showUnapply ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              if (!isApplied) {
                setUnapplyTransactionList?.((prev) => [
                  ...prev,
                  item.billingKey,
                ]);
              } else {
                setUnapplyTransactionList?.((prev) => [
                  ...prev.filter((key) => key !== item.billingKey),
                ]);
              }
            }}
          >
            {isApplied ? (
              <CheckCircleIcon
                className="size-10 text-primary-600"
                aria-hidden="true"
              />
            ) : (
              <CheckCircleIconOutline
                className="size-10 text-gray-400"
                aria-hidden="true"
              />
            )}
          </div>
        ) : isNew ? (
          <div
            onClick={() => {
              handleCheckboxChange(!isChecked, item?.balance || 0);
            }}
            className="cursor-pointer"
          >
            {isChecked ? (
              <CheckCircleIcon
                className="size-10 text-primary-600"
                aria-hidden="true"
              />
            ) : (
              <CheckCircleIconOutline
                className="size-10 text-gray-400"
                aria-hidden="true"
              />
            )}
          </div>
        ) : (
          <div
            className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={() => {
              if (disabled) return;
              handleCheckboxChange(isChecked, item?.balance || 0);
            }}
          >
            {isChecked ? (
              <CheckCircleIcon
                className="size-10 text-primary-600"
                aria-hidden="true"
              />
            ) : (
              <CheckCircleIconOutline
                className="size-10 text-gray-400"
                aria-hidden="true"
              />
            )}
          </div>
        )}
        <div className="text-sm min-w-48">
          {item.type === TransactionTypeEnum.AdHoc ? (
            <span>Purchase</span>
          ) : item.type === TransactionTypeEnum.Encounter ? (
            <span>Encounter</span>
          ) : (
            <span>
              {'Appointment '}
              {item.type === TransactionTypeEnum.Hybrid &&
                (item?.status === AppointmentStatuses.Canceled ? (
                  <span className="inline-flex items-center px-2  text-xs font-medium text-red-700 rounded-full bg-red-50 ring-1 ring-inset ring-red-600/50">
                    Canceled
                  </span>
                ) : item?.status === AppointmentStatuses.CheckedIn ? (
                  <span className="inline-flex items-center px-2 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                    Checked In
                  </span>
                ) : item?.status === AppointmentStatuses.NoShow ? (
                  <span className="inline-flex items-center px-2 text-xs font-medium text-red-700 rounded-full bg-red-50 ring-1 ring-inset ring-red-600/50">
                    No Show
                  </span>
                ) : item?.status === AppointmentStatuses.Scheduled ? (
                  <span className="inline-flex items-center px-2  text-xs font-medium text-yellow-700 rounded-full bg-yellow-50 ring-1 ring-inset ring-yellow-600/50">
                    Scheduled
                  </span>
                ) : null)}
            </span>
          )}

          <p className="text-xs leading-5 text-gray-500 dark:text-gray-300">
            {ChiroUpDayJsCommon.display.datetime(
              (item?.startTime || item?.transactionDate) as number,
              selectedLocationFull.timezone,
            ) || ''}
          </p>
        </div>

        <div className="text-sm sm:mt-0 mt-3">
          {item.provider?.displayName}
          {item.summary?.items && (
            <div className="flex justify-between gap-x-4 py-1 text-xs">
              <dd className="flex items-start gap-x-2">
                <div className="text-gray-700 dark:text-gray-300">
                  <dl className="divide-y">
                    {item.summary.items.map((item: string, i: number) => (
                      <dd key={i}>{item}</dd>
                    ))}
                  </dl>
                </div>
              </dd>
            </div>
          )}
        </div>
      </div>
      <div className=" shrink-0 sm:flex sm:flex-col sm:items-end">
        {isNew ? (
          <div>
            <dd className="flex items-start gap-x-2">
              <InputMasked
                name="creditAmount"
                className={classNames(
                  'w-48',
                  isChecked ? ' border-primary-500' : 'border-gray-300',
                )}
                placeholder="0.00"
                value={internalValue}
                onChange={handleAmountChange}
                onBlur={() => {
                  setInternalValue(0.2);
                }}
                inline={true}
                numericOptions={{
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  allowNegative: false,
                  // prefix: '$',
                }}
                disabled={(!available && !editing) || disabled}
              />
            </dd>
            <div className="flex flex-row space-x-1">
              <p className="text-xs">
                Patient balance:{' '}
                {formatCurrency(
                  !Number.isNaN(item?.patientBalance)
                    ? Number(item?.patientBalance)
                    : 0,
                )}
              </p>
              {Number(value.amount).toFixed(2) !==
              (Number(item?.patientBalance) || 0).toFixed(2) ? (
                <div
                  title="Click to use the full balance as the amount."
                  onClick={() => {
                    const amount = Number(item?.patientBalance ?? 0);
                    if (amount) handleAmountChange(amount);
                  }}
                >
                  <ArrowUpCircleIcon className="h-4 w-4 text-primary-500 mt-0.5 ml-0.5" />
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="txt-xl py-2 px-4">
            {formatCurrency(internalValue || 0)}
          </div>
        )}
      </div>
    </li>
  );
};

export default PaymentTransactionItem;
