import { SelectOption } from '@chiroup/components';
import { ChiroUpAppointmentCommon } from '@chiroup/core/constants/stringConstants';
import { classNames } from '@chiroup/core/functions/classNames';
import {
  AppointmentForUI,
  AppointmentStatuses,
} from '@chiroup/core/types/Appointment.type';
import { Discipline } from '@chiroup/core/types/Discipline.type';
import { useForm } from '@chiroup/hooks';
import dayjs, { isDayjs } from 'dayjs';

import qs from 'query-string';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import Button, { ButtonColors } from '../common/Button';
import ScheduleAppointmentFormSlot from './ScheduleAppointmentFormSlot';
import {
  NUMBER_ANY_HASH,
  SELECT_OPTION_TYPE,
} from '@chiroup/core/constants/globals';
import useUpdateMySlot, {
  ProvidersAndTreatmentsToTracks,
} from './useUpdateMySlot';
import { MeContext } from '../../contexts/me.context';
import { TreatmentHash } from './ScheduleAppointment';
import usePatientTracks from '../patients/hooks/usePatientTracks';
import useAppointment from './hooks/useAppointment';
import DaysOfWeekCheckboxes from './DaysOfWeekCheckboxes';

const validation = {
  patientId: { required: { message: 'Patient is required' } },
  clinicianId: { required: { message: 'Clinician is required' } },
  treatmentId: { required: { message: 'Treatment is required' } },
  timeOfDay: { required: { message: 'Start time is required' } },
  duration: { required: { message: 'Duration is required' } },
  daysOfWeek: { required: { message: 'Days of week is required' } },
  endRecurring: { required: { message: 'End recurring is required' } },
};

type Props = {
  checkAvailability: (val: any) => void;
  onClose: () => void;
  fetchingRecurringData: boolean;
  disabled?: boolean;
  editType?: string;
  setEditType?: (val: string) => void;
  showForm?: boolean;
  setShowForm?: (val: boolean) => void;
  timezone: string;
  passedDisciplines?: Discipline[];
  disciplineOptions: any[];
  providersAndTreatmentsToTracks: ProvidersAndTreatmentsToTracks;
  treatmentHash: TreatmentHash;
  sessionId: string;
  passedAppointment?: AppointmentForUI | null;
};

const ScheduleRecurringAppointmentsForm: React.FC<Props> = ({
  checkAvailability,
  onClose,
  disabled = false,
  editType,
  setEditType,
  showForm,
  setShowForm,
  disciplineOptions,
  providersAndTreatmentsToTracks,
  treatmentHash,
  sessionId,
  passedAppointment,
}) => {
  const [isAppointmentPast, setIsAppointmentPast] = useState(false);
  const { me, selectedLocationFull } = useContext(MeContext);
  const { search } = useLocation();
  const { value, onChange, setValue, isSubmitting, patchValue } = useForm<
    Partial<AppointmentForUI>
  >({ daysOfWeek: [] }, validation);
  const [slotContext, setSlotContext] = useState<NUMBER_ANY_HASH>({
    0: { visible: true },
  });
  const { open } = qs.parse(search) as any;

  const { data: appointment } = useAppointment({
    appointmentId: open,
    sessionId,
    enabled: !passedAppointment?.id,
  });

  const appointmentToUse = useMemo(() => {
    return passedAppointment ?? appointment;
  }, [appointment, passedAppointment]);

  const newRecurringCreation = appointmentToUse?.slots?.some(
    (s) => !s.recurringAppointmentId,
  );

  const [isEditing, setIsEditing] = useState(
    newRecurringCreation ? true : false,
  );

  useEffect(() => {
    if (appointmentToUse && !value?.slots?.length) {
      const startTimeIsDayjs = isDayjs(appointmentToUse.startTime);
      const time = startTimeIsDayjs
        ? appointmentToUse.startTime.format('h:mm a')
        : dayjs(appointmentToUse.startTime).format('h:mm a');
      const dayOfWeek = startTimeIsDayjs
        ? appointmentToUse.startTime.format('dddd')
        : dayjs(appointmentToUse.startTime).format('dddd');

      patchValue({
        patientId: appointmentToUse.patientId,
        treatmentId: appointmentToUse.treatmentId,
        clinicianId: appointmentToUse.clinicianId,
        roomId: appointmentToUse.roomId,
        timeOfDay: time,
        daysOfWeek: [dayOfWeek],
        endRecurring: appointmentToUse.endRecurring,
        slots: appointmentToUse.slots,
      });
      setIsAppointmentPast(
        appointmentToUse?.startTime
          ? appointmentToUse.startTime < dayjs()
          : false,
      );
    }
  }, [appointmentToUse, patchValue, value?.slots?.length]);

  const { data: patientTracks } = usePatientTracks({
    patientId: value?.patientId || '',
  });
  const trackOptions = useMemo(() => {
    return patientTracks?.reduce((arr: SelectOption[], track: any) => {
      arr.push({
        text: track.name,
        value: track.id,
        group:
          track?.providers?.map((provider: any) => provider?.name).join(', ') ||
          'No providers associated with track',
      });
      return arr;
    }, [] as SelectOption[]);
  }, [patientTracks]);

  const displayName = useMemo(() => {
    const { patientName } = qs.parse(search) as any;
    return patientName || '';
  }, [search]);

  const slotOptions = useMemo(() => {
    const clinicians =
      me.selectedClinic?.clinicians?.map((clinician) => ({
        text: clinician.name,
        value: clinician.ID,
      })) ?? [];
    clinicians.unshift({ text: '- none -', value: '-1' });

    return {
      clinicians,
      treatments: treatmentHash?.options as SELECT_OPTION_TYPE[],
      durations:
        treatmentHash?.durations?.map((duration: number) => ({
          value: duration,
          text: `${duration}`,
        })) || [],
      rooms: treatmentHash?.roomSelectComponents || [],
    };
  }, [
    me.selectedClinic?.clinicians,
    treatmentHash?.durations,
    treatmentHash?.options,
    treatmentHash?.roomSelectComponents,
  ]);

  const updateMySlot = useUpdateMySlot({
    disciplineOptions,
    providersAndTreatmentsToTracks,
    treatmentHash,
    setValue,
    value,
    rooms: (slotOptions.rooms as SelectOption[]).map((room) => room.value),
  });

  const firstProviderSlotIndex = useMemo(() => {
    return value?.slots?.reduce(
      (obj: { [key: string]: number }, slot, index) => {
        if (
          slot?.clinicianId &&
          obj[slot.clinicianId] !== 0 &&
          !obj[slot.clinicianId]
        ) {
          obj[slot?.clinicianId] = index;
        }
        return obj;
      },
      {},
    );
  }, [value?.slots]);

  const readOnly = newRecurringCreation ? false : !isEditing;
  // !newRecurringCreation ||
  // (!isEditing && );
  //  &&
  // !newRecurringCreation;

  return (
    <div
      className={classNames(
        'p-4 border border-gray-300 rounded-lg',
        newRecurringCreation
          ? 'cursor-pointer hover:bg-gray-100 dark:bg-darkGray-700 dark:hover:bg-darkGray-700 bg-gray-50'
          : disabled
            ? 'cursor-not-allowed'
            : isAppointmentPast
              ? 'cursor-not-allowed'
              : 'cursor-pointer hover:bg-gray-100 dark:bg-darkGray-700 dark:hover:bg-darkGray-700',
        isAppointmentPast && !newRecurringCreation
          ? 'bg-orange-50'
          : 'bg-gray-50',
      )}
      title={isAppointmentPast ? ChiroUpAppointmentCommon.cannotModify : ''}
      onClick={() => {
        // if (disabled || isAppointmentPast) return;
        if (disabled || appointment?.status === AppointmentStatuses.CheckedIn) {
          return;
        }

        if (!isEditing) setIsEditing(true);
      }}
    >
      {!showForm && isEditing && appointmentToUse?.recurringAppointmentId ? (
        <>
          <p className="text-sm text-gray-500 dark:text-gray-300">
            This is a recurring appointment. Do you want to edit only this
            appointment or do you want to edit all future appointments?
          </p>
          <div className="m-2">
            <label>
              <input
                type="radio"
                value="editOnlyThisAppointment"
                checked={editType === 'editOnlyThisAppointment'}
                onChange={() =>
                  setEditType && setEditType('editOnlyThisAppointment')
                }
                className="form-radio h-4 w-4 text-sm text-primary-600 transition duration-150 ease-in-out m-2"
              />
              <span className="text-sm text-gray-500 dark:text-gray-300">
                Edit only this appointment
              </span>
            </label>
            <br />
            <label>
              <input
                type="radio"
                value="editAllFutureRecurring"
                checked={editType === 'editAllFutureRecurring'}
                onChange={() => setEditType?.('editAllFutureRecurring')}
                className="form-radio h-4 w-4 text-primary-600 transition duration-150 ease-in-out m-2"
              />
              <span className="text-sm text-gray-500 dark:text-gray-300">
                Edit this appointment and all future recurring appointments
              </span>
            </label>
          </div>
          <div className="flex flex-row items-center justify-end gap-2">
            <Button
              text="Cancel"
              type="button"
              color={ButtonColors.plainWithBorder}
              onClick={(e) => {
                e.stopPropagation();
                setShowForm?.(false);
                setEditType?.('');
                setIsEditing(false);
              }}
            />
            <Button
              text="Next"
              disabled={!editType?.length}
              type="submit"
              onClick={() => {
                setShowForm?.(true);
              }}
            />
          </div>
        </>
      ) : (
        <form>
          {!appointmentToUse?.recurringAppointmentId && (
            <span className="text-xl flex flex-row gap-2 font-bold text-gray-900 dark:text-gray-300 sm:text-2xl ">
              {displayName}
            </span>
          )}

          {value?.slots?.map((slot, index) => (
            <ScheduleAppointmentFormSlot
              key={slot.id}
              ord={index}
              slot={slot}
              readonly={readOnly}
              primaryStatus={appointment?.status}
              options={slotOptions}
              location={selectedLocationFull}
              context={slotContext}
              setContext={setSlotContext}
              updateMySlot={updateMySlot}
              firstProviderSlotIndex={firstProviderSlotIndex}
              trackOptions={trackOptions || []}
              onClose={() => {}}
              setValue={setValue}
              timezone={selectedLocationFull?.timezone || ''}
              treatmentHash={treatmentHash}
              hideCreateCCS
            />
          ))}
          {!readOnly && (
            <>
              <div className="mt-2 text-left">
                <DaysOfWeekCheckboxes
                  daysValue={value?.daysOfWeek || []}
                  patchValue={patchValue}
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="date"
                  className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200"
                >
                  End recurring date
                </label>
                <div id="date">
                  <Datepicker
                    value={{
                      startDate: value.endRecurring,
                      endDate: value.endRecurring,
                    }}
                    onChange={(val) => {
                      const start = dayjs(val?.startDate).valueOf();
                      const now = dayjs().valueOf();
                      const yearFromNow = dayjs().add(1, 'year').valueOf();
                      const yearFromNowDate = dayjs().add(1, 'year').toDate();
                      const valStartTimeStamp = dayjs(val?.startDate).valueOf();
                      if (val && start > now) {
                        if (valStartTimeStamp > yearFromNow) {
                          onChange('endRecurring')(yearFromNowDate);
                        } else {
                          onChange('endRecurring')(val.startDate);
                        }
                      }
                    }}
                    asSingle={true}
                    useRange={false}
                    placeholder={'MM/DD/YYYY'}
                    displayFormat={'MM/DD/YYYY'}
                    primaryColor={'green'}
                    inputClassName="border border-gray-300 shadow-sm rounded-md mt-1 focus:outline-none focus:ring-none focus:ring-primary-500 focus:border-transparent pl-2"
                  />
                </div>
              </div>
            </>
          )}

          {(newRecurringCreation || editType === 'editAllFutureRecurring') && (
            <div className="flex-shrink-0 pl-4 border-t border-gray-300 dark:border-darkGray-600 py-5 dark:bg-darkGray-700">
              <div className="space-x-3 flex justify-end">
                <Button
                  text="Cancel"
                  onClick={() => {
                    setShowForm ? setShowForm(false) : onClose();
                    setEditType?.('');
                    setIsEditing(false);
                  }}
                  color={ButtonColors.plain}
                />
                <Button
                  text="Check availability"
                  disabled={!value.endRecurring || !value.timeOfDay}
                  loading={isSubmitting}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    checkAvailability(value);
                  }}
                />
              </div>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default ScheduleRecurringAppointmentsForm;
