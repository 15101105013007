import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import {
  PatientTransaction,
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { MagicAction, MagicActionType } from './commonMagic';
import { TransactionMagiceResponseType } from './transactionMagic';

/**
 *
 */
export const fnTransactionApplyPackage = (
  options: any,
  payload: {
    original: PatientTransaction;
    current: PatientTransaction;
    previous: PatientTransaction;
  },
): TransactionMagiceResponseType => {
  const actions: MagicActionType[] = [];
  const { current: transaction } = payload;
  const findThePackageCredit = options?.findThePackageCredit;

  if (options?.trace) {
    console.log({ fnTransactionApplyPackage: payload, options });
  }

  if (!transaction) {
    actions.push({
      message: `No actions possible without a transaction.`,
      type: MagicAction.Error,
    });
  }
  let touched = false;

  const wasPackagePreviouslyApplied = transaction?.items?.find(
    (item: PatientTransactionItemType) =>
      item.subtype === TransactionItemSubtypeEnum.Adjustment && item?.packageId,
  );
  if (wasPackagePreviouslyApplied) {
    //remove the package credit
    const newItems = ChiroUpJSON.clone(
      transaction?.items.filter(
        (item) =>
          !(
            item.subtype === TransactionItemSubtypeEnum.Adjustment &&
            item?.packageId
          ),
      ),
    );

    const packageCredit = findThePackageCredit?.({
      packageId: wasPackagePreviouslyApplied?.packageId,
      items: newItems,
      insurances: transaction?.insurances || [],
    });
    if (transaction && packageCredit) {
      transaction.items = newItems.concat(packageCredit);
      actions.push({
        message: `Package credit reapplied.`,
        type: MagicAction.Updated,
      });
      touched = true;
    }
  }

  return {
    actions,
    transaction,
    touched,
  };
};
