import { Loading, OpenClosedStates } from '@chiroup/components';
import {
  BellAlertIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import React, { useContext, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { MeContext } from '../../../../../contexts/me.context';
import usePatientNotes from '../../../../../hooks/usePatientNotes';
import patientService from '../../../../../services/patient.service';
import ConfirmModal from '../../../../common/ConfirmModal';
import NoteModal from '../../notes/PatientNote';
import IconButton from '../../../../common/IconButton';
import { NoteLocation } from '@chiroup/core/enums/NoteTypes.enum';
import { Macro } from '@chiroup/core/types/Macro';
import { PatientNote } from '@chiroup/core/types/PatientNote.type';
import classNames from 'classnames';

type Props = {
  patientId: string;
  location: NoteLocation;
  fromAppointment?: boolean;
  addContainerClassName?: string;
  showLoading?: boolean;
};

const PatientAlertsContainer: React.FC<Props> = ({
  patientId,
  location,
  fromAppointment = false,
  addContainerClassName = 'border-b border-gray-300 dark:border-darkGray-700',
  showLoading = false,
}) => {
  const [value, setValue] = useState<PatientNote | null>(null);
  const [showNotesModal, setShowNotesModal] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<OpenClosedStates>(
    OpenClosedStates.Closed,
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDismissing, setIsDismissing] = useState<boolean>(false);
  const { me } = useContext(MeContext);

  const { data, refetch, isFetching } = usePatientNotes(
    patientId,
    100,
    true, // Just notes user _has not_ dismissed.
    ['alert', 'notification'],
    [location],
  );

  const rows = useMemo(() => {
    if (!data?.pages?.length) return [];
    const alerts =
      data?.pages[0]?.data.filter(
        (note) => note.alert && note.location?.includes(location),
      ) || [];
    const notifications =
      data?.pages[0]?.data.filter(
        (note) =>
          !note.alert && note.notification && note.location?.includes(location),
      ) || [];

    const rowData = [...alerts, ...notifications];
    const groupedRows = [];
    for (let i = 0; i < rowData.length; i += 3) {
      groupedRows.push(rowData.slice(i, i + 3));
    }
    return groupedRows;
  }, [data?.pages, location]);

  const onDelete = async (noteId: string) => {
    try {
      setIsDeleting(true);
      await patientService.deletePatientNote(
        noteId,
        me.selectedClinic?.ID,
        patientId,
      );
      setValue(null);
      setShowNotesModal(OpenClosedStates.Closed);
      refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(OpenClosedStates.Closed);
    }
  };

  const openNotesModal = (alert: PatientNote) => {
    setValue(alert);
    setShowNotesModal(OpenClosedStates.Open);
  };

  const dismissNotification = async (note: PatientNote) => {
    try {
      setIsDismissing(true);
      if (
        Array.isArray(note?.dismissedNotification) &&
        !note.dismissedNotification.includes(me.ID)
      ) {
        note.dismissedNotification.push(me.ID);
      } else {
        note.dismissedNotification = [me.ID];
      }

      await patientService.updatePatientNote(
        note,
        me.selectedClinic?.ID,
        patientId,
      );
      await refetch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsDismissing(false);
    }
  };

  if (isFetching && (fromAppointment || showLoading)) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Loading size={10} color="text-gray-500" />
      </div>
    );
  }

  if (!isFetching && (fromAppointment || showLoading) && !rows?.length) {
    return (
      <div className="p-2 text-sm sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200  text-gray-500 ">
        Patient has no alerts or notifications to display.
      </div>
    );
  }

  if (!isFetching && rows?.length) {
    return (
      <div
        className={classNames(
          'print-hidden p-2 bg-white dark:bg-darkGray-800',
          addContainerClassName,
        )}
      >
        {rows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className={`
              print:hidden 
              grid 
              grid-cols-1 
              gap-2 
              ${!fromAppointment ? `md:grid-cols-${row.length}` : ''} 
              ${rowIndex < rows.length - 1 ? 'mb-4' : ''}
            `}
          >
            {row.map((note) => (
              <div
                key={note.id}
                className={`relative flex items-left space-x-3 rounded-lg border-2 bg-white dark:bg-darkGray-700 dark:text-white p-4 shadow-sm cursor-pointer ${
                  note.alert
                    ? ' border-red-500/30  hover:border-red-500 '
                    : 'border-yellow-500/30  hover:border-yellow-500 '
                }`}
                onClick={() => openNotesModal(note)}
              >
                <div className="flex-shrink-0">
                  {note.alert ? (
                    <BellAlertIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-yellow-500"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="min-w-0 flex-1">
                  <div className="text-sm h-12">
                    <Scrollbars
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                    >
                      {note.note}
                    </Scrollbars>
                  </div>
                </div>
                {!note.alert && (
                  <div className="absolute top-2 right-2">
                    <IconButton
                      tooltip="Dismiss"
                      onClick={(e) => {
                        e.stopPropagation();
                        dismissNotification(note);
                      }}
                      loading={isDismissing && row[rowIndex].id === note.id}
                      type="button"
                      className="inline-flex h-5 w-5 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300"
                      icon={<XMarkIcon />}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
        <NoteModal
          state={showNotesModal}
          refetch={refetch}
          setState={setShowNotesModal}
          patientId={patientId}
          note={value}
          macros={me?.macros as Macro[]}
          setSelectedNote={setValue}
          onDelete={() => setIsDeleteModalOpen(OpenClosedStates.Open)}
        />
        <ConfirmModal
          state={isDeleteModalOpen}
          confirm={() => onDelete(value?.id || '')}
          close={() => setIsDeleteModalOpen(OpenClosedStates.Closed)}
          title="Delete Note"
          description="Are you sure you want to delete this note?"
          loading={isDeleting}
          confirmText="Delete"
        />
      </div>
    );
  }
  return null;
};

export default PatientAlertsContainer;
