import React, { ChangeEvent } from 'react';
import Tabs from '../../../../layout/Tabs';
import {
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';

type Props = {
  rightSide?: React.ReactNode;
  setActiveTabName?: (name: string) => void;
  switches?: STRING_BOOLEAN_HASH;
  callbacks?: STRING_ANY_HASH;
};

const TransactionEditorTabs: React.FC<Props> = ({
  rightSide,
  setActiveTabName,
  switches = {},
  callbacks = {},
}) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (e: ChangeEvent<HTMLSelectElement>, idx?: number) => {
    const setto = typeof idx === 'number' ? idx : +e.target.value,
      tabObject = tabs[activeTab],
      tabName = tabObject.name,
      nextTabName = tabs[setto].name,
      fromName = `onFrom${tabName}`;

    if (callbacks?.[fromName]) {
      const res = callbacks[fromName](e, tabs[setto]);
      if (res === false) {
        return;
      }
    }
    setActiveTab(setto);
    if (setActiveTabName) {
      setActiveTabName(nextTabName);
    }
  };

  const tabs = [
    {
      name: 'Detail',
    },
    {
      name: 'Invoices',
    },
    {
      name: 'Activity',
    },
  ]
    .map((tab, i) => ({ ...tab, active: activeTab === i }))
    .filter((tab) => !switches[tab.name] === false);

  return (
    <div className="print:hidden relative bg-transparent">
      <Tabs
        tabs={tabs}
        rightSide={rightSide}
        onClick={handleTabChange}
        transparent={true}
      />
    </div>
  );
};

export default TransactionEditorTabs;
