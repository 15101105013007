import { DiagsType } from '@chiroup/core/types/PatientInvoice.type';

type InvoiceTableRowProps = {
  item: DiagsType;
  dx: number;
};
const SuperbillInvoiceDiagnosisTableRow: React.FC<InvoiceTableRowProps> = ({
  item,
  dx,
}) => {
  return (
    <tr className="text-xs odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {dx}
      </td>
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.data.code}
      </td>
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.data.description}
      </td>
    </tr>
  );
};

export default SuperbillInvoiceDiagnosisTableRow;
