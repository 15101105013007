import {
  Currency,
  Input,
  InputMasked,
  TrivialTooltip,
} from '@chiroup/components';
import { TidyInputValue } from '@chiroup/core/constants/TidyInputValue';
import { classNames } from '@chiroup/core/functions/classNames';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import {
  PatientTransactionItemType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  billingKey?: string;
  clickableRows?: boolean;
  hasServices?: boolean;
  hideUnits?: boolean;
  index: number;
  onChange: (property: string) => (value: any) => void;
  onRemove?: (ord: number) => void;
  overrideApplied?: boolean;
  patientId?: string;
  patientName?: string;
  readonly?: boolean;
  usage?: 'ledger' | 'legacy';
  value: PatientTransactionItemType;
  lineThrough?: boolean;
};

export const TransactionItem: React.FC<Props> = ({
  billingKey,
  clickableRows = false,
  hasServices = false,
  hideUnits = false,
  index,
  onChange,
  onRemove,
  overrideApplied = false,
  patientId,
  patientName,
  readonly = false,
  usage = 'legacy',
  value: item,
  lineThrough,
}) => {
  const navigate = useNavigate();

  const percentDiscountApplied = useMemo(() => {
    return item?.adjustment?.structure === '% Discount';
  }, [item?.adjustment?.structure]);

  const handleClick = () => {
    if (clickableRows) {
      if (item?.subtype === TransactionItemSubtypeEnum.Payment) {
        navigate(
          `/patients/${patientId}/billing/${
            item?.payorId ? 'payor-payments' : 'payments'
          }/${item?.paymentSource}${
            item?.payorId && billingKey ? `?returnTo=${billingKey}` : ''
          }`,
        );
      }
    }
  };

  const hasRemoveHandler = useMemo(() => {
    return (
      !!onRemove &&
      !readonly &&
      item?.subtype !== TransactionItemSubtypeEnum.Treatment
    );
  }, [item?.subtype, onRemove, readonly]);

  const bgColor =
    index % 2 === 0
      ? 'bg-gray-50 dark:bg-gray-800'
      : 'bg-white dark:bg-gray-900';

  const hoverColor =
    index % 2 === 0
      ? 'hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer'
      : 'hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer';

  const commonClassNames = useMemo(() => {
    return classNames(
      bgColor,
      `w-full text-gray-800 dark:text-white text-sm p-2`,
      clickableRows ? hoverColor : '',
    );
  }, [bgColor, clickableRows, hoverColor]);

  if (item?.subtype === TransactionItemSubtypeEnum.Reprocessed) {
    return (
      <div
        className={classNames(
          bgColor,
          `w-full text-gray-800 text-sm px-2 p-4 justify-between flex flex-row`,
          clickableRows ? hoverColor : '',
        )}
        data-component="transaction-item"
      >
        <div>{item?.description}</div>
        {/* <div>{item?.type}</div> */}
        <Currency
          value={item?.amount}
          debit={item?.type === TransactionItemTypeEnum.Debit}
          negativeClassName="text-red-500"
          className={lineThrough ? 'line-through' : ''}
        />
      </div>
    );
  }

  /**
   * s u p p l e m e n t
   */
  if (
    item.subtype === TransactionItemSubtypeEnum.Supplement ||
    item?.subtype === TransactionItemSubtypeEnum.Package
  ) {
    return (
      <div
        data-component="transaction-supplement-item"
        className={commonClassNames}
      >
        <div
          className="flex flex-row justify-between w-full"
          onClick={handleClick}
        >
          <div className="flex flex-row gap-1 items-center">
            {hasRemoveHandler && !readonly && (
              <XMarkIcon
                className="w-4 h-4 flex-shrink-0 cursor-pointer text-gray-400 hover:text-gray-700"
                onClick={() => {
                  onRemove?.(item.id);
                }}
              />
            )}
            <span>{item.description}</span>
          </div>
          <div className="flex flex-row space-x-2 align-middle">
            <Currency
              value={item?.amount}
              className={lineThrough ? 'line-through' : ''}
            />
            {readonly && !hideUnits ? (
              <div className="">
                <div>{`x ${item?.units}`}</div>
              </div>
            ) : (
              !hideUnits &&
              item?.subtype !== TransactionItemSubtypeEnum.Package && (
                <>
                  <div className="flex align-middle">{' x '}</div>
                  <Input
                    className="w-16"
                    label=""
                    type="number"
                    tooltipClassName="text-xs"
                    name="units"
                    onChange={(e: any) => {
                      onChange('units')(e);
                    }}
                    value={item?.units || ''}
                    min="1"
                  />{' '}
                  <div className="flex align-middle">units</div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * p a y m e n t
   */
  if (item.subtype === TransactionItemSubtypeEnum.Payment) {
    const sub =
      item?.payorId || patientName
        ? ` - ${item.payorId ? item.payorName : patientName}`
        : '';
    return (
      <div
        data-component="transaction-payment-item"
        className={commonClassNames}
      >
        <div
          className="flex flex-row justify-between w-full"
          onClick={handleClick}
        >
          <div className="flex">
            {item.payorId ? 'Payor payment' : 'Patient payment'}
            {sub}
          </div>
          <div className={classNames('flex')}>
            <Currency
              value={item?.amount}
              className={lineThrough ? 'line-through' : ''}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * T r e a t m e n t
   */
  // if (item.subtype === TransactionItemSubtypeEnum.Treatment) {
  //   // hasServices = false;
  //   // const total = createDecimal(item?.amount ?? 0)
  //   //   .times(item?.units ?? 1)
  //   //   .toNumber();
  //   return (
  //     <div
  //       data-component="transaction-treatment-item"
  //       className={commonClassNames}
  //     >
  //       {/* <pre>
  //         {ChiroUpJSON.pretty({
  //           description: item?.description,
  //           type: item?.type,
  //           subtype: item?.subtype,
  //         })}
  //       </pre> */}
  //       <div
  //         className="flex flex-row justify-between w-full"
  //         onClick={handleClick}
  //       >
  //         <div className="flex flex-row gap-1 items-center">
  //           <div className="flex flex-col">
  //             <p>{item?.description}</p>
  //           </div>
  //         </div>
  //         <div className="flex flex-row space-x-1">
  //           <div className={classNames('')}>
  //             <Currency value={item?.amount} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  /**
   * A d d T r e a t m e n t
   */
  if (
    item.subtype === TransactionItemSubtypeEnum.AddTreatment ||
    item.subtype === TransactionItemSubtypeEnum.Treatment
  ) {
    return (
      <div
        data-component="transaction-treatment-item"
        className={commonClassNames}
      >
        <div
          className="flex flex-row justify-between w-full"
          onClick={handleClick}
        >
          <div className="flex flex-row gap-1 items-center">
            {readonly ? (
              <div className="flex flex-col">
                <p>{item?.description}</p>
              </div>
            ) : (
              <div className="flex flex-col">
                {hasServices ? null : (
                  <div className="text-sm font-medium">Description</div>
                )}
                <div className="p-2 block w-full border-gray-300 rounded-md text-sm sm:leading-5 dark:border-darkGray-600 focus:ring-2 text-gray-500 dark:text-darkGray-300 bg-gray-300 dark:bg-darkGray-500 cursor-not-allowed">
                  {item?.description ?? '- n.a. -'}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-row space-x-1">
            <div className={classNames('')}>
              {readonly ? (
                <Currency
                  value={item?.amount}
                  className={lineThrough ? 'line-through' : ''}
                />
              ) : (
                <InputMasked
                  className="w-24"
                  label="Amount"
                  tooltipClassName="text-xs"
                  name="amount"
                  onChange={(e: any) => {
                    onChange('amount')(TidyInputValue.Currency(e));
                  }}
                  value={item?.amount || ''}
                  numericOptions={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                  placeholder="0.00"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    item?.subtype === TransactionItemSubtypeEnum.Adjustment ||
    item?.subtype === TransactionItemSubtypeEnum.AdditionalFee
  ) {
    return (
      <div
        className={classNames(
          bgColor,
          `w-full text-gray-800 text-sm px-2 py-2`,
        )}
      >
        {/* <pre>
          {ChiroUpJSON.pretty({
            type: item?.type,
            subtype: item?.subtype,
            description: item?.description,
          })}
        </pre> */}
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row gap-1 items-center">
            {hasRemoveHandler && !readonly && (
              <XMarkIcon
                className="w-4 h-4 flex-shrink-0 cursor-pointer text-gray-400 hover:text-gray-700"
                onClick={() => {
                  onRemove?.(item?.id);
                }}
              />
            )}
            <span>
              {item.code ? `${item.code} - ` : ''}
              {item.description}
            </span>
          </div>
          {percentDiscountApplied && (
            <span>{item?.adjustment?.percentOff || 0} % off</span>
          )}
          <div className="flex flex-row">
            {usage === 'ledger' ? (
              <Currency
                value={item?.amount}
                className={lineThrough ? 'line-through' : ''}
              />
            ) : (
              <div className="flex w-26 flex-row space-x-2 justify-end text-red-500">
                (<Currency value={item?.amount} />)
              </div>
            )}
            {}
          </div>
        </div>
      </div>
    );
  }

  if (
    item?.subtype === TransactionItemSubtypeEnum.Override ||
    item?.subtype === TransactionItemSubtypeEnum.Canceled ||
    item?.subtype === TransactionItemSubtypeEnum.NoShow
  ) {
    return (
      <div
        className={classNames(
          bgColor,
          `w-full text-gray-800 text-sm px-2 py-2`,
        )}
      >
        {/* <pre>
          {ChiroUpJSON.pretty({
            type: item?.type,
            subtype: item?.subtype,
            description: item?.description,
          })}
        </pre> */}
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row gap-1 items-center">
            {hasRemoveHandler && !readonly ? (
              item?.type === TransactionItemTypeEnum.Debit ? (
                <XMarkIcon
                  className="w-4 h-4 flex-shrink-0 cursor-pointer text-gray-400 hover:text-gray-700"
                  onClick={() => {
                    onRemove?.(item?.id);
                  }}
                />
              ) : null
            ) : null}
            <span>
              {item.code ? `${item.code} - ` : ''}
              {item.description}
            </span>
          </div>
          {percentDiscountApplied && (
            <span>{item?.adjustment?.percentOff || 0} % off</span>
          )}
          <div className="flex flex-row">
            {usage === 'ledger' ? (
              <Currency
                value={item?.amount}
                className={lineThrough ? 'line-through' : ''}
              />
            ) : (
              <div className="flex w-26 flex-row space-x-2 justify-end">
                <Currency value={item?.amount} />
              </div>
            )}
            {}
          </div>
        </div>
      </div>
    );
  }

  /**
   * s e r v i c e s
   *
   * p a c k a g e s
   *
   * p a t i e n t   s e r v i c e
   *
   * Use case: Items where units/quantity are applicable.
   */

  if (
    item?.subtype === TransactionItemSubtypeEnum.Service ||
    (item?.subtype === TransactionItemSubtypeEnum.PatientService &&
      usage === 'ledger')
  ) {
    const total = createDecimal(item?.amount ?? 0)
      .times(item?.units ?? 1)
      .toNumber();
    return (
      <div
        data-component="transaction-treatment-item"
        className={commonClassNames}
      >
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row gap-1 items-center">
            <div className="flex flex-col">
              {item?.code ? (
                <div>
                  {/* Yes I hate myself for absolute positioning, needed an easy button. */}
                  <span className={item?.insuranceBillable ? '' : 'italic'}>
                    {item?.code}
                  </span>
                  <TrivialTooltip
                    text={`${
                      item?.insuranceBillable
                        ? ''
                        : 'Not billable to insurance. '
                    }${item?.description ?? ''}`}
                    type={`info`}
                  />
                </div>
              ) : (
                <p>{item?.description}</p>
              )}
            </div>
          </div>
          <div className="flex flex-row space-x-1">
            <div className={classNames('')}>
              <Currency
                value={item?.amount}
                className={lineThrough ? 'line-through' : ''}
              />
            </div>
            {hideUnits ? null : (
              <div className="pl-1">
                <div className="flex flex-row space-x-1">
                  <div>x</div>
                  <div>{item?.units}</div>
                  <div>=</div>
                </div>
              </div>
            )}
            <div>
              <div className="pl-1">
                <Currency value={total} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   * t a x
   *
   */
  if (item?.subtype === TransactionItemSubtypeEnum.Tax) {
    const total = createDecimal(item?.amount ?? 0)
      .times(item?.units ?? 1)
      .toNumber();
    return (
      <div
        data-component="transaction-treatment-item"
        className={commonClassNames}
      >
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row gap-1 items-center">
            <div className="flex flex-row gap-1 items-center">
              <p>{item?.description}</p>
            </div>
          </div>
          <div className="flex flex-row space-x-1">
            <div>
              <div className="pl-1">
                <Currency
                  value={total}
                  className={lineThrough ? 'line-through' : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   *
   * t a x
   *
   * p a t i e n t   w r i t e - o f f
   *
   * p a y o r   w r i t e - o f f
   *
   * Use case: Items where units/quantity are not applicable.
   */
  if (
    item?.subtype === TransactionItemSubtypeEnum.PatientWriteOff ||
    item?.subtype === TransactionItemSubtypeEnum.PayorWriteOff
  ) {
    const total = createDecimal(item?.amount ?? 0)
      .times(item?.units ?? 1)
      .toNumber();
    return (
      <div
        data-component="transaction-treatment-item"
        className={commonClassNames}
      >
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row gap-1 items-center">
            <div className="flex flex-row gap-1 items-center">
              {hasRemoveHandler && !readonly && (
                <XMarkIcon
                  className="w-4 h-4 flex-shrink-0 cursor-pointer text-gray-400 hover:text-gray-700"
                  onClick={() => {
                    onRemove?.(item.id);
                  }}
                />
              )}
              <p>{item?.description}</p>
            </div>
          </div>
          <div className="flex flex-row space-x-1">
            <div>
              <div className="pl-1">
                <Currency
                  value={total}
                  className={lineThrough ? 'line-through' : ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * t h e   r e s t   ( might not be used any longer)
   */

  return (
    <div data-component="transaction-item" className={commonClassNames}>
      <div
        className="flex flex-row justify-between w-full"
        onClick={handleClick}
      >
        <div className="flex flex-row gap-1 items-center">
          {hasRemoveHandler && !readonly && (
            <XMarkIcon
              className="w-4 h-4 cursor-pointer flex-shrink-0 text-gray-400 hover:text-gray-700"
              onClick={() => {
                onRemove?.(item.id);
              }}
            />
          )}
          {readonly ? (
            <span>
              {item.code ? `${item.code} - ` : item.description || ''}
            </span>
          ) : null}
        </div>
        <div className="flex flex-row">
          {readonly ? (
            <div className={classNames('')}>
              <Currency
                value={item?.amount}
                className={lineThrough ? 'line-through' : ''}
              />
            </div>
          ) : null}
          {readonly && !hideUnits ? (
            <div className="pl-1">
              <div>{`x ${item?.units}`}</div>
            </div>
          ) : !hideUnits ? (
            <>
              <div className="px-2 ">{' x '}</div>
              <Input
                className=" w-24"
                label="Units"
                type="number"
                tooltipClassName="text-xs"
                name="units"
                onChange={(e: any) => {
                  onChange('units')(e);
                }}
                value={item?.units || ''}
                min="0"
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
