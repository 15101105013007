import { useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import patientBillingService from '../../../../../../../services/patientBilling.service';
import { MeContext } from '../../../../../../../contexts/me.context';
import { integrationService } from '../../../../../../../services/integration.service';

const QUERY_KEY_BASE = ['invoices'];
const useInvoices = ({
  billingKey,
  patientId,
  returnIntegrationInvoices = false,
  includeLineItems = true,
  trace = false,
}: {
  billingKey: string;
  patientId: string;
  returnIntegrationInvoices?: boolean;
  includeLineItems?: boolean;
  trace?: boolean;
}) => {
  const QUERY_KEY_ARRAY = [...QUERY_KEY_BASE, billingKey];
  const queryClient = useQueryClient();
  const meContext = useContext(MeContext);
  //const [isSaving, setIsSaving] = useState(false);
  const [isSaving] = useState(false);
  const [restError, setRestError] = useState<string | null>(null);
  const { data, isFetching, refetch } = useQuery(
    QUERY_KEY_ARRAY,
    returnIntegrationInvoices
      ? () =>
          integrationService.invoicesByBillingKey({
            billingKey,
            clinicId: meContext.me.selectedClinic?.ID || -1,
            includeLineItems,
            trace,
          })
      : () =>
          patientBillingService.listInvoices({
            billingKey,
            patientId,
            clinicId: meContext.me.selectedClinic?.ID || -1,
          }),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setRestError(null);
      },
      onError: (error: any) => {
        setRestError(
          error?.response?.data?.message ?? error.message ?? 'Unknown error.',
        );
      },
    },
  );

  const setDataManually = (data: any[]) => {
    queryClient.setQueryData(QUERY_KEY_ARRAY, () => data);
  };

  return {
    isFetching,
    data,
    refetch,
    isSaving,
    restError,
    setDataManually,
  };
};

export default useInvoices;
