import {
  Button,
  ButtonColors,
  InputMasked,
  LoadingPage,
} from '@chiroup/components';
import { STRING_ANY_HASH } from '@chiroup/core/constants/globals';
import { Invoice } from '@chiroup/core/types/Invoice.type';
import { useForm } from '@chiroup/hooks';
import { useContext, useEffect, useMemo } from 'react';
import { MeContext } from '../../../../../contexts/me.context';
import Modal from '../../../../common/Modal';
import useInvoice from './Invoice/hooks/useInvoice';

type Props = {
  invoice: Invoice | null;
  callbacks?: STRING_ANY_HASH;
};

const validation = {
  amount: {
    function: {
      value: (value: any) => {
        const { amount, max } = value;
        if (parseFloat(amount) <= 0) {
          return 'Amount must be greater than 0';
        } else if (parseFloat(amount) > parseFloat(max)) {
          return 'Amount must be less than or equal to the balance';
        }
        return false;
      },
    },
  },
};

const WriteOffModal: React.FC<Props> = ({ invoice, callbacks }) => {
  const id = useMemo(() => invoice?.id, [invoice]);
  const { me } = useContext(MeContext);
  const {
    data,
    isFetching,
    isSaving,
    writeOff: writeOffInvoice,
  } = useInvoice({
    invoiceId: id || -1,
    invoice,
  });

  const { value, errors, onChange, patchValue, registerSubmit } = useForm<{
    amount: number;
    max: number;
  }>(
    {
      amount: data?.invoiceData?.balance || 0,
      max: data?.invoiceData?.balance || 0,
    },
    validation,
  );

  useEffect(() => {
    const useThis = data ?? invoice;
    if (
      useThis?.invoiceData?.balance &&
      (!value.amount || value.max !== useThis?.invoiceData.balance)
    ) {
      patchValue({
        amount: useThis.invoiceData.balance,
        max: useThis.invoiceData.balance,
      });
    }
  }, [
    data,
    data?.invoiceData,
    invoice,
    onChange,
    patchValue,
    value,
    value.amount,
  ]);

  const closeModal = () => {
    if (callbacks?.onClose) {
      callbacks.onClose();
    } else {
      console.warn('No close callback provided');
    }
  };

  const writeOff = async () => {
    const res = await writeOffInvoice({
      invoiceId: id || -1,
      clinicId: me.selectedClinic?.ID || -1,
      amount: value.amount || 0,
    });
    if (res) {
      callbacks?.onSuccess?.();
    } else {
      callbacks?.onFail?.();
    }
  };

  return (
    <Modal isOpen>
      <div>
        {isFetching || isSaving ? (
          <LoadingPage />
        ) : (
          <>
            <div className="mt-3 sm:mt-5 flex flex-col gap-6">
              <div className="flex text-center flex-col">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-100"
                  id="modal-headline"
                >
                  Write Off
                </h3>
              </div>
              <form
                className="space-y-6"
                onSubmit={registerSubmit(writeOff, {})}
              >
                <InputMasked
                  className="w-full"
                  label={`Amount`}
                  name="amount"
                  placeholder="0.00"
                  value={value.amount}
                  onChange={onChange('amount')}
                  errors={errors?.fieldErrors?.amount}
                  numericOptions={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                  tooltip="Write-off the contractual adjustment for this purchase using this field."
                />
              </form>
            </div>
            <div className={'mt-6 flex flex-row gap-2'}>
              <Button
                text={'Commit'}
                fullWidth
                onClick={registerSubmit(writeOff, {})}
                loading={isSaving}
                disabled={isSaving || isFetching}
              />
              <Button
                text="Close"
                onClick={closeModal}
                fullWidth
                color={ButtonColors.plain}
                className="border border-gray-300 dark:border-darkGray-600"
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default WriteOffModal;
