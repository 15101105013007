import {
  Button,
  ButtonColors,
  ConfirmModal,
  FieldErrors,
  Input,
  InputMasked,
  LoadingPage,
  Select,
} from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Modal from '../../../../../components/common/Modal';
import InfoItem from '../../../../../components/common/info/InfoItem';
import SectionContainer from '../../../../../components/layout/SectionContainer';
import SectionHeader from '../../../../../components/layout/SectionHeader';
import { MeContext } from '../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import { PaymentFilters } from '../hooks/usePayments';
import useTransactions from '../hooks/useTransactions';
import PaymentTransactionItem from './PaymentTransactionItem';
import useBillingSettings from '../../../../settings/clinic/useBillingSettings';
import { STRING_BOOLEAN_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { enumToArrayOfOptions } from '@chiroup/core/functions/enumToArrayOfOptions';
import { formatCurrency } from '@chiroup/core/functions/format';
import { Patient } from '@chiroup/core/types/Patient.type';
import { CCPaymentFields } from '@chiroup/core/types/PatientBillling.type';
import {
  PatientPayment,
  PaymentTypePatient,
  PaymentType,
  PAYMENT_TYPE_DISPLAY,
} from '@chiroup/core/types/PatientPayment.type';
import {
  PatientTransaction,
  PatientTransactionClass,
} from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';

export type PayRequest = {
  payRequestKey: string | null;
  complete: boolean;
  status: string | null;
  refnum?: string | null;
  error?: string | null;
  result?: string | null;
};

type Props = {
  payment: PatientPayment;
  refetch?: () => void;
  patient: Patient;
  credits?: boolean;
  clear: () => void;
  filters?: PaymentFilters;
  updatePayRequest: (val: any) => any;
  balances:
    | {
        balance: number;
        creditBalance: number;
      }
    | undefined;
};

const PatientPaymentEditModal: React.FC<Props> = ({
  payment,
  refetch,
  patient,
  clear,
  filters,
  updatePayRequest,
  balances,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { me, hasRole } = useContext(MeContext);
  const { data: billingSettings } = useBillingSettings();
  const { createToast } = useContext(ToastContext);

  const patientId = patient.ID;
  const {
    data: transactions,
    deletePaymentTransaction,
    refundPaymentTransaction,
    clear: clearTransactions,
    isFetching: isFetchingTransactions,
  } = useTransactions({
    patientId,
    filters: { outstandingPatientBalance: true },
  });

  const [availableTransactions, setAvailableTransactions] = useState<any[]>([]);
  const [unapplyTransactionList, setUnapplyTransactionList] = useState<
    string[]
  >([]);
  const [isEditingAppliedPayment, setIsEditingAppliedPayment] =
    useState<boolean>(false);
  const [showUnapply, setShowUnapply] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRefunding, setIsRefunding] = useState(false);
  const [isUnapplyingTransactions, setIsUnapplyingTransactions] =
    useState(false);
  const [newChecked, setNewChecked] = useState<STRING_BOOLEAN_HASH>({});
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const hideCreditTransfer = useMemo(() => {
    return billingSettings?.enableCreditTransfers !== true;
  }, [billingSettings]);

  const filteredPaymentTypes = useMemo(() => {
    return enumToArrayOfOptions(PaymentTypePatient).filter((o) => {
      if (hideCreditTransfer) {
        return o.value !== PaymentType.CreditTransfer;
      }
      return true;
    });
  }, [hideCreditTransfer]);

  const amountApplied = useMemo(() => {
    return availableTransactions.reduce(
      (a: number, item: any) => a + Number(item.paymentAmount || '0'),
      0,
    );
  }, [availableTransactions]);

  const {
    value,
    onChange,
    errors,
    registerSubmit,
    isSubmitting,
    isDirty,
    patchValue,
  } = useForm<PatientPayment & CCPaymentFields>(
    payment.id
      ? {
          ...payment,
          paymentDate: dayjs(payment.paymentDate).utc().format('MM/DD/YYYY'),
        }
      : {
          type: PaymentType.Cash,
          paymentDate: dayjs().format('MM/DD/YYYY'),
          purchases: [],
          patientId,
        },
    {
      paymentDate: {
        required: {
          message: 'Payment date is required.',
        },
      },
      type: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            if (!value.type) {
              return 'Payment Type is required.';
            }
            return false;
          },
        },
      },
      referenceNumber: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            if (
              value?.type === 'check' &&
              value?.referenceNumber &&
              !value?.referenceNumber.toString().length
            ) {
              return 'Reference number is required.';
            }
            return false;
          },
        },
      },
      amount: {
        function: {
          value: (value: Partial<PatientPayment>) => {
            if (
              value?.creditAmount &&
              value?.creditAmount > (balances?.creditBalance || 0)
            ) {
              return 'Not enough credit is available.';
            }
            if (value.creditAmount) {
              // If we're applying credits, a payment amount isn't required.
              return false;
            }
            if (!value.amount) {
              return 'Amount is required.';
            }
            if (value.amount && value.amount < 0) {
              return 'Amount must be greater than 0.';
            }
            if (value?.amount < amountApplied) {
              return "Amount can't be less than total due.";
            }
            return false;
          },
        },
      },
    },
  );

  useEffect(() => {
    if (isDirty && !isEditingAppliedPayment && !!value.purchases?.length) {
      setIsEditingAppliedPayment(true);
    }
  }, [isDirty, value.purchases, isEditingAppliedPayment]);

  const remainder = useMemo(() => {
    const amounts = value?.amount
      ? value.amount -
        (value?.purchases || []).reduce(
          (a, c) => (a = a + (c.paymentAmount || 0)),
          0,
        )
      : 0;
    const newAmounts = availableTransactions.reduce(
      (a: number, c: any, idx: number) => {
        if (!newChecked[idx]) return Number(a);
        if (c.paymentAmount) return Number(a) + Number(c.paymentAmount);
        if (c.balance) return Number(a) + Number(c.balance);
        if (c.balance || c.patientBalance) {
          return (
            Number(a) +
            (!Number.isNaN(c?.patientBalance)
              ? Number(c?.patientBalance)
              : Number(c.balance))
          );
        }
        return Number(a);
      },
      0,
    );
    return (Number(amounts) - Number(newAmounts)).toFixed(2);
  }, [value, availableTransactions, newChecked]);

  /**
   * Business Rule:
   *    The same transaction can have a piece of a payment or credit
   *    applied _multiple_ times. So, it can't be removed by the item's
   *    id, it has to be done by its ordinal position in the list just
   *    in case it appears multiple times.
   */

  const handleItemSelect = useCallback(
    (
      item: PatientTransaction | PatientTransactionClass,
      deselect = false,
      order = 0,
      available = false,
      amount = 0,
    ) => {
      if (available) {
        setNewChecked((prev) => ({ ...prev, [order]: amount > 0 }));
      }

      const clone = ChiroUpJSON.clone(value?.purchases || []);
      const newAvailableItems = ChiroUpJSON.clone(availableTransactions);

      if (deselect && !available) {
        clone.splice(order, 1);
      } else {
        if (availableTransactions && availableTransactions[order]) {
          newAvailableItems[order].transactionId = item.id;
          // newAvailableItems[order].paymentAmount = item.balance || 0;
          // newAvailableItems[order].totalDue = Number(item.balance) || 0;
          newAvailableItems[order].billingKey = item.billingKey;
        }
      }

      const itemBalance = !Number.isNaN(Number(item?.patientBalance))
        ? Number(item?.patientBalance)
        : Number(item?.balance || 0);

      const creditAmount = Number(value?.amount || 0);
      const creditRemainder = Number(remainder) - itemBalance;
      if (deselect) {
        newAvailableItems[order].paymentAmount = 0;
      } else if (itemBalance > creditAmount) {
        newAvailableItems[order].paymentAmount =
          Number(value?.amount || '0') > 0 ? value?.amount : 0;
        setAvailableTransactions(newAvailableItems);
      } else if (creditRemainder < 0) {
        newAvailableItems[order].paymentAmount = Number(remainder);
        setAvailableTransactions(newAvailableItems);
      }

      if (!deselect) {
        newAvailableItems[order].paymentAmount = !Number.isNaN(
          newAvailableItems[order]?.patientBalance,
        )
          ? Number(newAvailableItems[order]?.patientBalance)
          : newAvailableItems[order].balance;
      }

      setAvailableTransactions(newAvailableItems);
      patchValue({ ...value, purchases: clone });
    },
    [patchValue, availableTransactions, remainder, value],
  );

  const closeModal = useCallback(
    (force = false) => {
      if (isSubmitting && !force) {
        return;
      }

      const path = location.pathname;
      const newPath = path.substring(0, path.lastIndexOf('/'));
      const search = location.search;
      navigate(`${newPath}${search}`);
    },
    [isSubmitting, location.pathname, location.search, navigate],
  );

  const confirmSubmit = useCallback(
    async ({ val }: { val: Partial<PatientPayment> }) => {
      if (val.id) {
        if (Number(remainder) < 0) {
          createToast({
            title: 'Error',
            description: `Not enough credit is available.`,
            type: ToastTypes.Fail,
            duration: 5000,
          });
          return;
        }

        const payload = JSON.parse(JSON.stringify(val));
        Object.keys(availableTransactions).forEach((key: string) => {
          if (newChecked[key]) {
            const paymentAmount = Number(
              availableTransactions[Number(key)].paymentAmount ||
                availableTransactions[Number(key)].balance,
            );

            payload.purchases.push({
              ...availableTransactions[Number(key)],
              paymentAmount: paymentAmount > 0 ? paymentAmount : 0,
            });
          }
        });

        const request = {
          billingKey: payment.billingKey,
          payload,
          clinicId: me?.selectedClinic?.ID || -1,
          patientId,
        };

        return updatePayRequest(request);
      }
    },
    [
      remainder,
      availableTransactions,
      payment.billingKey,
      me?.selectedClinic?.ID,
      patientId,
      updatePayRequest,
      createToast,
      newChecked,
    ],
  );

  const submit = async (val: Partial<PatientPayment>) => {
    if (!val.amount && !val.creditAmount) return;

    return confirmSubmit({ val: value });
  };

  const onSuccess = async (closeAndFetch = true) => {
    if (closeAndFetch) {
      closeModal();
      clearTransactions();
      clear();
      setAvailableTransactions([]);
      refetch?.();
    }
  };

  const onFail = (error: any) => {
    const isDuplicateRefNum =
      error?.response?.data?.fieldErrors?.referenceNumber?.code ===
      'DuplicateReferenceNumber';
    if (isDuplicateRefNum) {
      patchValue({ alertOverrode: true });
    }
    const message = error?.message;
    createToast({
      title: 'Error!',
      description: message || `There was an error saving the payment.`,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  const onError = useCallback(
    (type: string, message?: string) => {
      const toastType = type || 'saving';
      type = type === 'deleting' ? 'Delete' : type;
      createToast({
        title: `${type}!`,
        description:
          message ||
          errorMessage ||
          `There was an error ${toastType} the payment.`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    },
    [createToast, errorMessage],
  );

  const onSuccessDeleteOrRefund = (type: string) => {
    createToast({
      type: ToastTypes.Success,
      title: 'Success',
      description: `Payment ${
        type === 'delete'
          ? 'voided'
          : type === 'unapply'
            ? 'unposted'
            : 'refunded'
      } successfully.`,
    });
    const path = location.pathname;
    const firstPath = path.substring(0, path.lastIndexOf('/'));
    const finalPath = firstPath.substring(0, firstPath.lastIndexOf('/'));
    const search = location.search;
    if (type === 'unapply') {
      clearTransactions();
      clear();
      setAvailableTransactions([]);
      refetch?.();
    }
    navigate(`${finalPath}${search}`);
  };

  const unapplyTransaction = async () => {
    try {
      setIsUnapplyingTransactions(true);

      const newTransactionPayments = unapplyTransactionList?.length
        ? value?.purchases?.filter((item: any) => {
            return !unapplyTransactionList.includes(item.billingKey);
          })
        : [];

      const payload = {
        ...value,
        purchases: newTransactionPayments,
      };

      const request = {
        billingKey: payment.billingKey,
        payload,
        clinicId: me?.selectedClinic?.ID || -1,
        patientId,
      };

      await updatePayRequest(request);

      onSuccessDeleteOrRefund('unapply');
    } catch (err: any) {
      setErrorMessage(err?.response?.data || 'Error');
      const path = location.pathname;
      const newPath = path.substring(0, path.lastIndexOf('/'));
      navigate(newPath);
      onError('unapplying');
    }
    setIsUnapplyingTransactions(false);
  };

  const refund = async () => {
    setIsRefunding(true);
    try {
      await refundPaymentTransaction((payment as any).billingKey, filters);
      onSuccessDeleteOrRefund('refund');
    } catch (err: any) {
      console.error(err);
      setErrorMessage(err?.response?.data || 'Error');
      const path = location.pathname;
      const search = location.search;
      const newPath = path.substring(0, path.lastIndexOf('/'));
      navigate(`${newPath}${search}`);
      onError('refunding');
    }
    setIsRefunding(false);
  };

  const del = async () => {
    setIsDeleting(true);
    try {
      await deletePaymentTransaction((payment as any).billingKey, filters);
      onSuccessDeleteOrRefund('delete');
    } catch (err: any) {
      const message = err?.response?.data || err?.message || 'Error';
      setErrorMessage(message);
      const path = location.pathname;
      const newPath = path.substring(0, path.lastIndexOf('/'));
      navigate(newPath);
      onError('deleting', message);
    }

    setIsDeleting(false);
  };

  useEffect(() => {
    const newbies = (transactions?.pages[0]?.data || [])?.map((transaction) => {
      return {
        ...transaction,
      };
    });
    setAvailableTransactions(newbies);
  }, [transactions?.pages]);

  const availableOrder = useMemo(() => {
    return availableTransactions?.reduce((obj, t, index) => {
      obj[t.billingKey] = index;
      return obj;
    }, {});
  }, [availableTransactions]);

  /**
   * This is weird and probably needs to be refactored. Basically,
   * there are two halves to the modal, the things that are there
   * and the NEW things that are being added. It looks like one
   * list but they are TWO arrays in this modal. So...the flag
   * 'available' is used to designate the ones that are new.
   */
  const updateSelectedItems = useCallback(
    (id: number, amount: number, order: number, available: boolean) => {
      const clone = ChiroUpJSON.clone(value?.purchases || []);

      if (available) {
        const newItems = ChiroUpJSON.clone(availableTransactions);
        if (newItems[order]) {
          newItems[order].transactionId = id;
          newItems[order].paymentAmount = amount >= 0 ? amount : 0;
          // newItems[order].totalDue = amount;
          newItems[order].billingKey = availableTransactions[order].billingKey;
        }
        setAvailableTransactions(newItems);
      } else {
        if (clone && clone[order]) {
          clone[order].paymentAmount = amount;
        }

        patchValue({ ...value, purchases: clone });
      }
    },
    [patchValue, availableTransactions, value],
  );

  const filiteredAvailableTransactions = useMemo(() => {
    const appliedTransactionIds: string[] =
      payment.purchases?.map((t) => t.billingKey) ?? [];

    return availableTransactions?.filter(
      (t) => !appliedTransactionIds.includes(t.billingKey),
    );
  }, [availableTransactions, payment.purchases]);

  return (
    <Modal
      addClasses="max-w-4xl w-full p-0"
      omitClasses="sm:max-w-lg px-4 pt-5 pb-4 sm:p-6 sm:pb-4 sm:p-6 px-6 pt-5 pb-4"
      isOpen
      close={() => closeModal(false)}
    >
      <form
        onSubmit={registerSubmit(submit, {
          onSuccess: () => {
            onSuccess(true);
          },
          onFail,
        })}
      >
        <div className="">
          <div className="flex flex-col">
            <div className="flex flex-col">
              <SectionContainer parentClassName="">
                <SectionHeader
                  title={`Payment # ${payment.number ?? payment.id}`}
                  rightSide={
                    <div className="flex flex-row items-center space-x-2.5">
                      {payment?.isRefunded ? (
                        <h3 className=" inline-flex items-center text-center px-2 py-1 text-md font-medium text-primary-700 rounded-md bg-primary-50 ring-1 ring-inset ring-primary-600/50">
                          <span>Payment has been refunded.</span>
                        </h3>
                      ) : (
                        <div className="flex flex-row items-center space-x-2.5">
                          {isEditing ? (
                            <Button
                              text="Cancel Edit"
                              onClick={() => {
                                setIsEditing(false);
                                setShowUnapply(false);
                                setUnapplyTransactionList([]);
                              }}
                              color={ButtonColors.plainWithBorder}
                            />
                          ) : payment.isVoided ? null : (
                            <>
                              <Button
                                text="Edit"
                                onClick={() => setIsEditing(true)}
                                icon={
                                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                }
                                color={ButtonColors.plainWithBorder}
                              />

                              <Link
                                to={`delete${location?.search || ''}`}
                                className="text-sm font-medium leading-6 text-primary-600 hover:text-primary-500 cursor-pointer"
                              >
                                <Button
                                  text="Void"
                                  color={ButtonColors.red}
                                  disabled={
                                    value?.purchases?.length ? true : false
                                  }
                                  tooltip={
                                    value?.purchases?.length
                                      ? 'Void for applied payments is not allowed.  Please unapply the payment first.'
                                      : ''
                                  }
                                />
                              </Link>
                              <Link
                                to={`refund${location?.search || ''}`}
                                className="text-sm font-medium leading-6 text-primary-600 hover:text-primary-500 cursor-pointer"
                              >
                                <Button
                                  text="Refund"
                                  color={ButtonColors.primary}
                                  disabled={
                                    !!value.creditAmount ||
                                    !!value?.purchases?.length
                                  }
                                  tooltip={
                                    value.creditAmount
                                      ? 'Refund is not yet allowed for Credit payments.'
                                      : value?.purchases?.length
                                        ? 'Refund for applied payments is not allowed.  Please unapply the payment first.'
                                        : ''
                                  }
                                />
                              </Link>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  }
                />
                <div className="px-4 py-5 sm:px-6">
                  {isEditing ? (
                    <div className="">
                      {/* ???bwm??? replace with AlertBlock when available */}
                      {!!value?.purchases?.length && !payment.verified ? (
                        <>
                          {payment.verifiedBy && (
                            <p className="text-red-500">{`Payment unverified by ${payment.verifiedBy}`}</p>
                          )}
                          <p className="mt-8 inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/50">
                            <span>
                              Alert: Payment has been applied! Please note that
                              any modifications to this Payment could affect the
                              associated invoices and applied payments. Exercise
                              caution while making changes.
                            </span>
                          </p>
                        </>
                      ) : payment.verified && payment.verifiedBy ? (
                        <p className="text-primary-500">{`Payment verified on ${dayjs(
                          payment.verified,
                        ).format('MM/DD/YYYY')} by ${payment.verifiedBy}`}</p>
                      ) : null}
                      <div className="flex flex-col sm:flex-row sm:gap-2 w-full">
                        <InputMasked
                          name="paymentDate"
                          label="Payment date *"
                          value={value.paymentDate}
                          onChange={onChange('paymentDate')}
                          errors={errors.fieldErrors?.paymentDate}
                          className="w-full"
                          patternFormat="##/##/####"
                          placeholder="MM/DD/YYYY"
                          disabled={
                            isSubmitting ||
                            payment?.isRefunded ||
                            !!payment.verified
                          }
                        />
                        <InputMasked
                          className="w-full"
                          label={`Payment amount`}
                          name="amount"
                          placeholder="0.00"
                          value={value.amount}
                          onChange={onChange('amount')}
                          errors={errors?.fieldErrors?.amount}
                          numericOptions={{
                            decimalScale: 2,
                            fixedDecimalScale: true,
                            // prefix: '$',
                          }}
                          disabled={
                            isSubmitting ||
                            payment?.isRefunded ||
                            value.purchases?.length !== 0 ||
                            !!payment.verified
                          }
                        />
                      </div>
                      <Select
                        name="subtype"
                        label="Payment type *"
                        options={filteredPaymentTypes}
                        onChange={() => {}}
                        value={value.subtype}
                        limit={1}
                        errors={errors.fieldErrors?.subtype}
                        disabled={true}
                      />
                      {value.subtype === 'check' && (
                        <Input
                          name="referenceNumber"
                          label="Reference number"
                          value={value?.referenceNumber || ''}
                          onChange={(val: number) => {
                            patchValue({
                              referenceNumber: val,
                              alertOverrode: false,
                            });
                          }}
                          errors={errors.fieldErrors?.referenceNumber}
                          disabled={
                            isSubmitting ||
                            payment?.isRefunded ||
                            !!payment.verified
                          }
                          type="number"
                        />
                      )}
                      <Input
                        name="description"
                        label="Description"
                        value={value?.description || ''}
                        onChange={onChange('description')}
                        errors={errors.fieldErrors?.description}
                        disabled={isSubmitting || payment?.isRefunded}
                      />
                      {!!errors.form?.length && (
                        <FieldErrors
                          errors={{
                            message:
                              errors.form
                                ?.map((err) => {
                                  return err.message;
                                })
                                ?.join(', ') || '',
                          }}
                        />
                      )}
                      <div className={'mt-6 flex flex-row gap-2'}>
                        {errorMessage && (
                          <p className="text-red-500 text-sm">
                            {typeof errorMessage === 'string'
                              ? errorMessage
                              : typeof errorMessage?.message === 'string'
                                ? errorMessage.message
                                : JSON.stringify(errorMessage)}
                          </p>
                        )}
                      </div>

                      {payment.purchases?.length === 0 ? (
                        <div className="">
                          This payment is not applied to any transactions.
                        </div>
                      ) : !showUnapply ? (
                        <>
                          <div className="mt-10 mb-6">
                            <span>Applied Transaction(s)</span>
                            <div className="inline float-end">
                              <div className="ml-4 sm:ml-64">
                                <span
                                  onClick={() =>
                                    setShowUnapply((prev) => !prev)
                                  }
                                  className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs"
                                >
                                  Unpost payment(s)
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className=" bg-white rounded-md border-gray-300 border pt-1 dark:bg-darkGray-700">
                            <ul className="divide-y divide-gray-100">
                              {payment.purchases?.map(
                                (item: any, index: number) => (
                                  <PaymentTransactionItem
                                    order={availableOrder[item.billingKey]}
                                    item={{
                                      ...item,
                                      paymentAmount:
                                        payment.items?.find(
                                          (i) =>
                                            i.paymentToward === item.billingKey,
                                        )?.amount || 0,
                                    }}
                                    patientId={patientId}
                                    key={`at-${item.id}-${index}`}
                                    handleItemSelect={handleItemSelect}
                                    updateSelectedItems={updateSelectedItems}
                                    value={value}
                                    isNew={false}
                                    editing={isEditing}
                                    available={false}
                                    disabled={true}
                                    setUnapplyTransactionList={
                                      setUnapplyTransactionList
                                    }
                                    isApplied={unapplyTransactionList?.includes(
                                      item.billingKey,
                                    )}
                                    transactionLength={
                                      payment.purchases?.length
                                    }
                                  />
                                ),
                              )}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <>
                          {!payment.verified && (
                            <div className="mt-10 mb-6">
                              <span>
                                {showUnapply
                                  ? 'Select transactions to unpost'
                                  : 'Posted Transaction(s)'}
                              </span>
                            </div>
                          )}
                          <div className=" bg-white rounded-md border-gray-300 border pt-1 dark:bg-darkGray-700">
                            <ul className="divide-y divide-gray-100">
                              {payment.purchases?.map(
                                (item: any, index: number) => (
                                  <PaymentTransactionItem
                                    order={availableOrder[item.billingKey]}
                                    item={{
                                      ...item,
                                      paymentAmount:
                                        payment.items?.find(
                                          (i) =>
                                            i.paymentToward === item.billingKey,
                                        )?.amount || 0,
                                    }}
                                    patientId={patientId}
                                    key={`at-${item.id}-${index}`}
                                    handleItemSelect={handleItemSelect}
                                    updateSelectedItems={updateSelectedItems}
                                    value={value}
                                    isNew={false}
                                    editing={isEditing}
                                    available={false}
                                    disabled={true}
                                    showUnapply={showUnapply}
                                    setUnapplyTransactionList={
                                      setUnapplyTransactionList
                                    }
                                    isApplied={unapplyTransactionList?.includes(
                                      item.billingKey,
                                    )}
                                    transactionLength={
                                      payment.purchases?.length
                                    }
                                  />
                                ),
                              )}
                            </ul>
                          </div>
                        </>
                      )}
                      <div className="flex flex-col text-left w-full">
                        {payment.verified &&
                        payment.balance ===
                          0 ? null : isFetchingTransactions ? (
                          <LoadingPage title="Loading Transactions" />
                        ) : showUnapply ? (
                          <></>
                        ) : filiteredAvailableTransactions?.length > 0 ? (
                          <>
                            <div className="mt-10">
                              Outstanding Transaction(s)
                            </div>
                            <div className=" bg-white rounded-md border-gray-300 border pt-1 dark:bg-darkGray-700">
                              <ul className="divide-y divide-gray-100">
                                {filiteredAvailableTransactions?.map(
                                  (transaction, index) => {
                                    return (
                                      <PaymentTransactionItem
                                        order={
                                          availableOrder[transaction.billingKey]
                                        }
                                        item={transaction}
                                        patientId={patientId}
                                        key={transaction.billingKey}
                                        handleItemSelect={handleItemSelect}
                                        updateSelectedItems={
                                          updateSelectedItems
                                        }
                                        value={value}
                                        isNew={true}
                                        disabled={
                                          isSubmitting || payment?.isRefunded
                                        }
                                        available={true}
                                        editing={isEditing}
                                      />
                                    );
                                  },
                                )}
                              </ul>
                            </div>
                          </>
                        ) : !showUnapply ? (
                          <div className="mt-10">
                            No Outstanding Transactions{' '}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <InfoItem
                          label="Patient"
                          value={`${patient?.lname}, ${patient.fname}`}
                        />
                        <InfoItem
                          label="Payment Date"
                          value={ChiroUpDayJsCommon.display.date(
                            payment.paymentDate,
                            'UTC',
                          )}
                        />
                        <InfoItem
                          label="Amount"
                          value={formatCurrency(
                            payment.amount - (payment?.processingFee || 0),
                          )}
                        />
                        {(payment?.processingFee || 0) > 0 && (
                          <InfoItem
                            label="Processing Fee"
                            value={formatCurrency(payment?.processingFee || 0)}
                          />
                        )}
                        <InfoItem
                          label="Type"
                          value={
                            payment.subtype
                              ? PAYMENT_TYPE_DISPLAY[payment.subtype]
                              : ''
                          }
                        />
                        <InfoItem
                          label="Reference Number"
                          value={
                            !payment.referenceNumber ||
                            payment.referenceNumber.toString() === 'undefined'
                              ? 'N/A'
                              : payment.referenceNumber
                          }
                        />
                        <InfoItem
                          label="Unposted Amount"
                          value={formatCurrency(payment.balance)}
                          show={payment.balance > 0}
                        />
                        <InfoItem
                          label="Description"
                          value={payment.description}
                        />
                      </dl>
                      {payment.purchases?.length === 0 ? (
                        <div className="mt-10">
                          This payment is not posted to any transactions.
                        </div>
                      ) : (
                        <>
                          <div className="mt-10">Posted Transaction(s)</div>
                          <div className=" bg-white rounded-md border-gray-300 border pt-1 dark:bg-darkGray-700">
                            <ul className="divide-y divide-gray-100">
                              {payment.purchases?.map(
                                (item: any, index: number) => (
                                  <PaymentTransactionItem
                                    order={availableOrder[item.billingKey]}
                                    item={{
                                      ...item,
                                      paymentAmount:
                                        payment.items?.find(
                                          (i) =>
                                            i.paymentToward === item.billingKey,
                                        )?.amount || 0,
                                    }}
                                    key={`${index}-${item.id}`}
                                    handleItemSelect={handleItemSelect}
                                    updateSelectedItems={updateSelectedItems}
                                    value={value}
                                    isNew={false}
                                    disabled={true}
                                    patientId={patientId}
                                  />
                                ),
                              )}
                            </ul>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {/* <div>
                    <div className="flex items-center justify-around my-4">
                      {!!value.amount && value?.amount > amountApplied && (
                        <>The applied amount is not equal to amount. (Test)</>
                      )}
                    </div>
                  </div> */}
                  <div className={'mt-6 flex flex-row gap-2 justify-between'}>
                    <Button
                      text="Close"
                      onClick={() => closeModal(false)}
                      color={ButtonColors.plain}
                      className="border border-gray-300 dark:border-darkGray-600"
                      disabled={isSubmitting}
                    />
                    {!payment?.isRefunded &&
                      !payment.isVoided &&
                      hasRole([
                        UserRoles.Admin,
                        UserRoles.Biller,
                        UserRoles.Staff,
                      ]) &&
                      (showUnapply && !!unapplyTransactionList?.length ? (
                        <Link
                          to={`unapply${location?.search || ''}`}
                          className="text-sm font-medium leading-6 text-primary-600 hover:text-primary-500 cursor-pointer"
                        >
                          <Button
                            text={
                              payment?.purchases &&
                              unapplyTransactionList?.length !==
                                payment?.purchases?.length
                                ? `Unpost ${unapplyTransactionList?.length}`
                                : 'Unpost All'
                            }
                            tooltip="This will unpost this payment from the transactions."
                            loading={isUnapplyingTransactions}
                            disabled={isUnapplyingTransactions}
                          />
                        </Link>
                      ) : (
                        !showUnapply && (
                          <Button
                            text={value.alertOverrode ? 'Save Anyway' : 'Save'}
                            type="submit"
                            loading={isSubmitting}
                            disabled={
                              !isDirty ||
                              isSubmitting ||
                              (!value.amount && !value.creditAmount) ||
                              payment?.isRefunded
                            }
                          />
                        )
                      ))}
                  </div>
                </div>
              </SectionContainer>
            </div>
          </div>
        </div>
      </form>
      {hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
        <Routes>
          <Route
            path={`refund/*`}
            element={
              <ConfirmModal
                isOpen={true}
                confirm={refund}
                close={closeModal}
                loading={isRefunding}
                confirmText="Refund"
                description={`Are you sure you want to refund this payment?`}
              />
            }
          />
          <Route
            path={`delete/*`}
            element={
              <ConfirmModal
                isOpen={true}
                confirm={del}
                close={closeModal}
                loading={isDeleting}
                confirmText="Void"
                title="Void Payment"
                description={`Are you sure you want to void this payment?`}
              />
            }
          />
          <Route
            path={`unapply/*`}
            element={
              <ConfirmModal
                isOpen={true}
                confirm={unapplyTransaction}
                close={closeModal}
                loading={isUnapplyingTransactions}
                confirmText="Unpost Payment"
                description={`Are you sure you want to unpost this payment? This does not refund the payment.`}
              />
            }
          />
        </Routes>
      )}
    </Modal>
  );
};

export default PatientPaymentEditModal;
