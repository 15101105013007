import { STRING_ANY_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import {
  Appointment,
  AppointmentForUI,
} from '@chiroup/core/types/Appointment.type';
import { useCallback, useContext } from 'react';
import { MeContext } from '../../contexts/me.context';
import { TreatmentHash } from './ScheduleAppointment';
import dayjs from 'dayjs';

export type ProvidersAndTreatmentsToTracks = {
  providerToTrackIds: Record<string, number[]>;
  treatmentToTrackIds: Record<string, number[]>;
};

export interface UpdateSlotParams {
  ord: number;
  key: string;
  value: any;
}

interface UseUpdateMySlotProps {
  disciplineOptions: any[];
  providersAndTreatmentsToTracks: ProvidersAndTreatmentsToTracks;
  treatmentHash?: TreatmentHash;
  setValue: (value: AppointmentForUI) => void;
  value: any;
  rooms: number[];
}

const useUpdateMySlot = ({
  disciplineOptions,
  providersAndTreatmentsToTracks,
  treatmentHash,
  setValue,
  value,
  rooms,
}: UseUpdateMySlotProps) => {
  const { me } = useContext(MeContext);

  const updateMySlot = useCallback(
    ({
      ord,
      key,
      value: replacementValue,
    }: {
      ord: number;
      key: string;
      value: any;
    }) => {
      if (replacementValue === undefined) return;
      const newv = ChiroUpJSON.clone(value) as AppointmentForUI;
      newv.slots = newv.slots || [];
      const slot = (newv.slots[ord] ?? {}) as STRING_ANY_HASH;
      slot[key] = replacementValue;
      if (key === 'treatmentId') {
        const discipline = disciplineOptions.find(
          (option: any) => option.treatments[slot.treatmentId],
        );
        const treatmentSpecificDuration =
          treatmentHash?.objs?.[replacementValue]?.treatmentDuration;
        const isPrincipalUsed = newv.slots.reduce(
          (acc: boolean, slot: Appointment) => {
            return slot.treatmentId === newv.treatmentId || acc;
          },
          false,
        );
        if (treatmentSpecificDuration) {
          slot.duration = treatmentSpecificDuration;
        }
        if (!isPrincipalUsed) {
          newv.treatmentId = replacementValue;
        }
        if (discipline && discipline.value) {
          slot.disciplineId = discipline.value;
        }

        const tHash = treatmentHash?.options.find(
          (t: any) => t.value === replacementValue,
        );

        const room: number | undefined =
          tHash?.rooms[me.selectedLocation as number];

        if (room && rooms.includes(room)) {
          slot.roomId = room;
        }

        if (
          !!slot?.createTrackCCSSubmission &&
          replacementValue &&
          !!treatmentHash?.objs[replacementValue]?.suppressAutomatedSurveys
        ) {
          slot.createTrackCCSSubmission = false;
        }
      } else if (key === 'startTime' || key === 'duration') {
        // Change all slots after this one to be directly after this time.
        const hasSubsequentSlots = newv.slots.length > ord + 1;
        if (hasSubsequentSlots) {
          const subsequentSlots = newv.slots.slice(ord + 1);
          subsequentSlots.forEach((slot, i) => {
            const previousSlot = subsequentSlots?.[i - 1] || newv.slots?.[ord];
            if (!previousSlot) return;
            const previousSlotDayjs = dayjs.utc(previousSlot.startTime);
            const previousSlotDuration = previousSlot.duration;
            const thisSlotStartTime = previousSlotDayjs.add(
              previousSlotDuration,
              'minute',
            );
            slot.startTime = thisSlotStartTime.valueOf();
          });
        }
      }

      if (newv.slots.length === 1) {
        newv.disciplineId = disciplineOptions.find(
          (option: any) => option.treatments[slot.treatmentId],
        )?.value;
        newv.treatmentId = slot.treatmentId;
        newv.clinicianId = slot.clinicianId;
        newv.roomId = slot.roomId;
      }

      // Make sure we still have a primary discipline id.
      const isDisciplineUsed = newv?.slots?.find((slot) => {
        return slot.treatmentId === newv.treatmentId;
      });
      if (!isDisciplineUsed) {
        const disciplineId = disciplineOptions.find(
          (option: any) => option.treatments[slot.treatmentId],
        )?.value;
        newv.disciplineId = disciplineId;
      }

      // Since clinician id is optional on a slot, we need to go
      // find one.
      const isClinicianUsed = newv?.slots?.find((slot) => {
        return slot.clinicianId && slot.clinicianId === newv.clinicianId;
      });
      // console.log({ isClinicianUsed });
      if (!isClinicianUsed) {
        const slotWithClinician = newv?.slots?.find(
          (slot) => slot.clinicianId && slot.clinicianId !== '-1',
        );
        if (slotWithClinician) {
          newv.clinicianId = slotWithClinician.clinicianId;
        } else {
          newv.clinicianId = '-1';
        }
      }

      //filling in the trackId if the patient has one.

      if (
        (key === 'clinicianId' || key === 'treatmentId') &&
        !slot?.createTrackCCSSubmission
      ) {
        const ordClinicianId = newv.slots[ord]?.clinicianId;
        const firstInstanceOfProvider = newv.slots.findIndex(
          (slot) => slot.clinicianId === ordClinicianId,
        );
        const isFirstInstance = firstInstanceOfProvider === ord;
        if (isFirstInstance) {
          const tracksForProvider =
            providersAndTreatmentsToTracks?.providerToTrackIds[
              key === 'clinicianId' ? replacementValue : slot?.clinicianId
            ] || [];
          const tracksForTreatment =
            providersAndTreatmentsToTracks?.treatmentToTrackIds[
              key === 'treatmentId' ? replacementValue : slot?.treatmentId
            ] || [];
          let match;

          if (tracksForProvider?.length && tracksForTreatment?.length) {
            match = tracksForProvider.find((trackId) =>
              tracksForTreatment.includes(trackId),
            );
          }

          //if there are multiple tracks that match, then the user has to select the correct track...
          const count = tracksForProvider?.filter((trackId) =>
            tracksForTreatment.includes(trackId),
          ).length;

          if (match && count === 1) {
            slot.trackId = match;
          } else {
            slot.trackId = null;
          }
        }
        if (key === 'clinicianId') {
          if (!newv?.clinicianId || newv?.clinicianId === '-1') {
            newv.clinicianId = replacementValue;
          }
          newv?.slots.forEach((slot) => {
            if (slot?.roomId && !slot.clinicianId) {
              slot.clinicianId = '-1';
            }
          });
        }
      }

      if (key === 'createTrackCCSSubmission' && replacementValue) {
        slot.trackId = undefined;
      }

      if (key === 'trackId') {
        slot.trackId === -1
          ? (slot.createTrackCCSSubmission = true)
          : (slot.createTrackCCSSubmission = false);
      }

      if (key === 'roomId' && newv?.slots?.length !== 1) {
        const obj = newv?.slots?.[ord];
        if (!obj.clinicianId) {
          obj.clinicianId = '-1';
        }
        // console.log({ slot, roomId: replacementValue, obj, newv });
      }

      setValue(newv);
    },
    [
      disciplineOptions,
      me.selectedLocation,
      providersAndTreatmentsToTracks.providerToTrackIds,
      providersAndTreatmentsToTracks.treatmentToTrackIds,
      setValue,
      treatmentHash?.objs,
      treatmentHash?.options,
      value,
      rooms,
    ],
  );

  return updateMySlot;
};

export default useUpdateMySlot;
