import { Loading, OpenClosedStates, Toggle } from '@chiroup/components';
import { IntegrationInvoice } from '@chiroup/core/types/Invoice.type';
import { PrinterIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useContext, useMemo, useState } from 'react';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import useInvoiceNotes from '../clinical-skills/hooks/useInvoiceNotes';
import IconButton from '../common/IconButton';
import Modal from '../common/Modal';
import InvoiceNotesLog from './InvoiceNotesLog';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';

type InvoiceNotesModalProps = {
  invoice?: IntegrationInvoice;
  state: OpenClosedStates;
  onClose: () => void;
  useIntegrationInvoicesQueryKey?: any[] | null | undefined;
};

const InvoiceNotesModal: React.FC<InvoiceNotesModalProps> = ({
  invoice,
  state,
  onClose,
  useIntegrationInvoicesQueryKey,
}) => {
  const { createToast } = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [updatedInvoice, setUpdatedInvoice] =
    useState<IntegrationInvoice | null>(null);
  const invoiceNotes = useInvoiceNotes({
    invoiceId: invoice?.id,
    disabled: typeof invoice?.id !== 'number' || invoice?.id < 0,
    useIntegrationInvoicesQueryKey,
  });
  const { toggleFlag, data, downloadPdf } = invoiceNotes;

  const isFlagged = useMemo(() => {
    if (updatedInvoice && typeof updatedInvoice.isFlagged === 'boolean') {
      return updatedInvoice.isFlagged;
    }
    return invoice?.isFlagged === true;
  }, [invoice?.isFlagged, updatedInvoice]);

  const handleDownloadPdf = async () => {
    try {
      setIsPdfLoading(true);
      createToast({
        title: 'Generating PDF',
        description: 'Preparing invoice notes PDF for download...',
        type: ToastTypes.Info,
        duration: 3000,
      });

      await downloadPdf();

      createToast({
        title: 'PDF Generated',
        description: 'Invoice notes PDF has been downloaded.',
        type: ToastTypes.Success,
        duration: 3000,
      });
    } catch (error) {
      console.error('Error downloading PDF:', error);
      createToast({
        title: 'Error',
        description: 'Failed to generate PDF. Please try again.',
        type: ToastTypes.Fail,
        duration: 3000,
      });
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleToggleFlag = async () => {
    if (!invoice?.id) return;
    try {
      setIsLoading(true);
      const newFlagState = !isFlagged;
      const resp = await toggleFlag(newFlagState);
      if (resp && resp?.invoice) {
        setUpdatedInvoice(() => ({
          ...resp.invoice,
          dirty: new Date().getTime(),
        }));
      }

      createToast({
        title: 'Flag Status',
        description: `Invoice has been ${
          newFlagState ? 'flagged' : 'unflagged'
        }.`,
        type: ToastTypes.Success,
        duration: 3000,
      });
    } catch (error) {
      createToast({
        title: 'Error',
        description: 'Failed to update invoice flag status.',
        type: ToastTypes.Fail,
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={state === OpenClosedStates.Open}
      close={onClose}
      omitClasses="sm:max-w-lg"
      addClasses="sm:max-w-4xl"
    >
      {/* <pre>{ChiroUpJSON.pretty(data)}</pre> */}
      <div className="p-4 flex flex-col space-y-4 max-w-4xl w-full">
        {/* <pre>
          {ChiroUpJSON.pretty({
            useMemoIsFlagged: isFlagged,
            invoiceIsFlagged: invoice?.isFlagged,
            updatedIsFlagged: updatedInvoice?.isFlagged,
          })}
        </pre> */}
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">Claim Notes</h2>
          <div className="flex items-center space-x-4">
            <Loading
              flag={isLoading || isPdfLoading}
              style={`tiny-inline-gray`}
            />
            <Toggle
              className="col-span-12"
              label={<div>Flag</div>}
              value={isFlagged}
              onChange={handleToggleFlag}
              disabled={isLoading}
            />
            <IconButton
              className="h-6 w-6 col-span-1 text-gray-400 hover:text-gray-500 dark:text-darkGray-400 dark:hover:text-darkGray-300"
              icon={<PrinterIcon />}
              tooltip="Print"
              onClick={handleDownloadPdf}
              disabled={isPdfLoading || !data?.rows?.length}
            />
            <IconButton
              className="h-7 w-7 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300 print:hidden "
              icon={<XMarkIcon />}
              tooltip="Close"
              onClick={onClose}
              disabled={isLoading || isPdfLoading}
            />
          </div>
        </div>
        <InvoiceNotesLog invoiceNotes={invoiceNotes} />
      </div>
    </Modal>
  );
};

export default InvoiceNotesModal;
