import { Button, ButtonColors, Input } from '@chiroup/components';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';

type Props = {
  openDialog: boolean;
  close: () => void;
  value?: string;
  onChange?: (val: string, result: string) => void;
  result: string;
};

export const ReasonModal: React.FC<Props> = ({
  close,
  onChange,
  value,
  openDialog,
  result,
}) => {
  const [localValue, setLocalValue] = useState<string>(value || '');

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const closeDialog = () => {
    onChange?.(localValue, result);
    setLocalValue('');
    close();
  };

  return (
    <Transition.Root show={openDialog} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-darkGray-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <form
                  className="flex justify-around pt-5 pb-2 w-full"
                  onSubmit={(e) => {
                    e.preventDefault();
                    closeDialog();
                  }}
                >
                  <Input
                    name="other"
                    type="text"
                    value={localValue}
                    onChange={setLocalValue}
                    label={`${result} Explanation`}
                    className="w-full"
                    autoFocus
                  />
                </form>
                <div className="flex flex-row justify-between mt-5">
                  <Button
                    onClick={closeDialog}
                    text="Close"
                    color={ButtonColors.plainWithBorder}
                    className="mt-5"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
