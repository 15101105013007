import { Payor } from './Payor.type';

export type Insurance = {
  acceptAssignment?: boolean;
  active: 0 | 1;
  adjustorInformation?: AdjustorInformation;
  clinicId: number;
  createdAt: number;
  deductible?: number;
  annualDeductible?: number;
  deleted?: number;
  disciplineBenefits?: DisciplineInsuranceBenefit[];
  id: string;
  inNetwork?: boolean;
  insuranceBack?: string;
  insuranceFront?: string;
  insuranceProgram?: string;
  insuranceTypeCode?: string;
  internalName?: string;
  legalName?: string;
  memberId: string;
  name?: string;
  patientId: string;
  payor: number;
  payorID?: string;
  planGroupId?: string;
  policyEndDate?: string | number | null;
  policyMaximum?: number;
  primaryInsured?: PatientPrimaryInsured;
  primaryInsuredType?: PrimaryInsuredTypes;
  propertyAndCasualtyInsurance?: PropertyAndCasualtyInsurance;
  releaseOfPatientInformation?: boolean;
  signatureOnFile?: boolean;
  type: PatientInsuranceTypes;
  updatedAt: number;
  inNetworkProvider?: boolean;
  maxPerVisit?: number | string | null;
  billable?: boolean;
  pending?: boolean;
  serviceCodes?: string[];
  serviceAllowedAmounts?: Payor[];
};

export type HealthcomInsurance = {
  id: string;
  payor?: number;
  name?: string;
  legalName?: string;
  active: 0 | 1;
  patientId: string;
  clinicId: number;
  createdAt: number;
  updatedAt: number;
  type: PatientInsuranceTypes | PatientInsuranceTypesSpanish;
  memberId: string;
  planGroupId?: string;
  primaryInsuredType?:
    | PrimaryInsuredTypes
    | PrimaryInsuredTypesSurvey
    | PrimaryInsuredTypesSpanishSurvey;
  primaryInsured?: PatientPrimaryInsured;
  insuranceFront?: string;
  insuranceBack?: string;
  clinicIdPatientId: string;
  insuranceFrontPreview?: string | null;
  insuranceBackPreview?: string | null;
  signatureOnFile?: boolean;
  acceptAssignment?: boolean;
  releaseOfPatientInformation?: boolean;
  pending?: boolean;
};

export enum PatientInsuranceTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Other = 'other',
}

export enum PatientInsuranceTypesSpanish {
  Primario = 'primary',
  Secundario = 'secondary',
  Otro = 'other',
}

export enum PrimaryInsuredTypesSurvey {
  Self = 'patient',
  Spouse = 'spouse',
  Child = 'parent',
  Employee = 'employer',
  Other = 'other',
}

export enum PrimaryInsuredTypesSpanishSurvey {
  'Mi persona' = 'patient',
  Pareja = 'spouse',
  'Padre/Madre' = 'parent',
  Empleado = 'employer',
  Otro = 'other',
}

export enum PrimaryInsuredTypes {
  Patient = 'patient',
  Spouse = 'spouse',
  Parent = 'parent',
  Employer = 'employer',
  Other = 'other',
}

export const primaryInsuredTypeDisplay = (type?: PrimaryInsuredTypes) => {
  // console.log('type', type);
  switch (type) {
    case PrimaryInsuredTypes.Patient:
      return 'Patient';
    case PrimaryInsuredTypes.Spouse:
      return 'Spouse';
    case PrimaryInsuredTypes.Parent:
      return 'Parent';
    case PrimaryInsuredTypes.Employer:
      return 'Employer';
    case PrimaryInsuredTypes.Other:
      return 'Other';
    default:
      return 'Unknown';
  }
};

/**
 * This is broken down by type, but there are only two options.
 * Easy enough to tweak should the requirements change. The idea
 * is that even if it is employer or other, there still needs to
 * be a first and last name.
 *
 * @param pi
 * @returns
 */
export const primaryInsuredNameDisplay = (
  pi?:
    | (PatientPrimaryInsured & { memberId: string; type: PrimaryInsuredTypes })
    | null,
) => {
  if (!pi) return `No primary insured.`;
  switch (pi.type) {
    case PrimaryInsuredTypes.Patient:
      return null;
    default:
      return `${pi.lname ?? '- last -'}, ${pi.fname ?? '- first -'}`;
  }
};

export const PrimaryInsuredTypeEdiCode = {
  [PrimaryInsuredTypes.Patient]: '18',
  [PrimaryInsuredTypes.Spouse]: '01',
  [PrimaryInsuredTypes.Parent]: '19',
  [PrimaryInsuredTypes.Employer]: '20',
  [PrimaryInsuredTypes.Other]: 'G8',
  _unknown: '21',
};

export type PatientPrimaryInsured = {
  fname?: string;
  mname?: string;
  lname?: string;
  suffix?: string;
  dateOfBirth?: string;
  sex?: InsuranceSexTypes | InsuranceSexTypesSpanish;
  addressSameAsPatient?: boolean;
  streetAddress?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  phone?: string;
  countrty?: string;
};

export type DisciplineInsuranceBenefit = {
  disciplineId: number;
  maxNumberOfTreatments?: number;
  coPay?: number;
  coPayExempt?: boolean;
  coInsurance?: number;
  priorAuthorization?: PriorAuthorization;
  referralNumber?: string;
};

export type PriorAuthorization = {
  number?: string;
  validStartDate?: string | number | null;
  validEndDate?: string | number | null;
  numberOfUses?: number;
};

export type AdjustorInformation = {
  name?: string;
  phone?: string;
  extension?: string;
  email?: string;
};

export type PropertyAndCasualtyInsurance = {
  claimNumber?: string;
  contactName?: string;
  claimContactPhone?: string;
  extension?: string;
  patientIdType?: string;
  ssn?: string;
  memberId?: string;
};

export enum InsuranceSexTypes {
  Male = 'male',
  Female = 'female',
  X = 'x',
}

export enum InsuranceSexTypesSpanish {
  Masculino = 'male',
  Femenino = 'female',
  X = 'x',
}
