import {
  Checkbox,
  Currency,
  Input,
  InputMasked,
  Select,
  TrivialTooltip,
} from '@chiroup/components';
import { useEffect, useState } from 'react';
import { getBillingPriorityError } from './EncounterInsurancePolicy';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import {
  RootCallbackEvent,
  RootCallbackProps,
} from '../../../billing/transactions/ConsolidatedTransactionModal';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';

type Props = {
  index: number;
  insurances: Partial<AppointmentInsuranceType>[];
  rootCallback?: (props: RootCallbackProps) => void;
  readOnly?: boolean;
  insuranceErrors:
    | {
        index: number;
        field: keyof AppointmentInsuranceType;
        message: string;
      }[]
    | [];
  coPay?: number;
  coInsurance?: number;
  allowBillingPriorityChange: boolean;
  transaction: PatientTransaction | null | undefined;
};

const EncounterInsuranceFormFields: React.FC<Props> = ({
  index,
  rootCallback,
  insurances,
  readOnly = false,
  insuranceErrors,
  allowBillingPriorityChange,
  transaction,
}) => {
  const [selectedBillingPriorities, setSelectedBillingPriorities] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (insurances.length) {
      const validBillingPriorities = insurances
        .map((policy) => policy.billingPriority)
        .filter((priority): priority is number => priority !== undefined);

      setSelectedBillingPriorities(validBillingPriorities);
    }
  }, [insurances, setSelectedBillingPriorities]);

  const options = Array.from({ length: insurances?.length ?? 0 }, (_, i) => ({
    text: (i + 1).toString(),
    value: i + 1,
  })).filter(
    (option) =>
      !selectedBillingPriorities.includes(option.value) ||
      option.value === insurances?.[index]?.billingPriority,
  );

  function updatePolicy<K extends keyof AppointmentInsuranceType>(
    fieldName: K,
    fieldValue: any,
  ) {
    const objs = insurances?.map((policy, i) => {
      if (index === i) {
        return {
          ...policy,
          [fieldName]: fieldValue,
        };
      }
      return policy;
    });

    console.log('EncounterInsurancePolicy1');
    rootCallback?.({
      event: RootCallbackEvent.UpdateSingleTransaction,
      transaction: {
        ...transaction,
        insurances: objs,
      },
    });
    // setInsurances(objs);
  }
  return (
    <>
      <Select
        name="billingPriority"
        label="Billing Priority *"
        value={insurances?.[index]?.billingPriority}
        onChange={(e) => updatePolicy('billingPriority', e)}
        options={options}
        disabled={readOnly || !allowBillingPriorityChange}
        limit={1}
        className="col-span-2"
        clearable
        errors={getBillingPriorityError(index, insuranceErrors)}
      />
      <InputMasked
        label="Remaining deductible"
        name="deductible"
        placeholder="0.00"
        numericOptions={{
          decimalScale: 2,
          fixedDecimalScale: true,
        }}
        className="col-span-2"
        value={insurances?.[index]?.deductible}
        onChange={(val) => updatePolicy('deductible', val)}
        disabled={readOnly}
      />
      <InputMasked
        label="Copay"
        name="copay"
        placeholder="0.00"
        numericOptions={{
          decimalScale: 2,
          fixedDecimalScale: true,
        }}
        className="col-span-2"
        value={insurances?.[index]?.coPay}
        onChange={(val) => updatePolicy('coPay', val)}
        disabled={readOnly}
      />
      <Input
        label="Co-insurance"
        name="coinsurance"
        placeholder="0"
        className="col-span-2"
        type="number"
        min="0"
        max="100"
        value={insurances?.[index]?.coInsurance}
        onChange={(val) => updatePolicy('coInsurance', val)}
        disabled={readOnly}
        iconRight={true}
        icon={
          <svg
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            className="mr-4"
          >
            <path
              stroke="rgb(156 163 175 / var(--tw-text-opacity))"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.0"
              d="M17.25 6.75L6.75 17.25"
            />
            <circle
              cx="16"
              cy="16"
              r="1.25"
              stroke="rgb(156 163 175 / var(--tw-text-opacity))"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <circle
              cx="8"
              cy="8"
              r="1.25"
              stroke="rgb(156 163 175 / var(--tw-text-opacity))"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </svg>
        }
      />
      <Checkbox
        value={insurances?.[index]?.updatePatientInsurance}
        onChange={(val) => updatePolicy('updatePatientInsurance', val)}
        label="Update Patient Insurance"
        disabled={readOnly}
        className="col-span-4"
      />
      {insurances?.[index]?.maxPerVisit && (
        <div className="col-span-4 flex w-full flex-row pt-2">
          <div className="text-xs font-light flex flex-row italic">
            <InformationCircleIcon className="h-4 w-4 mr-1 text-gray-400" />
            This payor has a max allowed amount of{' '}
            <Currency value={insurances?.[index]?.maxPerVisit} />
          </div>
          <TrivialTooltip text="This is the maximum amount the insurance will cover for this visit, encompassing both patient and payor contributions." />
        </div>
      )}
    </>
  );
};

export default EncounterInsuranceFormFields;
