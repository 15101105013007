import {
  Checkbox,
  Input,
  Loading,
  LoadingPage,
  RadioGroup,
  icons,
} from '@chiroup/components';
import { EPayStatus } from '@chiroup/core/enums/EPayStatus.enum';
import { classNames } from '@chiroup/core/functions/classNames';
import { FormErrors } from '@chiroup/core/types/ErrorResponse.type';
import {
  CCPaymentFields,
  EpayCustomerRecord,
  PaymentMethodType,
} from '@chiroup/core/types/PatientBillling.type';
import { PatientPayment } from '@chiroup/core/types/PatientPayment.type';
import { ValueOf } from '@chiroup/core/types/ValueOf.type';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { MeContext } from '../../../../../contexts/me.context';
import useLocalStorage, { LSType } from '../../../../../hooks/useLocalStorage';
import IconButton from '../../../../common/IconButton';
import useCreditDevices from '../../../../settings/clinic/useCreditDevices';
import { PayRequest } from './PatientPaymentModal';
import PaymentMethods from './PaymentMethods';
import WaitingForPayment from './WaitingForPayment';

type Props = {
  value: Partial<PatientPayment & CCPaymentFields>;
  onChange: (
    key: keyof PatientPayment | keyof CCPaymentFields,
  ) => (val: ValueOf<PatientPayment & CCPaymentFields>) => void;
  disabled: boolean;
  errors: FormErrors;
  isLoading: boolean;
  isSubmitting: boolean;
  waitingForTerminal: boolean;
  payment?: PatientPayment;
  selectedPaymentCard: PaymentMethodType | null;
  epayCustomer: EpayCustomerRecord | null;
  setSelectedPaymentCard: (paymentMethod: PaymentMethodType | null) => void;
  setTerminalTransaction: Dispatch<SetStateAction<PayRequest | null>>;
  onError: (message: string | null) => void;
  setWaitingForTerminal: Dispatch<SetStateAction<boolean>>;
  terminalTransaction: PayRequest | null;
  onSuccess: (closeAndFetch?: boolean, force?: boolean) => Promise<void>;
  refetchEpayCustomer: () => void;
  patchValue: (values: Partial<PatientPayment & CCPaymentFields>) => void;
};

const CreditCardDeviceSelector = ({
  value,
  onChange,
  disabled,
  errors,
  isLoading,
  isSubmitting,
  waitingForTerminal,
  payment,
  selectedPaymentCard,
  epayCustomer,
  setSelectedPaymentCard,
  setTerminalTransaction,
  onError,
  setWaitingForTerminal,
  terminalTransaction,
  onSuccess,
  refetchEpayCustomer,
  patchValue,
}: Props) => {
  const { setItem, getItem } = useLocalStorage();
  const { selectedLocationFull } = useContext(MeContext);
  const { data, isFetching, refetch } = useCreditDevices(
    value.merchantId || selectedLocationFull?.merchantAccounts?.[0]?.merchantId,
  );

  useEffect(() => {
    patchValue({
      merchantId: selectedLocationFull?.merchantAccounts?.[0]?.merchantId,
    });
    refetchEpayCustomer();
  }, [patchValue, refetchEpayCustomer, selectedLocationFull]);

  useEffect(() => {
    if (data?.length && !value) {
      const lsData = getItem(LSType.both, 'deviceKey');
      if (lsData) {
        const existsInData = data.find((d: any) => d.key === lsData);
        if (existsInData) {
          onChange('deviceKey')(lsData);
          onChange('epayPublicKey')(existsInData.publicKey);
        }
      }
      onChange('deviceKey')(data[0].key);
      onChange('epayPublicKey')(data[0].publicKey);
    }
  }, [data, value, onChange, getItem]);

  const handlePaymentCardError = useCallback(
    (err: any) => {
      err = JSON.parse(err);
      if (err.code === '0') {
        onError(null);
      } else if (err.code) {
        onError(err.message);
      }
    },
    [onError],
  );

  const isManualEntry = useMemo(() => {
    const selectedDevice = data?.find((d: any) => d.key === value.deviceKey);
    return selectedDevice?.terminal_type === 'manual' && !isFetching;
  }, [data, isFetching, value.deviceKey]);

  useEffect(() => {
    let paymentCard: any = null;
    if ((window as any).usaepay && isManualEntry && value.epayPublicKey) {
      const client = new (window as any).usaepay.Client(value.epayPublicKey);
      paymentCard = client.createPaymentCardEntry();

      const paymentCardConfig = {
        styles: `
          #payjs-cnum-wrapper {
            padding: 0 !important;
            margin-left: 0.5rem;
            border-radius: 0.375rem !important;
            border: 1px solid rgb(209 213 219 / 1) !important;
          }
          #payjs-cnum {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 1.25em;
            padding-bottom: 1rem;
            border-radius: 0.375rem !important;
          }
          #payjs-exp-wrapper {
            padding: 0 !important;
            margin-left: 0.5rem;
            border-radius: 0.375rem !important;
            border: 1px solid rgb(209 213 219 / 1) !important;
          }
          #payjs-exp {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 1.25em;
            padding-bottom: 1rem;
            border-radius: 0.375rem !important;
          }
          #payjs-cvv-wrapper {
            padding: 0 !important;
            margin-left: 0.5rem;
            border-radius: 0.375rem !important;
            border: 1px solid rgb(209 213 219 / 1) !important;
          }
          #payjs-cvv {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 1.25em;
            padding-bottom: 1rem;
            border-radius: 0.375rem !important;
          }
          #payjs-exp-wrapper {
            padding-right: 5px;
          }
          #payjs-cv2-wrapper {
            padding-right: 5px;
          }
    
          #payjs-input-icon {
            padding-left: 5px;
          }
          #payjs-input-icon:hover {
              height: 22px;
          }

          @media screen and (max-width: 400px) {
              .payjs-base {
                  font-size: 10px;
              }
          }
        `,
      };

      paymentCard.generateHTML(paymentCardConfig);
      paymentCard.addHTML('paymentCardContainer');
      paymentCard.addEventListener('error', handlePaymentCardError);
    }
  }, [
    selectedPaymentCard,
    value.type,
    value.deviceKey,
    value.epayPublicKey,
    handlePaymentCardError,
    isManualEntry,
  ]);

  const onCCTerminalMessage = useCallback(
    (data: PayRequest) => {
      setTerminalTransaction(data);
      if (data.status === EPayStatus.Success) {
        setWaitingForTerminal(false);
        onSuccess(true, true);
      } else if (data.status === EPayStatus.Error) {
        onError(data.error ?? 'An error occurred');
        setWaitingForTerminal(false);
      }
    },
    [setTerminalTransaction, setWaitingForTerminal, onSuccess, onError],
  );

  return (
    <div>
      <div className="flex space-x-7">
        <RadioGroup
          name="deviceKey"
          className="col-span-2"
          label={
            <div className="flex flex-row gap-2 items-center">
              <span>Select Merchant Account</span>
            </div>
          }
          value={value.merchantId}
          onChange={(val: string) => {
            onChange('merchantId')(+val);
            onChange('deviceKey')('');
            onChange('epayPublicKey')('');
            setItem(LSType.both, 'deviceKey', '');
          }}
          disabled={disabled || isFetching}
          options={selectedLocationFull?.merchantAccounts?.map((account) => ({
            value: account.merchantId,
            text: account.name,
          }))}
        />
        {isFetching ? (
          <div className="flex flex-row gap-2 text-gray-900 item-center pt-4">
            <Loading color="text-gray-700" size={6} />
            <div>Fetching devices...</div>
          </div>
        ) : data?.length ? (
          <div>
            <div className="flex space-x-7">
              <RadioGroup
                name="deviceKey"
                className="col-span-2"
                label={
                  <div className="flex flex-row gap-2 items-center">
                    <span>Select Device</span>
                    <ArrowPathIcon
                      className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-pointer"
                      onClick={() => refetch()}
                    />
                  </div>
                }
                value={value.deviceKey}
                onChange={(val: string) => {
                  onChange('deviceKey')(val);
                  onChange('epayPublicKey')(
                    data.find((d: any) => d.key === val).publicKey,
                  );
                  setItem(LSType.both, 'deviceKey', val);
                }}
                disabled={disabled || isFetching}
                options={data.map((device: any) => ({
                  value: device.key,
                  text: (
                    <div className="flex flex-row items-center gap-2">
                      <div>{device.name}</div>
                      <div
                        className={classNames(
                          'flex h-5 w-5 items-center justify-center text-3xl',
                          device?.status === 'connected'
                            ? 'text-primary-500'
                            : 'text-red-500',
                        )}
                      >
                        {device?.status === 'connected'
                          ? icons.link
                          : icons.linkBreak}
                      </div>
                    </div>
                  ),
                  disabled: device?.status !== 'connected',
                }))}
              />
            </div>

            {terminalTransaction?.status === EPayStatus.Timeout ? (
              <div className="text-red-500">
                The payment request has timed out. Please try again.
              </div>
            ) : (
              terminalTransaction?.status === EPayStatus.Pending &&
              value.deviceKey && (
                <WaitingForPayment
                  terminalTransaction={terminalTransaction}
                  waitingForTermial={waitingForTerminal}
                  onCCTerminalMessage={onCCTerminalMessage}
                  setWaitingForTerminal={setWaitingForTerminal}
                  setTerminalTransaction={setTerminalTransaction}
                />
              )
            )}
          </div>
        ) : (
          <div>
            <span>No devices found</span>
            <IconButton
              icon={icons.refresh}
              onClick={() => refetch()}
              disabled={isFetching}
              className="ml-2 h-6 w-6"
            />
          </div>
        )}
      </div>
      <div className="pt-6">
        {isManualEntry && (
          <div className="flex flex-col gap-4">
            {!!epayCustomer?.payment_methods?.length && (
              <PaymentMethods
                merchantId={value.merchantId}
                disabled={
                  !!(isSubmitting || waitingForTerminal || payment?.isRefunded)
                }
                selectedPaymentCard={selectedPaymentCard}
                setSelectedPaymentCard={setSelectedPaymentCard}
                passedPatientId={value?.patientId}
              />
            )}
            <div
              className={classNames(
                'border border-gray-300 bg-gray-50 p-6 rounded-md',
                !selectedPaymentCard?.key && value.epayPublicKey
                  ? 'block'
                  : 'hidden',
              )}
            >
              <h3 className="pb-2 font-medium text-md">
                New Credit or Debit Card
              </h3>
              <div id="paymentCardContainer" className="h-12">
                {/* Payment Card iframe will go here */}
              </div>
              {isLoading ? (
                <LoadingPage />
              ) : !selectedPaymentCard ? (
                <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-x-4 mt-4">
                  <Input
                    name="fname"
                    className="col-span-2"
                    label="Cardholder first name *"
                    value={value.fname}
                    onChange={onChange('fname')}
                    errors={errors?.fieldErrors?.lname}
                    disabled={
                      isSubmitting || waitingForTerminal || payment?.isRefunded
                    }
                  />
                  <Input
                    name="lname"
                    className="col-span-2"
                    label="Cardholder last name *"
                    value={value.lname}
                    onChange={onChange('lname')}
                    errors={errors?.fieldErrors?.lname}
                    disabled={
                      isSubmitting || waitingForTerminal || payment?.isRefunded
                    }
                  />
                  <Input
                    name="streetAddress"
                    className="col-span-2"
                    label="Cardholder street address *"
                    value={value.streetAddress}
                    errors={errors?.fieldErrors?.streetAddress}
                    onChange={onChange('streetAddress')}
                    disabled={
                      isSubmitting || waitingForTerminal || payment?.isRefunded
                    }
                  />
                  <Input
                    name="zip"
                    className="col-span-2"
                    label="Cardholder zip code *"
                    value={value.postalCode}
                    errors={errors?.fieldErrors?.postalCode}
                    onChange={onChange('postalCode')}
                    disabled={
                      isSubmitting || waitingForTerminal || payment?.isRefunded
                    }
                  />
                  <Checkbox
                    className="col-span-4"
                    label="Save payment method?"
                    name="saveCard"
                    onChange={onChange('saveCard')}
                    value={value.saveCard || false}
                    disabled={
                      isSubmitting || waitingForTerminal || payment?.isRefunded
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditCardDeviceSelector;
