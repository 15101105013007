import {
  Button,
  ButtonColors,
  ConfirmModal,
  Input,
  InputMasked,
  TrivialTooltip,
} from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Modal from '../../../../../components/common/Modal';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import PaymentTransactionItem from './PaymentTransactionItem';
import dayjs from 'dayjs';
import { MeContext } from '../../../../../contexts/me.context';
import { formatCurrency } from '@chiroup/core/functions/format';
import { Patient } from '@chiroup/core/types/Patient.type';
import { CCPaymentFields } from '@chiroup/core/types/PatientBillling.type';
import { PatientPayment } from '@chiroup/core/types/PatientPayment.type';
import { UserRoles } from '@chiroup/core/types/User.type';

type Props = {
  credit?: PatientPayment;
  patient: Patient;
  save: (val: Partial<PatientPayment>) => Promise<PatientPayment>;
  refetch?: () => void;
  updatePayRequest: (val: any) => any;
};

const PatientCreditModal: React.FC<Props> = ({
  credit,
  patient,
  save,
  refetch,
  updatePayRequest,
}) => {
  const { me, hasRole } = useContext(MeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { createToast } = useContext(ToastContext);

  const [isUnapplyingTransactions, setIsUnapplyingTransactions] =
    useState(false);

  const { pathname } = useLocation();
  const pcs = pathname.split('/');
  const billingKey = pcs[pcs.length - 1];
  const { value, onChange, errors, registerSubmit, isSubmitting, isDirty } =
    useForm<PatientPayment>(
      credit?.id ? credit : { paymentDate: dayjs().format('MM/DD/YYYY') },
      {
        paymentDate: {
          required: {
            message: 'Date is required.',
          },
        },
        description: {
          required: {
            message: 'Description is required.',
          },
        },
        amount: {
          required: {
            message: 'Amount is required.',
          },
          function: {
            value: (value: Partial<PatientPayment>) => {
              if (!value.amount) {
                return 'Amount is required.';
              }
              if (value.amount && value.amount < 0) {
                return 'Amount must be greater than 0.';
              }
              return false;
            },
          },
        },
      },
    );

  const unapplyTransaction = async () => {
    try {
      setIsUnapplyingTransactions(true);

      const payload = {
        ...value,
        transactionPayments: [],
      };

      const request = {
        billingKey: credit?.billingKey,
        payload,
        clinicId: me?.selectedClinic?.ID || -1,
        patientId: patient.ID,
      };

      await updatePayRequest(request);
      onSuccess();
      closeModal();
    } catch (err: any) {
      const path = location.pathname;
      const newPath = path.substring(0, path.lastIndexOf('/'));
      navigate(newPath);
    }
    setIsUnapplyingTransactions(false);
  };

  const isNew = !credit?.id;

  const submit = async (val: Partial<PatientPayment>) => {
    if (isNew) {
      const creditRequest = {
        id: credit?.id,
        billingKey: credit?.billingKey,
        amount: val.amount,
        credit: true,
        creditAmount: 0,
        description: val.description,
        patientId: patient.ID,
        paymentDate: val.paymentDate,
        transactionPayments: [],
      };

      return save(creditRequest);
    } else {
      const creditRequest = val as PatientPayment & CCPaymentFields;
      return updatePayRequest({
        billingKey,
        payload: creditRequest,
        clinicId: me?.selectedClinic?.ID || -1,
        patientId: patient.ID,
        deviceKey: '',
      });
    }
  };

  const onSuccess = () => {
    closeModal();
    refetch?.();
  };

  const onFail = () => {
    createToast({
      type: ToastTypes.Fail,
      title: 'Error',
      description: 'There was an error saving the credit.',
    });
  };

  const closeModal = () => {
    const path = location.pathname;
    const newPath = path.substring(0, path.lastIndexOf('/'));
    navigate(`${newPath}${location.search}`);
  };

  return (
    <Modal isOpen close={closeModal}>
      <form
        onSubmit={registerSubmit(submit, {
          onSuccess,
          onFail,
        })}
      >
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100 dark:bg-darkGray-600">
            <CurrencyDollarIcon className="h-6 w-6 text-gray-600 dark:text-darkGray-400" />
          </div>
          <div className="mt-3 text-center sm:mt-5 flex flex-col gap-6">
            <div className="flex flex-col">
              <h3
                className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-100"
                id="modal-headline"
              >
                {isNew ? 'Add' : 'Edit'} Credit
              </h3>
              <div className="mt-2">
                <p className="text-sm leading-5 text-gray-500 dark:text-darkGray-400">
                  Add a credit to the patient account without obtaining payment.
                  <TrivialTooltip
                    text="This is most often used when trading goods or services with a
                  patient."
                  />
                </p>
              </div>
            </div>
            <div className="flex flex-col text-left w-full">
              <div className="flex flex-col sm:flex-row sm:gap-2 w-full">
                <InputMasked
                  name="transactionDate"
                  label="Transaction date *"
                  value={value.paymentDate as string}
                  onChange={onChange('paymentDate')}
                  errors={errors.fieldErrors?.transactionDate}
                  className="w-full"
                  patternFormat="##/##/####"
                  placeholder="MM/DD/YYYY"
                />
                <InputMasked
                  label="Amount *"
                  className="w-full"
                  name="amount"
                  placeholder="0.00"
                  value={value.amount}
                  onChange={onChange('amount')}
                  errors={errors?.fieldErrors?.amount}
                  numericOptions={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                />
              </div>
              <Input
                name="description"
                label="Description *"
                value={value?.description || ''}
                onChange={onChange('description')}
                errors={errors.fieldErrors?.description}
              />
            </div>
          </div>
          {!isNew && (
            <div className="text-right text-sm text-gray-600">
              Remaining Balance: {formatCurrency(credit?.balance)}
            </div>
          )}
          {!isNew &&
            value.transactionPayments &&
            value.transactionPayments?.length > 0 && (
              <>
                <div className="mt-10 mb-6">
                  Applied to
                  <div className="inline float-end">
                    <Link
                      to={`unapply`}
                      className="text-sm font-medium leading-6 text-primary-600 hover:text-primary-500 cursor-pointer"
                    >
                      <Button
                        text="Unapply"
                        tooltip="This will unapply this credit from the applied transactions."
                        loading={isUnapplyingTransactions}
                        disabled={isUnapplyingTransactions}
                      />
                    </Link>
                  </div>
                </div>
                <div className=" bg-white rounded-md border-gray-300 border pt-1">
                  <ul className="divide-y divide-gray-100">
                    {value.transactionPayments?.map(
                      (item: any, index: number) => (
                        <PaymentTransactionItem
                          order={index}
                          item={item}
                          key={`${index}-${item.id}`}
                          //TODO: Implement these
                          handleItemSelect={() => {}}
                          updateSelectedItems={() => {}}
                          value={value}
                          isNew={false}
                        />
                      ),
                    )}
                  </ul>
                </div>
              </>
            )}
        </div>
        <div className={'mt-6 flex flex-row gap-2'}>
          <Button
            text="Close"
            onClick={closeModal}
            fullWidth
            color={ButtonColors.plain}
            className="border border-gray-300 dark:border-darkGray-600"
          />
          <Button
            text={isNew ? 'Create' : 'Save'}
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={!isDirty}
          />
        </div>
      </form>
      {hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
        <Routes>
          <Route
            path={`unapply`}
            element={
              <ConfirmModal
                isOpen={true}
                confirm={unapplyTransaction}
                close={closeModal}
                loading={isUnapplyingTransactions}
                confirmText="Unapply Credit"
                description={`Are you sure you want to unapply this credit?`}
              />
            }
          />
        </Routes>
      )}
    </Modal>
  );
};

export default PatientCreditModal;
