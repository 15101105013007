import { classNames } from '@chiroup/core/functions/classNames';
import { createNeverNullDayjs } from '@chiroup/core/functions/time';
import {
  AppointmentForUI,
  AppointmentStatuses,
} from '@chiroup/core/types/Appointment.type';
import {
  TailwindColor,
  tailwindColorsHash,
} from '@chiroup/core/types/Color.type';
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { Dayjs, isDayjs } from 'dayjs';
import React, { useContext, useMemo } from 'react';
import { AppointmentsContext } from '../../contexts/appointments.context';
import { ScheduleContext } from '../../contexts/schedule.context';

type Props = {
  patientName: string;
  treatmentName: string;
  pendingPatient?: boolean;
  start: Dayjs;
  className?: string;
  isDragging?: boolean;
  width?: string;
  height?: number;
  duration: number;
  isPending?: boolean;
  color?: TailwindColor;
  appointment: AppointmentForUI;
};

const AppointmentView: React.FC<Props> = ({
  patientName,
  treatmentName,
  start,
  className = '',
  isDragging,
  width,
  height,
  duration,
  color = 'gray',
  isPending = false,
  appointment,
}) => {
  const { zoom } = useContext(AppointmentsContext);
  const { showTreatment } = useContext(ScheduleContext);
  const colorHex = tailwindColorsHash[color];
  const isaPendingPatient = appointment?.pendingPatient;
  const isaDeniedPatient = appointment?.deniedPatient;

  const startAsDayjs = useMemo(() => {
    if (isDayjs(start)) {
      return start;
    }
    return createNeverNullDayjs({ datetime: start, timezone: appointment.tz });
  }, [appointment.tz, start]);

  const icons = useMemo(() => {
    const iconsToUse = [];
    if (isaPendingPatient) {
      iconsToUse.push(
        <ExclamationTriangleIcon
          key="pending"
          className="h-4 w-4 text-yellow-500 absolute"
          title="Pending patient."
        />,
      );
    } else if (isaDeniedPatient) {
      iconsToUse.push(
        <ExclamationTriangleIcon
          key="denied"
          className="h-4 w-4 text-red-500"
          title="Denied patient."
        />,
      );
    }

    if (appointment?.recurringAppointmentId) {
      iconsToUse.push(
        <ArrowPathIcon
          key="recurring"
          className="h-4 w-4 text-accent-500"
          title="Recurring appointment: click to edit"
        />,
      );
    }

    if (appointment.status === AppointmentStatuses.CheckedIn) {
      iconsToUse.push(
        <CheckCircleIcon key="checkedIn" className="w-4 h-4 text-green-500" />,
      );
    }

    if (appointment.status === AppointmentStatuses.NoShow) {
      iconsToUse.push(
        <svg
          key={`svg-${iconsToUse.length}-${appointment.id}`}
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-red-500"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M19.25 12.25v-3.5a2 2 0 0 0-2-2H6.75a2 2 0 0 0-2 2v8.5a2 2 0 0 0 2 2h4.5M8 4.75v3.5m8-3.5v3.5m-8.25 2.5h8.5m-.5 5 1.75 1.75m0 0 1.75 1.75M17.5 17.5l1.75-1.75M17.5 17.5l-1.75 1.75"
          />
        </svg>,
      );
    }

    return iconsToUse;
  }, [isaDeniedPatient, isaPendingPatient, appointment]);

  const fontSize = useMemo(() => {
    if (zoom === 1 || zoom === 2) {
      return 10;
    } else if (zoom === 3) {
      return 12;
    } else if (zoom === 4) {
      return 14;
    } else if (zoom === 5) {
      return 16;
    }
    return 10;
  }, [zoom]);

  return (
    <div
      className={classNames(
        className,
        `absolute inset-x-0 inset-y-0 p-1 appointment-view ${
          duration <= 15 ? 'appointment-view-small' : ''
        }`,
      )}
      style={{
        width: isDragging ? width : 'auto',
        height,
        fontSize: `${fontSize}px`,
        lineHeight: `${fontSize * 1.1}px`,
      }}
    >
      <div
        className={classNames(
          'group w-full h-full rounded-lg flex flex-col overflow-hidden relative ring-2',
          zoom < 3 ? 'px-1' : 'p-1',
          isPending
            ? `ring-${color}-300 hover:ring-${color}-500`
            : `ring-${color}-400 hover:ring-${color}-600`,
          isDragging
            ? `bg-${color}-100`
            : `bg-${color}-50 hover:bg-${color}-100`,
        )}
      >
        <div
          className="absolute left-0 top-0 w-full h-full rounded-lg"
          style={
            appointment?.status === AppointmentStatuses.Canceled
              ? {
                  background: `repeating-linear-gradient(
                    135deg,
                    ${colorHex}40,
                    ${colorHex}40 4px,
                    ${colorHex}10 4px,
                    ${colorHex}10 8px
                  )`,
                }
              : {}
          }
        />
        <div className={`order-1 flow-root text-${color}-500 mr-0 pr-0 `}>
          <span className={`float-left dark:text-${color}-500 flex flex-col`}>
            <div>
              <time>{startAsDayjs?.format('h:mm A')} - </time>
              <span className="font-semibold">{patientName}</span>
            </div>
            {showTreatment && !!treatmentName ? `${treatmentName}` : null}
          </span>
          <div className="absolute top-0.5 right-0.5 flex flex-row w-full justify-end">
            {icons}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AppointmentView);
