import React, { useContext, useMemo } from 'react';
import { AppointmentsContext } from '../../contexts/appointments.context';
import { ScheduleContext } from '../../contexts/schedule.context';

type Props = {
  minMaxTime: {
    minTime: number;
    maxTime: number;
  };
};

const ScheduleHours: React.FC<Props> = ({
  minMaxTime: { minTime, maxTime },
}) => {
  const { zoom } = useContext(AppointmentsContext);
  const { timeBlockInterval } = useContext(ScheduleContext);

  // Calculate how many blocks we need per hour
  const blocksPerHour = 60 / timeBlockInterval;
  const totalBlocks = (maxTime - minTime) * blocksPerHour;

  const gridTemplateRows = `repeat(${totalBlocks * zoom}, minmax(${
    60 / blocksPerHour
  }px, 1fr))`;

  const hourRows = useMemo(() => {
    // Format time to show hours and minutes
    const formatTime = (hour: number, minutes: number) => {
      let displayHour = hour;
      if (displayHour === 0) displayHour = 12;
      else if (displayHour > 12) displayHour -= 12;

      const period = hour >= 12 ? 'PM' : 'AM';
      return `${displayHour}:${minutes.toString().padStart(2, '0')}${period}`;
    };

    const rows = [];

    // For each hour
    for (let hour = minTime; hour < maxTime; hour++) {
      // For each block within the hour
      for (let block = 0; block < blocksPerHour; block++) {
        const minutes = block * timeBlockInterval;
        const shouldShowTime = hour !== minTime || block > 0;
        const isHourMarker = minutes === 0;

        // Add the time label
        rows.push(
          <div
            key={`${hour}-${minutes}`}
            className={`sticky left-0 z-20 -ml-1 w-16 pr-1 -mt-2.5 text-right dark:text-gray-100 ${
              isHourMarker ? 'font-bold' : 'opacity-75'
            }`}
            style={{ height: `${60 / blocksPerHour}px` }}
          >
            {shouldShowTime ? formatTime(hour, minutes) : null}
          </div>,
        );

        // Add zoom-level rows
        const zoomArr = Array.from({ length: zoom - 1 }, (_, i) => i);
        zoomArr.forEach((_, zoomIndex) => {
          rows.push(
            <div
              key={`${hour}-${minutes}-${zoomIndex}-zoom`}
              style={{ height: `${60 / blocksPerHour}px` }}
            />,
          );
        });
      }
    }

    return rows;
  }, [maxTime, minTime, zoom, timeBlockInterval, blocksPerHour]);

  return (
    <div
      className="col-start-1 row-start-1 grid w-full text-right text-xs leading-5 text-gray-400"
      style={{ gridTemplateRows }}
    >
      {hourRows}
    </div>
  );
};

export default ScheduleHours;
