import { useNavigate } from 'react-router-dom';
import Modal from '../../../../common/Modal';

import { Button, ButtonColors, MakeBrowserWait } from '@chiroup/components';
import {
  PatientTransaction,
  PatientTransactionItemType,
} from '@chiroup/core/types/PatientTransaction.type';
import { NewItemSubtypeAdjustment } from './NewItemSubtypeAdjustment';

export type DiscountModalCallbacks = {
  onClose?: () => void;
  onAdded?: (items?: PatientTransactionItemType[]) => void;
  onAddStart?: () => void;
};

type Props = {
  patientId: string;
  transaction: PatientTransaction | null;
  addPendingItems: (items: PatientTransactionItemType[]) => void;
  isAnyRestRunning?: boolean;
  isOpen?: boolean;
  isRouted?: boolean;
  callbacks?: DiscountModalCallbacks;
};

const DiscountModal = ({
  isAnyRestRunning = false,
  isOpen,
  isRouted = true,
  callbacks = {},
  patientId,
  transaction,
  addPendingItems,
}: Props) => {
  const navigate = useNavigate();
  const close = () => {
    if (!isRouted) {
      callbacks?.onClose?.();
      return;
    }
    navigate(`/consolidated/transaction/${transaction?.billingKey}`);
  };

  return (
    <Modal isOpen={!!isOpen} close={close} addClasses="h-4/5">
      <NewItemSubtypeAdjustment
        addPendingItems={addPendingItems}
        callbacks={callbacks}
        isAnyRestRunning={isAnyRestRunning}
      />
      <Button
        text="Close"
        color={ButtonColors.plainWithBorder}
        className="mt-4"
        onClick={() => {
          close();
        }}
        disabled={isAnyRestRunning}
      />
      <MakeBrowserWait isWaiting={isAnyRestRunning} />
    </Modal>
  );
};

export default DiscountModal;
