import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

export enum QueryParamReturnType {
  'String' = 0,
  'Number' = 1,
  'Boolean' = 2,
  'StringArray' = 3,
  'NumberArray' = 4,
  'BooleanArray' = 5,
}

export const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const removeQueryParams = (params: string[]) => {
    const queryParams = qs.parse(location.search);
    params.forEach((p) => {
      delete queryParams[p];
    });

    return {
      navigate: () => {
        navigate({
          search: qs.stringify(queryParams),
        });
      },
      link: `?${qs.stringify(queryParams)}`,
    };
  };

  const addQueryParams = (params: { [key: string]: string | number }) => {
    const queryParams = { ...qs.parse(location.search), ...params };
    return {
      navigate: () => {
        navigate({
          search: qs.stringify(queryParams),
        });
      },
      link: `?${qs.stringify(queryParams)}`,
    };
  };

  const getParamValue = ({
    key,
    as = QueryParamReturnType.String,
    sep = ',',
  }: {
    key: string;
    as?: QueryParamReturnType;
    sep?: string;
  }): any => {
    const queryParams = qs.parse(location.search);
    const value = queryParams[key];
    const isaArray = Array.isArray(value);
    let nonNulls: any[] = [];

    if (isaArray) {
      nonNulls = value.filter((v) => v !== null);
    }

    let parsedValue: any;

    switch (as) {
      case QueryParamReturnType.String:
        if (isaArray) {
          parsedValue = nonNulls.map((v) => String(v)).join(sep);
        } else {
          parsedValue = String(value || '');
        }
        break;
      case QueryParamReturnType.Number:
        if (isaArray) {
          parsedValue = Number(nonNulls[0]);
        } else {
          parsedValue = Number(value);
        }
        break;
      case QueryParamReturnType.Boolean:
        if (isaArray) {
          parsedValue = nonNulls[0] === 'true';
        } else {
          parsedValue = value === 'true';
        }
        break;
      case QueryParamReturnType.StringArray:
        if (isaArray) {
          parsedValue = nonNulls.map((v) => String(v));
        } else {
          parsedValue = [String(value)];
        }
        break;
      case QueryParamReturnType.NumberArray:
        if (isaArray) {
          parsedValue = nonNulls.map((v) => Number(v));
        } else {
          parsedValue = [Number(value)];
        }
        break;
      case QueryParamReturnType.BooleanArray:
        if (isaArray) {
          parsedValue = nonNulls.map((v) => v === 'true');
        } else {
          parsedValue = [value === 'true'];
        }
        break;
      default:
        parsedValue = value;
    }

    return parsedValue;
  };

  return {
    removeQueryParams,
    addQueryParams,
    getParamValue,
    queryParams: qs.parse(location.search),
  };
};
