import {
  PatientInvoiceItem,
  PatientInvoiceLineItem,
  InvoicePayor,
} from '@chiroup/core/types/PatientInvoice.type';
import { TransactionItemSubtypeEnum } from '@chiroup/core/types/PatientTransaction.type';
import InvoiceItemTableRow from '../Invoice/InvoiceItemTableRow';
import InvoiceTableHeader from '../Invoice/InvoiceTableHeader';
import PrintInvoiceLineItemTableRow from './PrintInvoiceLineItemTableRow';
import PrintInvoicePayorTableRow from './PrintInvoicePayorTableRow';

type InvoiceBreakdownProps = {
  purchaseDate: any;
  provider: string;
  items: PatientInvoiceItem[];
  lineItems: PatientInvoiceLineItem[];
  payors: Record<string, InvoicePayor>;
  providerId: string;
  license?: string | null;
  patientResponsibility: number;
};

const PrintInvoiceBreakdown: React.FC<InvoiceBreakdownProps> = ({
  purchaseDate,
  provider,
  items = [],
  lineItems = [],
  payors = [],
  license,
  patientResponsibility,
}) => {
  // const showItemAmounts = !lineItems.some(
  //   (item) => item.type === TransactionItemSubtypeEnum.Service,
  // );
  // const getItemHeaders = () => {
  //   // if (showItemAmounts) {
  //     return ['Item Purchased', 'Item Type', 'Item Units', 'Item Total'];
  //   // } else return ['Item Purchased', 'Item Type'];
  // };

  return (
    <div className="p-2 text-sm avoid-page-break ">
      {/* <h3 className="font-bold pb-2 print:hidden">Invoice Breakdown</h3> */}
      <div className="pb-3">
        <div className="flex items-center print:text-xs ">
          <label className="font-normal mr-2">Purchase Date:</label>
          <p className="hidden print:block">{purchaseDate}</p>
        </div>
        <div className="grid grid-cols-3 pt-2 print:p-0  items-center">
          <p className="font-normal text-xs   print:col-span-2">
            {`Rendering Provider: ${provider}`}
          </p>
          <p className="font-normal text-xs print:p-0 hidden print:block print:col-span-2">
            {`License Number: ${license}`}
          </p>
        </div>
      </div>
      <div className="mb-2 inline-block rounded-lg border w-full">
        <table className="min-w-full divide-y divide-gray-300">
          <InvoiceTableHeader
            headers={[
              'Items Purchased',
              'Item Type',
              'Item Units',
              'Item Total',
            ]}
          />
          <tbody>
            {items?.map((item, index) => (
              <InvoiceItemTableRow key={index} item={item} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="mb-2 inline-block rounded-lg border w-full">
        {!!lineItems.length && lineItems.some((item) => !item.hidden) && (
          <table className="min-w-full divide-y divide-gray-300">
            <InvoiceTableHeader
              headers={['Line Item', 'Detail', 'Amount']}
              textAlign={{ Amount: 'text-right' }}
            />
            <tbody>
              {lineItems.map((lineItem, index) => (
                <PrintInvoiceLineItemTableRow
                  item={lineItem}
                  key={lineItem.transactionItemId}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
      {!!Object.keys(payors).length &&
        Object.values(payors).some((payor) => !payor.hidden) && (
          <div className="inline-block rounded-lg border w-full mb-2">
            <table className="min-w-full divide-y divide-gray-300">
              <InvoiceTableHeader
                headers={['Payor Coverage', 'Detail', 'Responsibility']}
                textAlign={{ Responsibility: 'text-right' }}
              />
              <tbody>
                {Object.entries(payors)
                  ?.sort((a, b) => a[1].billingPriority - b[1].billingPriority)
                  ?.map(([key, value], index) => {
                    return (
                      <PrintInvoicePayorTableRow
                        item={value}
                        key={key}
                        index={index}
                        lastIndex={Object.entries(payors).length - 1}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
    </div>
  );
};

export default PrintInvoiceBreakdown;
