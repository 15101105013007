import {
  AddCodingDetails,
  Button,
  ButtonColors,
  Loading,
} from '@chiroup/components';
import { PayorListType } from '@chiroup/core/constants/DatabaseFeatureCommon';
import { STRING_BOOLEAN_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpTransactionCommon } from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { classNames } from '@chiroup/core/functions/classNames';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { CodeSets } from '@chiroup/core/types/BillingCode.type';
import { ClinicCaseType } from '@chiroup/core/types/ClinicCaseType.type';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import {
  isaServiceItem,
  notaServiceItem,
  PatientTransaction,
  PatientTransactionItemDiagnosisType,
  PatientTransactionItemType,
  TransactionAppendItemsType,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
  TransactionTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { VisitPlanType } from '@chiroup/core/types/PatientVisit.type';
import { ReferenceCodeDisplay } from '@chiroup/core/types/ReferenceCode.type';
import React, { useCallback, useContext, useMemo } from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import { databaseService } from '../../../../../settings/database/database.service';
import {
  InstanceComponents,
  RootCallbackEvent,
  RootCallbackProps,
} from '../../../billing/transactions/ConsolidatedTransactionModal';
import { FindThePackageCreditArgs } from '../../../billing/transactions/SingleTransaction';
import EncounterInsurance from '../insurances/EncounterInsurance';
import { InsuranceError } from './CodeEncounterDiagnosisServices';
import CodeEncounterServices from './CodeEncounterServices';
import SaltTransaction from './SaltTransaction';
import businessRulesService from '@chiroup/core/services/businessRules.service';
import { MagicToastTitle } from '@chiroup/core/services/businessRules.service/commonMagic';
import {
  TransactionMagicEvent,
  TransactionMagiceResponseType,
} from '@chiroup/core/services/businessRules.service/transactionMagic';
import { ToastTypes } from '../../../../../../contexts/toast.context';

const isaAppointment = (parentIsa?: string) => {
  return parentIsa === 'appointment';
};

const isaVisit = (parentIsa?: string) => {
  return (
    parentIsa === 'visit' ||
    parentIsa === 'encounter' ||
    parentIsa === 'transaction'
  );
};

const parentLowerCase = (parentIsa?: string, plural?: boolean) => {
  if (parentIsa === 'slot') {
    return 'time slot' + (plural ? 's' : '');
  } else if (isaAppointment(parentIsa)) {
    return 'appointment' + (plural ? 's' : '');
  } else if (isaVisit(parentIsa)) {
    return 'encounter' + (plural ? 's' : '');
  }
  return 'Ooops!';
};

type Props = {
  omitTitle: boolean;
  // listofServices: PatientTransactionItemType[];
  isFetchingService: boolean;
  readonly?: boolean;
  isBillingStarted?: boolean;
  locked: boolean;
  parentIsa?: string;
  unlockButtonText: string;
  lockButtonText: string;
  saveButtonText: string;
  loadFromPlanAndAssessment: (
    e: any,
    afterLoad?: (newTransaction: any) => Promise<void>,
  ) => Promise<{
    services: PatientTransactionItemType[];
    insurances: Partial<AppointmentInsuranceType[]>;
  }>;
  // lockButtonClicked: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  // saveButtonClicked: ({
  //   e,
  //   disableToast,
  //   services,
  //   insurancesToUse,
  // }: {
  //   e: React.MouseEvent<HTMLButtonElement>;
  //   disableToast?: boolean | undefined;
  //   services?: PatientTransactionItemType[] | null | undefined;
  //   insurancesToUse?: AppointmentInsuranceType[] | null | undefined;
  // }) => Promise<void>;
  saveCallback?: (args: TransactionAppendItemsType) => void;
  patientId: string;
  insuranceErrors: InsuranceError[];
  services: PatientTransactionItemType[];
  plan?: VisitPlanType;
  isRestActive: {
    loadPlan: boolean;
    lock: boolean;
    save: boolean;
  };
  setIsFetchingService: React.Dispatch<React.SetStateAction<boolean>>;
  disciplineId?: number;
  policies?: Insurance[] | any[] | undefined | null;
  handlePolicyChange: (e: any) => void;
  patientTransaction?: PatientTransaction | null | undefined;
  instanceComponents?: InstanceComponents | null;
  allowBillingPriorityChange: boolean;
  courtesyBilling?: boolean;
  superBill?: boolean;
  handleCourtesyBillingChange: (e: boolean) => void;
  handleSuperBillChange?: (e: boolean) => void;
  providerId?: string;
  handleBillingProfileChange: (e: number | undefined | null) => void;
  billingProfileId?: number;
  caseType?: number | null;
  caseTypes?: Partial<ClinicCaseType>[];
  importButtonText?: string;
  importOnlyMode?: boolean;
  afterImport?: (newTransaction: any) => Promise<void>;
  // FYI: I could not define createToast this far down, a react artificat of some kind.
  // However, passing it in works. Because, this component needed another parameter.
  createToast?: (args: any) => void;
  hasPackage?: boolean;
  findThePackageCredit: (params: FindThePackageCreditArgs) => any;
  transactionLineItemComponent?: React.ReactNode;
  diagnosticCodeComponent?: React.ReactNode;
  // setConsolidatedData?: React.Dispatch<React.SetStateAction<any>>;
  noCodesMessage?: string;
  horizontal?: boolean;
  primarySubHeadClassNames?: string;
  rootCallback?: (args: RootCallbackProps) => void;
  insurances: Partial<AppointmentInsuranceType>[];
  editMode?: boolean;
};

const CodeEncounterDiagnosisServicesWithDiagnosis: React.FC<Props> = ({
  // listofServices,
  isFetchingService,
  horizontal,
  readonly = false,
  isBillingStarted = false,
  locked,
  parentIsa,
  loadFromPlanAndAssessment,
  // saveButtonClicked,
  saveCallback,
  patientId,
  insuranceErrors,
  services,
  plan,
  isRestActive,
  setIsFetchingService,
  disciplineId,
  policies = [],
  handlePolicyChange,
  patientTransaction,
  instanceComponents = null,
  allowBillingPriorityChange = false,
  courtesyBilling = false,
  superBill = false,
  handleCourtesyBillingChange,
  handleSuperBillChange,
  handleBillingProfileChange,
  billingProfileId,
  caseType,
  caseTypes,
  importButtonText = 'Import from wizard',
  importOnlyMode = false,
  afterImport,
  createToast = () => {},
  findThePackageCredit,
  transactionLineItemComponent = null,
  diagnosticCodeComponent = null,
  rootCallback,
  noCodesMessage = `No services are associated with this ${parentLowerCase(
    parentIsa,
  )}.`,
  primarySubHeadClassNames = [
    'mb-2.5 relative block text-lg',
    isBillingStarted ? 'text-accent-600' : 'text-primary-600',
    'font-medium leading-5 sm:mt-px sm:pt-2 mt-4',
  ].join(' '),
  insurances,
  providerId,
  editMode = false,
}) => {
  const { me } = useContext(MeContext);

  const listofServices = useMemo(() => {
    // console.log({ useMemoForListofServices: patientTransaction });
    return patientTransaction?.items?.filter(isaServiceItem) ?? [];
  }, [patientTransaction]);

  const updateTransactionWhenNeeded = useCallback(
    async (services: PatientTransactionItemType[], doMagic?: boolean) => {
      const clone = ChiroUpJSON.clone(patientTransaction) as PatientTransaction;
      const itemsWithoutServices =
        clone?.items?.filter((item) => !isaServiceItem(item)) || [];
      clone.services = services;
      clone.items = [...itemsWithoutServices, ...services];

      let magicResponse: TransactionMagiceResponseType | null = null;
      let newTransaction: PatientTransaction | null = null;
      if (doMagic) {
        magicResponse = (await businessRulesService.transactionMagic({
          transaction: clone,
          previous: clone,
          event: TransactionMagicEvent.onChangeService,
          options: {
            findThePackageCredit,
            // trace: true,
          },
        })) as TransactionMagiceResponseType;
        if (magicResponse?.actions?.length) {
          // This was running afoul of the renderer as it was so deep.
          // Probably need a top-level <MagicContext> to handle this.
          // hmmm.... [BWM]
          setTimeout(() => {
            createToast({
              title: MagicToastTitle,
              description: (
                <div>
                  {magicResponse?.actions.map((action, idx) =>
                    action.message ? <p key={idx}>{action.message}</p> : null,
                  )}
                </div>
              ),
              type: ToastTypes.Info,
              duration: 5000,
            });
          }, 0);
          // setListofServices(magicResponse.transaction.services as any);
        }
      }
      newTransaction = magicResponse?.transaction ?? clone;

      /**
       * We're probably not doing this the way we should. This sets
       * the query-client cache and a useEffect on SingleTransaction
       * will load its local row from the data.
       */
      if (newTransaction) {
        rootCallback?.({
          event: RootCallbackEvent.UpdateSingleTransaction,
          transaction: {
            ...clone,
            services: clone?.items?.filter(isaServiceItem) ?? [],
          },
          options: {
            findThePackageCredit,
          },
        });
      }
    },
    [findThePackageCredit, patientTransaction, rootCallback, createToast],
  );

  const trivialTooltip = useMemo(() => {
    return {
      text: isBillingStarted ? ChiroUpTransactionCommon.billingStarted : '',
      id: 'main-tooltip',
    };
  }, [isBillingStarted]);

  const buttonColor = useMemo(
    () => (isBillingStarted ? ButtonColors.accent : ButtonColors.primary),
    [isBillingStarted],
  );

  /**
   * Please put all the logic for updating one service here. Resist the urge
   * to add additional handlers for each property.
   *
   * @param index
   * @param val
   * @param prop
   * @returns
   */
  const onChangeServices = async (
    index: number,
    val: PatientTransactionItemType | null,
    prop?: string,
  ) => {
    if (readonly) return;
    // console.log(
    //   'CodeEncounterDiagnosisServicesWithDiagnosis.onChangeServices',
    //   { index, val, prop },
    // );
    let magicResponse: TransactionMagiceResponseType | null = null;
    if (prop === 'shiftModifiers') {
      const clone = ChiroUpJSON.clone(patientTransaction) as PatientTransaction;
      if (!clone) return;
      const services = clone.items?.filter(isaServiceItem) ?? [];
      const service = services[index];
      if (!service) return;
      const vals = [
        service?.modifier2 ?? undefined,
        service?.modifier3 ?? undefined,
        service?.modifier4 ?? undefined,
        undefined,
      ];
      service.modifier1 = vals[0];
      service.modifier2 = vals[1];
      service.modifier3 = vals[2];
      service.modifier4 = vals[3];
      clone.items = [
        ...(clone.items?.filter(notaServiceItem) ?? []),
        ...services,
      ];
      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: clone,
      });
    } else if (val && prop === 'blurAmount') {
      const clone = ChiroUpJSON.clone(patientTransaction) as PatientTransaction;
      if (!clone) return;
      magicResponse = (await businessRulesService.transactionMagic({
        transaction: clone,
        event: TransactionMagicEvent.onBlurServiceAmount,
        options: {
          // trace: true,
        },
      })) as TransactionMagiceResponseType;
      if (magicResponse?.actions?.length) {
        createToast({
          title: MagicToastTitle,
          description: (
            <div>
              {magicResponse?.actions.map((action, idx) =>
                action.message ? <p key={idx}>{action.message}</p> : null,
              )}
            </div>
          ),
          type: ToastTypes.Info,
          duration: 5000,
        });
        if (magicResponse?.touched) {
          rootCallback?.({
            event: RootCallbackEvent.UpdateSingleTransaction,
            transaction: magicResponse.transaction ?? clone,
          });
          // const insurances = (magicResponse?.transaction?.insurances ??
          //   []) as unknown as Partial<AppointmentInsuranceType>[];
          // setInsurances(insurances);
        }
      }

      return;
    }
    if (prop?.indexOf('blur') === 0) {
      const clone = ChiroUpJSON.clone(patientTransaction) as PatientTransaction;
      if (!clone) return;
      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: clone,
      });
      return;
    }

    const newServices = [...(listofServices || [])];
    if (val) {
      newServices[index] = val;
    } else {
      newServices.splice(index, 1);
    }
    /**
     * [2025-01-02.1213 by Brian per Emily] Keep line items with the
     * same code synchronized on one transaction. [Yes, this will
     * update the same item twice, sometimes just can't be bothered.]
     */
    const code = newServices?.[index]?.code;
    if (code) {
      if (prop === 'amount') {
        for (const ns of newServices) {
          if (ns.code === code) {
            ns.amount = val?.amount ?? ns.amount;
          }
        }
      } else if (prop === 'insuranceBillable') {
        for (const ns of newServices) {
          if (ns.code === code) {
            ns.insuranceBillable =
              val?.insuranceBillable ?? ns.insuranceBillable;
            ns.subtype =
              ns?.subtype === TransactionItemSubtypeEnum.Service
                ? TransactionItemSubtypeEnum.PatientService
                : TransactionItemSubtypeEnum.Service;
          }
        }
      }
    }
    // setListofServices(newServices);
    updateTransactionWhenNeeded(newServices, true);
  };

  const onPromote = useCallback(
    async (e: ReferenceCodeDisplay, getRidOfService?: string) => {
      if (isFetchingService) return;
      let newInsurances = [...(insurances || [])];

      setIsFetchingService(true);

      /**
       * Fire off the REST calls in parallel.
       */
      const proms: Promise<any>[] = [];
      let code: PayorListType = {};
      // let ins: Insurance[] = [];
      let newPayors: {
        payorID: any;
        allowedAmount: number;
        code: string;
        replacementCode: any;
      }[] = [];

      try {
        proms.push(
          databaseService
            .getCode({
              clinicId: me?.selectedClinic?.ID,
              code: e.code,
              payors: true,
            })
            .then((r: any) => {
              code = r.data as PayorListType;
              newPayors =
                code.payors?.map((payor: any) => ({
                  payorID: payor.payorIntPk,
                  allowedAmount: +payor.allowedAmount,
                  code: e.code,
                  replacementCode: payor.replacementCode,
                })) || [];
              // setPayors((prevPayors) => {
              //   const newPayors =
              //     code.payors?.map((payor: any) => ({
              //       payorID: payor.payorIntPk,
              //       allowedAmount: +payor.allowedAmount,
              //       code: e.code,
              //       replacementCode: payor.replacementCode,
              //     })) || [];

              //   return [...(prevPayors || []), ...(newPayors || [])];
              // });
              newInsurances = (insurances || []).map(
                (insurance: Partial<AppointmentInsuranceType>) => {
                  const matchedPayor = code.payors?.find(
                    (payor: any) => payor.payorIntPk === insurance.payorID,
                  );
                  // console.log({ matchedPayor });
                  const allowedAmount = Number(
                    matchedPayor?.allowedAmount || code?.billedAmount || 0,
                  );
                  return {
                    ...insurance,
                    serviceAllowedAmounts: {
                      ...insurance.serviceAllowedAmounts,
                      [e.code]: {
                        allowedAmount,
                        payorID: insurance.payorID as number,
                      },
                    },
                  };
                },
              );

              rootCallback?.({
                event: RootCallbackEvent.UpdateSingleTransaction,
                transaction: {
                  ...patientTransaction,
                  payors: [
                    ...(patientTransaction?.payors ?? []),
                    ...newPayors,
                  ] as any,
                  insurances: newInsurances,
                },
                options: {
                  runMagic: true,
                },
              });
              // setInsurances(objs);
              return r;
            }),
        );
      } catch (e) {
        console.error({ e });
      }
      await Promise.all(proms);

      /**
       * [2024-10-14.0855 by Brian per Emily in 7519482792]
       *   Logic: Sort the insurances by billing priority. Then, for each
       *   insurance, add the modifiers in the order they appear. If no
       *   insurance-specific modifiers exist, then the defaults are used.
       *   Modifiers are only added once in the order they are found.
       */
      const sortedInsurances = ChiroUpJSON.clone(
          patientTransaction?.insurances ?? [],
        ).sort((a: any, b: any) => a.billingPriority - b.billingPriority),
        usedModifiders: STRING_BOOLEAN_HASH = {},
        orderedModifiers: string[] = [];

      /**
       * Business rule: Add the modifiers to the service in the order they
       * appear in the crazy payor-specific modifiers. At the end, if there
       * are no ordered modifiers at this point, then add the default modifiers.
       */
      while (sortedInsurances.length) {
        const ins = sortedInsurances.shift() as AppointmentInsuranceType,
          billingCode = code?.payors?.find(
            (p: any) => p.payorIntPk === ins.payorID,
          ) as any,
          hasPayorSpecificModifiers =
            !!billingCode?.modifier1 ||
            !!billingCode?.modifier2 ||
            !!billingCode?.modifier3 ||
            !!billingCode?.modifier4;

        if (hasPayorSpecificModifiers) {
          ['modifier1', 'modifier2', 'modifier3', 'modifier4'].forEach((s) => {
            if (billingCode[s] && !usedModifiders[billingCode[s]]) {
              orderedModifiers.push(billingCode[s]);
              usedModifiders[billingCode[s]] = true;
            }
          });
        }
      }

      if (!orderedModifiers.length) {
        if (code.modifier1) orderedModifiers.push(code.modifier1);
        if (code.modifier2) orderedModifiers.push(code.modifier2);
        if (code.modifier3) orderedModifiers.push(code.modifier3);
        if (code.modifier4) orderedModifiers.push(code.modifier4);
      }

      if (code) {
        const nobj = ChiroUpJSON.clone(code);

        nobj.diagnoses = [] as PatientTransactionItemDiagnosisType[];
        nobj.units = 1;
        nobj.type = TransactionItemTypeEnum.Debit;
        nobj.subtype = TransactionItemSubtypeEnum.Service;
        nobj.amount = code.billedAmount ? +code.billedAmount : 0;
        nobj.variableBilledAmount = code.variableBilledAmount;
        nobj.modifier1 = orderedModifiers[0] ?? null;
        nobj.modifier2 = orderedModifiers[1] ?? null;
        nobj.modifier3 = orderedModifiers[2] ?? null;
        nobj.modifier4 = orderedModifiers[3] ?? null;
        nobj.insuranceBillable = true;

        // TODO [BWM]: Move to transactionMagic.
        const servicesToUse = ChiroUpJSON.clone(listofServices);
        const codeToFind = nobj.code;
        const matchingService =
          servicesToUse?.find(
            (service: PatientTransactionItemType) =>
              service.code === codeToFind,
          ) || null;
        if (matchingService) {
          nobj.insuranceBillable = matchingService.insuranceBillable;
          nobj.amount = matchingService.amount;
        }
        const newServices =
          patientTransaction?.items?.filter(isaServiceItem) ?? [];
        if (getRidOfService) {
          const serviceIndex = newServices.findIndex(
            (service) => service.code === getRidOfService,
          );
          if (serviceIndex > -1) {
            newServices[serviceIndex] = nobj;
          }
        } else {
          newServices.push(nobj);
        }

        rootCallback?.({
          event: RootCallbackEvent.UpdateSingleTransaction,
          transaction: {
            ...patientTransaction,
            items: [
              ...(patientTransaction?.items?.filter(notaServiceItem) ?? []),
              ...newServices,
            ],
            payors: [
              ...(patientTransaction?.payors ?? []),
              ...newPayors,
            ] as any,
            services: newServices,
            insurances: newInsurances,
          },
          options: {
            findThePackageCredit,
            runMagic: true,
          },
        });
      }

      setIsFetchingService(false);
    },
    [
      isFetchingService,
      insurances,
      setIsFetchingService,
      patientTransaction,
      me?.selectedClinic?.ID,
      listofServices,
      rootCallback,
      findThePackageCredit,
    ],
  );

  const onRemoveInsurance = useCallback(
    (policy: Partial<AppointmentInsuranceType>) => {
      const next = ChiroUpJSON.clone(insurances).filter(
        (p: Partial<AppointmentInsuranceType>) => {
          return p.insuranceID !== policy.insuranceID;
        },
      );

      rootCallback?.({
        event: RootCallbackEvent.UpdateSingleTransaction,
        transaction: {
          ...patientTransaction,
          insurances: next,
        },
        options: {
          runMagic: true,
        },
      });
      // setInsurances(next);
    },
    [insurances, patientTransaction, rootCallback],
  );

  const activePolicies = useMemo(() => {
    return (policies || [])
      ?.filter((policy: Insurance) => policy.active && !policy.pending)
      ?.filter(
        (ap: Insurance) =>
          !(insurances || []).some((policy) => ap.id === policy.insuranceID),
      )
      ?.map((policy: Insurance) => ({
        text: policy?.name || '',
        value: policy,
        disabled: !policy.billable && isBillingStarted,
        color: policy.billable ? '' : 'text-red-500',
      }));
  }, [policies, insurances, isBillingStarted]);

  const insuranceComponent = useMemo(() => {
    return (
      <>
        <EncounterInsurance
          activePolicies={activePolicies}
          allowBillingPriorityChange={allowBillingPriorityChange}
          billingKey={patientTransaction?.billingKey as string}
          billingProfileId={billingProfileId}
          courtesyBilling={courtesyBilling}
          disciplineId={disciplineId}
          handleBillingProfileChange={handleBillingProfileChange}
          handleCourtesyBillingChange={handleCourtesyBillingChange}
          handlePolicyChange={handlePolicyChange}
          handleSuperBillChange={handleSuperBillChange}
          horizontal={horizontal}
          insuranceErrors={insuranceErrors}
          isBillingStarted={isBillingStarted}
          onRemoveInsurance={onRemoveInsurance}
          parentIsa={parentIsa}
          patientId={patientId}
          policies={policies}
          readOnly={readonly}
          rootCallback={rootCallback}
          services={(services || []).filter(
            (item) => item.subtype === TransactionItemSubtypeEnum.Service,
          )}
          superBill={superBill}
          transaction={patientTransaction}
        />
        <InsuranceErrors errors={insuranceErrors} />{' '}
      </>
    );
  }, [
    activePolicies,
    allowBillingPriorityChange,
    patientTransaction,
    billingProfileId,
    courtesyBilling,
    disciplineId,
    handleBillingProfileChange,
    handleCourtesyBillingChange,
    handlePolicyChange,
    handleSuperBillChange,
    horizontal,
    insuranceErrors,
    isBillingStarted,
    onRemoveInsurance,
    parentIsa,
    patientId,
    policies,
    readonly,
    rootCallback,
    services,
    superBill,
  ]);

  if (importOnlyMode) {
    return (
      <div>
        {plan && Object.keys(plan).length > 0 && (
          <div className="flex flex-row space-x-4">
            <Button
              text={importButtonText}
              loading={isRestActive.loadPlan || isRestActive.save}
              className="print:hidden"
              disabled={locked || isFetchingService || isRestActive.loadPlan}
              onClick={async (e) => {
                await loadFromPlanAndAssessment(e, afterImport);
              }}
              color={buttonColor}
              trivialTooltip={trivialTooltip}
            />
          </div>
        )}
        {/* <pre>{ChiroUpJSON.pretty(plan)}</pre> */}
      </div>
    );
  }

  /**
   * Use case: Do not show insurnace or services on non-provider segments
   * but we _do_ need to see ConsolidatedTransactionLedger which is passed
   * in. [Oh what a tangled web...]
   */
  if (
    (!providerId || providerId === '-1') &&
    patientTransaction?.type === TransactionTypeEnum.AdHoc
  ) {
    return <div>{transactionLineItemComponent}</div>;
  }

  return (
    <div className={classNames('w-full', 'flex flex-col')}>
      {isFetchingService && (!listofServices || listofServices.length === 0) ? (
        <div className="flex justify-center m-4">
          <Loading color="text-gray-400" />
        </div>
      ) : null}
      {horizontal ? (
        <div
          className={classNames(
            'w-full flex flex-row justitify-between space-x-6 mt-4',
          )}
        >
          <div className="flex w-full">{diagnosticCodeComponent}</div>
          <div className="flex w-full flex-col">
            <div className={primarySubHeadClassNames}>
              Services
              {instanceComponents?.lockButton}
            </div>
            <CodeEncounterServices
              services={listofServices}
              readonly={readonly || locked}
              isBillingStarted={isBillingStarted}
              onChangeValue={onChangeServices}
              onPromote={onPromote}
              insurances={insurances}
              payors={patientTransaction?.payors}
            />
            <AddCodingDetails
              label="Services"
              value={listofServices}
              codeSet={[CodeSets.CPT, CodeSets.SERVICE]}
              noCodesMessage={noCodesMessage}
              noneText="Type to search and add service codes..."
              onPromote={onPromote}
              isPromoting={isFetchingService}
              promoteTitle={`Add this service to the ${parentLowerCase(
                parentIsa,
              )}.`}
              // repeatTitle={`This service is already associated with the ${parentLowerCase(
              //   parentIsa,
              // )}.`}
              autoCompleteContainerClassName="mr-0.5 flex flex-row gap-4"
              clinicId={me.selectedClinic?.ID as number}
              isBillingStarted={isBillingStarted}
              autoCompleteClassName="pb-4"
            />
          </div>
          <div className="flex w-full">{insuranceComponent}</div>
        </div>
      ) : null}
      {!horizontal && transactionLineItemComponent}
      {!horizontal && caseTypes?.find((c) => c.id === caseType)?.name !== 'Cash'
        ? horizontal
          ? null
          : insuranceComponent
        : null}

      {!horizontal ? (
        <div
          data-bwm="cedswd"
          className={classNames(horizontal ? 'w-1/3' : 'w-full p-0 mt-6')}
        >
          {!listofServices.length ? null : (
            <div className={primarySubHeadClassNames}>
              Services and Diagnoses
            </div>
          )}

          {diagnosticCodeComponent}
          <CodeEncounterServices
            services={listofServices}
            readonly={readonly || locked}
            isBillingStarted={isBillingStarted}
            onChangeValue={onChangeServices}
            onPromote={onPromote}
            insurances={insurances}
            payors={patientTransaction?.payors}
          />
          {!readonly && !locked && (
            <div className="flex flex-col space-y-4">
              <SaltTransaction
                target={
                  {
                    ...patientTransaction,
                    insurances,
                  } as PatientTransaction
                }
                parentIsa={parentIsa}
                callback={(txn: PatientTransaction) => {
                  rootCallback?.({
                    event: RootCallbackEvent.UpdateSingleTransaction,
                    transaction: {
                      ...txn,
                      services: txn.items?.filter(isaServiceItem) ?? [],
                    },
                  });
                  // setListofServices(
                  //   (txn.items || []).filter(
                  //     (i) =>
                  //       i.subtype === TransactionItemSubtypeEnum.Service ||
                  //       i.subtype === TransactionItemSubtypeEnum.PatientService,
                  //   ),
                  // );
                  // setInsurances(txn.insurances || []);
                  // setPayors(txn.payors || []);
                  // if (typeof saveCallback === 'function') {
                  //   saveCallback({ newTransaction: txn });
                  // }
                }}
              />

              <AddCodingDetails
                label="Services"
                value={listofServices}
                codeSet={[CodeSets.CPT, CodeSets.SERVICE]}
                noCodesMessage={noCodesMessage}
                noneText="Type to search and add service codes..."
                onPromote={onPromote}
                isPromoting={isFetchingService}
                promoteTitle={`Add this service to the ${parentLowerCase(
                  parentIsa,
                )}.`}
                autoCompleteContainerClassName="mr-0.5 flex flex-row gap-4"
                clinicId={me.selectedClinic?.ID as number}
                isBillingStarted={isBillingStarted}
                autoCompleteClassName="p-0"
              />
            </div>
          )}
        </div>
      ) : null}
      <div
        className="flex flex-row justify-end space-x-4"
        data-id="bwm-lock-button"
      >
        {!readonly && (
          <>
            {plan && Object.keys(plan).length > 0 && (
              <Button
                text={importButtonText}
                loading={isRestActive.loadPlan}
                className="mt-5 print:hidden"
                disabled={locked || isFetchingService || isRestActive.loadPlan}
                onClick={async (e) => {
                  await loadFromPlanAndAssessment(e, afterImport);
                }}
                color={buttonColor}
                trivialTooltip={trivialTooltip}
              />
            )}
            {/* dmy */}
          </>
        )}
      </div>
    </div>
  );
};

type InsuranceErrorsProps = {
  errors?: InsuranceError[];
  className?: string;
};

export const InsuranceErrors: React.FC<InsuranceErrorsProps> = ({
  errors,
  className = '',
}) => {
  if (!errors || errors.length === 0) return null;

  return (
    <div className="border border-red-600 px-4 py-2 rounded-md">
      {errors.map((error, index) => (
        <div
          key={`cedswd-errors-${index}`}
          className={['my-2 text-sm text-red-600', className].join(' ')}
        >
          {error.message}
        </div>
      ))}
    </div>
  );
};

export default CodeEncounterDiagnosisServicesWithDiagnosis;
