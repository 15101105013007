import { CommunicationDataTypes } from '../enums/CommunicationDataTypes.enum';
import { CommunicationMethodTypes } from '../enums/CommunicationMethodTypes.enum';
import { ModalityHistory, Visit } from './PatientVisit.type';
import { PCPReport } from './PCPReport';
import { Vital } from './Vital.type';

export type CommunicationSent = {
  method: CommunicationMethodTypes;
  to: string | number;
  sentAt: number;
};

export type CommunicationDataDatetime = {
  id: string;
  name: string;
  type: CommunicationDataTypes.datetime;
  value: number;
};

export type CommunicationData = CommunicationDataDatetime;

export enum CommunicationIconColors {
  plain = 'plain',
  info = 'info',
  success = 'success',
  warn = 'warn',
  error = 'error',
}

export enum CommunicationIcons {
  email = 'email',
  textMessage = 'textMessage',
  clipboardList = 'clipboardList',
}

export type CommunicationIcon = {
  icon: CommunicationIcons;
  color: CommunicationIconColors;
  tooltip: string;
};

export type InfoRequests = {
  uploadPhotoId: boolean | number;
  uploadInsuranceCard: boolean | number;
  basicInformation: boolean | number;
  insurance: boolean | number;
};

export type Communication = {
  id: string;
  title: string;
  subtitle?: string;
  createdAt: number;
  updatedAt: number;
  recipientId?: string;
  patientId?: string;
  type: CommTypes | EHRActivityTypes;
  typeId?: string;
  userId?: string;
  clinicId: number;
  patientIdType?: string;
  disabled?: boolean;
  sent?: CommunicationSent[];
  infoRequests?: InfoRequests;
  data?: CommunicationData[] | Partial<PCPCommunicationData>;
  icons?: CommunicationIcon[];
  manualEntry?: boolean;
  appointmentGroupId?: number;
  sendInfo?: {
    error: boolean;
    status?: string;
    bounceDetail?: {
      bounceType?: string;
      bounceSubType?: string;
    };
  };
  reportInfo?: {
    thirtyDaySurvey?: {
      color: string;
      tooltip: string;
    };
    initialReport?: {
      date: string;
    };
    releaseReport?: {
      createDate: string;
      healthcareID: number;
      releaseDate: string;
      perImprove: number;
      treatments: number;
      summary: string;
    };
  };
  surveys?: any[];
  consents?: any[];
  completedAt?: boolean | number;
  guardian?: boolean;
  expired?: boolean;
  encounterId?: string;
  notAvailable?: boolean;
  encounterDate?: number;
  visit?: Visit;
  ehrOnly?: boolean;
  ehrActivityType?: string;
  painLevel?: number;
  appointmentId?: string;
  manualEntryId?: number;
};
export type PCPCommunicationData = {
  id?: string;
  isReferred?: boolean;
  healthCareProvider?: { ID: string; name: string };
  conditions: { ID: string; name: string }[];
  goalImprove?: number;
  perImprove?: number;
  proposedAdditionalVisits?: number;
  visits?: {
    duration: number;
    durationUnit: string;
    times: number;
    timesUnit: string;
  }[];
  treatments?: string;
  summary: string;
  releaseDate?: string;
};

export enum CommTypes {
  ReleaseReport = 'releaseReport',
  InitialReport = 'initialReport',
  InterimReport = 'interimReport',
  RequestReview = 'requestReview',
  PatientRequest = 'patientRequest',
  ConditionReport = 'conditionReport',
  Report = 'report',
  ExercisePlan = 'exercisePlan',
  PainLevelSurvey = 'painLevelSurvey',
  Intake = 'intake',
}

export type EHRActivity = {
  id: string;
  patientId: string;
  clinicId: number;
  visitId?: string;
  createdAt: number;
  type: string;
  content:
    | EHRNoteSignedActivity
    | EHRModalitiesCompleteActivity
    | EHRVitalsCompleteActivity
    | EHRTestOrderedActivity
    | EHROfficeExercisesCompleteActivity
    | EHRHomeExercisesCompleteActivity
    | EHRManualEntryActivity;
};

export type EHRModalitiesCompleteActivity = {
  completedAt: number;
  history: ModalityHistory[];
};

export type EHRNoteSignedActivity = {
  encounterDate: number;
  complaints: string[];
};

export type EHRVitalsCompleteActivity = {
  encounterDate: number;
  vitals: Partial<Vital>;
};

export type EHROfficeExercisesCompleteActivity = {
  completedAt: number;
  exerciseHistoryId: number;
};

export type EHRTestOrderedActivity = {
  testName: string;
  orderDate: number;
  testId: number;
};

export type EHRHomeExercisesCompleteActivity = {
  completedAt: number;
  painLevel: number;
  exerciseNames: string;
  key: string;
};

export type EHRManualEntryActivity = {
  id: string;
  name: string;
  note: string;
  date: number | string;
  files?: string[];
  createdAt: number;
  updatedAt?: number;
  createdBy: string;
  patientId: string;
  clinicId: number;
  encounterId?: string;
  communicationId?: string;
  encounterDate?: number;
  type: ManualEntryTypes;
  data?: PCPReport;
};

export enum ManualEntryTypes {
  Note = 'note',
  InterimReport = 'interimReport',
  InitialReport = 'initialReport',
  ReleaseReport = 'releaseReport',
}

export const enum EHRActivityTypes {
  NoteSigned = 'noteSigned',
  ModalitiesComplete = 'modalitiesComplete',
  VitalsComplete = 'vitalsComplete',
  TestOrdered = 'testOrdered',
  OfficeExercisesComplete = 'officeExercisesComplete',
  HomeExercisesComplete = 'homeExercisesComplete',
  ManualEntry = 'manualEntry',
  ComplaintDeleted = 'complaintDeleted',
}
