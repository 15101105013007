import { PatientPayment } from '@chiroup/core/types/PatientPayment.type';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { PatientContext } from '../../../../../contexts/patient.context';
import usePatientBillingBalances from '../../../hooks/usePatientBillingBalances';
import { PaymentFilters } from '../hooks/usePayments';
import PatientCreditModal from './PatientCreditModal';
import PatientPaymentEditModal from './PatientPaymentEditModal';
import PatientPaymentModal from './PatientPaymentModal';
import usePatientPayment from './usePatientPayment';

type Props = {
  patientId?: string;
  refetch?: () => void;
  setIsFetchingPayment?: (isFetching: string | null) => void;
  credits?: boolean;
  filters?: PaymentFilters;
  passedTransactions?: PatientTransaction[];
  isRouted?: boolean;
  isOpen?: boolean;
  onClosed?: () => void;
  onSuccess?: (payment: any) => void;
  disableCreateCredit?: boolean;
};

const Payment: React.FC<Props> = ({
  patientId,
  refetch,
  setIsFetchingPayment,
  credits = false,
  filters,
  passedTransactions,
  isRouted = true,
  isOpen = false,
  onClosed,
  onSuccess,
  disableCreateCredit = false,
}) => {
  const { patientId: patientIdParam, paymentId } = useParams() as {
    patientId: string;
    paymentId: string;
  };
  const patientIdToUse = patientId || patientIdParam;
  const { patient } = useContext(PatientContext);
  const { data: balances } = usePatientBillingBalances(patientIdToUse);
  const {
    data,
    save,
    selectedPaymentCard,
    setSelectedPaymentCard,
    clear,
    updatePayRequest,
  } = usePatientPayment({
    patientId: patientIdToUse,
    paymentId,
    setIsFetchingPayment,
  });

  const savePayment = async (val: Partial<PatientPayment>) => {
    const res: PatientPayment = await save(val);
    onSuccess?.(res);
    return res;
  };

  if (!data || !patient) {
    return null;
  }

  if (credits) {
    return (
      <PatientCreditModal
        credit={data}
        patient={patient}
        save={savePayment}
        refetch={refetch}
        updatePayRequest={updatePayRequest}
      />
    );
  } else {
    if (!data.id) {
      return (
        <PatientPaymentModal
          payment={data}
          balances={balances}
          save={savePayment}
          refetch={refetch}
          patient={{ ...patient, ID: patientIdToUse || patient.ID }}
          selectedPaymentCard={selectedPaymentCard}
          setSelectedPaymentCard={setSelectedPaymentCard}
          credits={credits}
          clear={clear}
          passedTransactions={passedTransactions}
          isRouted={isRouted}
          isOpen={isOpen}
          onClosed={onClosed}
          disableCreateCredit={disableCreateCredit}
        />
      );
    }
    return (
      <PatientPaymentEditModal
        payment={data}
        balances={balances}
        refetch={refetch}
        patient={patient}
        credits={credits}
        clear={clear}
        filters={filters}
        updatePayRequest={updatePayRequest}
      />
    );
  }
};

export default Payment;
