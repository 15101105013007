/**
 * DatabaseTable.tsx
 *
 * This is a data-driven component that is used _LOTS_ of places. Please do
 * not add single-use code here. This is meant to be completely configurable
 * by the schema it is passed. If you're working with one of the standard
 * database schemas defined in InstanceSchema, you can apply any overrides
 * that need to return JSX where FrontendInstanceSchema is defined below.
 *
 * Please search the codebase for other examples of using this component before
 * making changes to this file, the needed feature may already be possible.
 */
import { Loading } from '@chiroup/components';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../common/table/Pagination';
import SectionContainer from '../../layout/SectionContainer';
import NoRowsFound from './NoRowsFound';
import {
  InstanceSchema,
  InstanceSchemaType,
} from '@chiroup/core/types/DatabaseFeatureTypes';
import { classNames } from '@chiroup/core/functions/classNames';

export type DatabaseTableOptions = {
  includeHeader?: boolean;
  sectionClassName?: string;
  outerClassName?: string;
  evenClassName?: string;
  oddClassName?: string;
  defaultThClassName?: string;
  defaultTdClassName?: string;
  noRowsFoundComponent?: React.ReactNode;
};

type Props = {
  instanceTitle: string;
  rows: any[] | null;
  schema: InstanceSchemaType;
  page?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  count: number;
  limit?: number;
  isFetching?: boolean;
  onRowClick?: (row: any, index: number) => void;
  cellClassName?: string;
  clickable?: boolean;
  columnMetadata?: { [key: string]: { content: React.ReactNode } };
  parentClassName?: string | undefined;
  onClickLink?: string;
  options?: DatabaseTableOptions;
};

const DatabaseTable: React.FC<Props> = ({
  instanceTitle,
  rows,
  schema,
  page,
  setPage = () => {},
  count,
  limit,
  isFetching = false,
  onRowClick,
  cellClassName = 'text-sm font-medium text-gray-900',
  clickable = true,
  columnMetadata = {},
  parentClassName = undefined,
  onClickLink,
  options = {},
}) => {
  options = {
    includeHeader: true,
    sectionClassName:
      'sm:shadow border border-gray-300 dark:border-darkGray-800 rounded-md overflow-hidden',
    outerClassName: 'overflow-x-auto rounded-lg print:rounded-none relative',
    evenClassName: 'bg-white dark:bg-darkGray-700',
    oddClassName: 'bg-gray-50 dark:bg-darkGray-600',
    defaultThClassName:
      'px-6 py-3 text-left text-base font-medium text-gray-500 dark:text-darkGray-50 tracking-wider print:text-gray-800 whitespace-nowrap',
    defaultTdClassName: 'px-6 py-4 align-top',
    noRowsFoundComponent: (
      <NoRowsFound
        name={instanceTitle}
        create={() => navigate(`${pathname}?id=add`)}
      />
    ),
    ...options,
  };

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const defaultOnClick = (row: any, index: number) => {
    navigate(`${onClickLink || pathname}?id=${row.ID || row.id || index}`);
  };

  const columns = useMemo(() => {
    return (
      Object.keys(schema)?.filter(
        (key) =>
          schema[key].showOnList !== false && schema[key].hidden !== true,
      ) || []
    );
  }, [schema]);

  if (!rows) return null;
  return (
    <SectionContainer
      parentClassName={parentClassName}
      className={options.sectionClassName}
    >
      {columns?.length ? (
        !rows?.length ? (
          options?.noRowsFoundComponent
        ) : (
          <div className={options?.outerClassName}>
            <div
              className={classNames(
                'absolute z-0 left-0 top-0 w-full h-full bg-white dark:bg-darkGray-700 flex justify-center items-center transition-opacity ease-in-out duration-500',
                isFetching
                  ? 'opacity-90 cursor-wait'
                  : 'opacity-0 pointer-events-none',
              )}
            >
              <Loading color="text-gray-500" />
            </div>
            <table className="min-w-full divide-y divide-gray-300 dark:divide-darkGray-600">
              {options.includeHeader ? (
                <thead className="bg-gray-50 dark:bg-darkGray-600">
                  <tr key="thead-key">
                    {columns.map((key, keyIndex) => (
                      <th
                        scope="col"
                        key={`th-${key}-${keyIndex}`}
                        className={classNames(
                          options?.defaultThClassName,
                          schema[key]?.thClassName || '',
                        )}
                      >
                        {columnMetadata[key]?.content ? (
                          <div className="whitespace-normal">
                            {columnMetadata[key].content}
                          </div>
                        ) : (
                          schema[key].label
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
              ) : null}
              <tbody>
                {rows.map((row, i) => (
                  <tr
                    key={`data-row-${i}`}
                    onClick={() => {
                      if (!clickable) return;
                      onRowClick ? onRowClick(row, i) : defaultOnClick(row, i);
                    }}
                    className={classNames(
                      `border-b-2  dark:bg-darkGray-800 border-transparent ${
                        clickable
                          ? 'cursor-pointer hover:bg-gray-100 dark:hover:bg-darkGray-700'
                          : ''
                      }`,
                      i % 2 === 0
                        ? options?.evenClassName
                        : options?.oddClassName,
                    )}
                  >
                    {columns.map((key, keyIndex) => (
                      <td
                        className={classNames(
                          options?.defaultTdClassName,
                          schema[key]?.tdClassName || '',
                        )}
                        key={`${i}.${keyIndex}`}
                      >
                        <div
                          className={classNames(
                            cellClassName,
                            ' dark:text-darkGray-50',
                            schema[key]?.tdDivClassName || '',
                          )}
                        >
                          {schema[key] && schema[key].td
                            ? (
                                schema[key].td ||
                                function (row: any, ord: string) {
                                  return row[ord] as string;
                                }
                              )(row, key)
                            : row[key]}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      ) : null}
      {!!limit && (
        <div className="overflow-x-auto">
          <Pagination
            setPage={setPage}
            currentPage={page || 1}
            count={count}
            limit={limit}
          />
        </div>
      )}
    </SectionContainer>
  );
};

/**
 * This is where to drop any well-known schema overrides for the UI. Mostly,
 * this supports adding custom JSX to the table cells like the DatabaseTable
 * component supports for other non-standard schemas. The InstanceSchema data
 * structure is for front-end or backend, but the _td_ functions support JSX
 * as well which is no bueno in .ts files.
 *
 * Note this will REPLACE anything in the InstanceSchema.
 */
export const FrontendInstanceSchema = InstanceSchema;
FrontendInstanceSchema.payors.legalName.td = (row: any, ord?: string) => {
  ord = ord || 'legalName';
  return (
    <p className="text-sm">
      <span className="block">{row[ord]}</span>
      <div className="flex mt-2 space-x-2">
        {row.inNetwork && (
          <span className="rounded-lg bg-primary-500 py-1 px-2 text-[10px] leading-[10px] font-light text-white whitespace-nowrap">
            In Network
          </span>
        )}
        {!row.electronicBilling && (
          <span className="rounded-lg bg-yellow-300 py-1 px-2 text-[10px] leading-[10px] font-light text-gray-600 whitespace-nowrap">
            Paper Billing
          </span>
        )}
        {!row.billable && (
          <span className="rounded-lg bg-red-500 py-1 px-2 text-[10px] leading-[10px] font-light text-white whitespace-nowrap">
            Non-billable
          </span>
        )}
      </div>
    </p>
  );
};
FrontendInstanceSchema.payors.address1.td = (row: any, ord?: string) => {
  ord = ord || 'address1';
  return (
    <div className="flex flex-col">
      {row.address1 ? <div>{row.address1}</div> : null}
      {row.address2 ? <div>{row.address2}</div> : null}
      {row.city ? <div>{row.city}</div> : null}
      {row.state ? <div>{row.state}</div> : null}
      {row.zip ? <div>{row.zip}</div> : null}
    </div>
  );
};

export default DatabaseTable;
