import { Button, ButtonColors } from '@chiroup/components';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import { classNames } from '@chiroup/core/functions/classNames';
import { DisciplineTreatment } from '@chiroup/core/types/Discipline.type';
import { Room } from '@chiroup/core/types/Room.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { Menu, Transition } from '@headlessui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DateType } from 'react-tailwindcss-datepicker/dist/types';
import { MeContext } from '../../contexts/me.context';
import { ScheduleContext } from '../../contexts/schedule.context';
import useLocalStorage, { LSType } from '../../hooks/useLocalStorage';
import { useQueryParams } from '../../hooks/useQueryParams';
import DateRangePicker from '../common/DateRangePicker';

const now = dayjs();
const startOfLastWeek = now
  .subtract(1, 'week')
  .startOf('week')
  .format('YYYY-MM-DD');
const endOfLastWeek = now
  .subtract(1, 'week')
  .endOf('week')
  .format('YYYY-MM-DD');
const startOfThisWeek = now.startOf('week').format('YYYY-MM-DD');
const endOfThisWeek = now.endOf('week').format('YYYY-MM-DD');
const startOfNextWeek = now.add(1, 'week').startOf('week').format('YYYY-MM-DD');
const endOfNextWeek = now.add(1, 'week').endOf('week').format('YYYY-MM-DD');

const shortcuts = {
  lastWeek: {
    text: 'Last week',
    period: {
      start: startOfLastWeek,
      end: endOfLastWeek,
    },
  },
  yesterday: 'Yesterday',
  todayBecauseNormalTodayIsNotAlwaysRight: {
    text: 'Today',
    period: {
      start: dayjs().format('YYYY-MM-DD'),
      end: dayjs().format('YYYY-MM-DD'),
    },
  },
  thisWeek: {
    text: 'This week',
    period: {
      start: startOfThisWeek,
      end: endOfThisWeek,
    },
  },
  nextWeek: {
    text: 'Next week',
    period: {
      start: startOfNextWeek,
      end: endOfNextWeek,
    },
  },
};

type Props = {
  start: string;
  end: string;
  selectTreatment: (treatment: DisciplineTreatment | null) => Promise<void>;
  selectedTreatment: DisciplineTreatment | null;
  loadingAvailableSlots: boolean;
  selectedUsers: {
    [key: string]: boolean;
  };
  selectedRooms: {
    [key: string]: boolean;
  };
  setSelectedUsers: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  setSelectedRooms: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  nonArchivedRooms?: Room[];
  isFetching: boolean;
  changeDateCallback: ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => void;
};

const ScheduleHeader: React.FC<Props> = ({
  start,
  end,
  selectTreatment,
  selectedTreatment,
  loadingAvailableSlots,
  selectedUsers,
  setSelectedUsers,
  setSelectedRooms,
  nonArchivedRooms,
  isFetching,
  selectedRooms,
  changeDateCallback,
}) => {
  const { setItem, getItem } = useLocalStorage();
  const { me, hasRole, hasAccess, selectedLocationFull } =
    useContext(MeContext);
  const { disciplines } = useContext(ScheduleContext);
  const navigate = useNavigate();
  const { addQueryParams } = useQueryParams();
  const { queryParams } = useQueryParams();

  useEffect(() => {
    const previousDates = getItem(LSType.both, 'previousScheduleDateRange');
    const currentDates = getItem(LSType.both, 'currentScheduleDateRange');
    if (!previousDates || !currentDates) {
      setItem(LSType.both, 'previousScheduleDateRange', {
        startDate: start,
        endDate: end,
      });
      setItem(LSType.both, 'currentScheduleDateRange', {
        startDate: start,
        endDate: end,
      });
    } else if (
      currentDates &&
      currentDates.startDate !== start &&
      currentDates.endDate !== end
    ) {
      setItem(LSType.both, 'previousScheduleDateRange', {
        startDate: currentDates.startDate,
        endDate: currentDates.endDate,
      });
      setItem(LSType.both, 'currentScheduleDateRange', {
        startDate: start,
        endDate: end,
      });
    }
  }, [end, start, setItem, getItem]);

  const handleValueChange = (
    newValue: {
      startDate: DateType;
      endDate: DateType;
    } | null,
  ) => {
    if (!newValue) return;
    const startDate = dayjs(newValue.startDate).format('YYYY-MM-DD');
    const endDate = dayjs(newValue.endDate).format('YYYY-MM-DD');

    addQueryParams({
      startDate,
      endDate,
    }).navigate();

    changeDateCallback({ startDate, endDate });
  };

  const next = () => {
    const startDate = dayjs(start)
      .add(daysBetween + 1, 'day')
      .format('YYYY-MM-DD');
    const endDate = dayjs(end)
      .add(daysBetween + 1, 'day')
      .format('YYYY-MM-DD');

    addQueryParams({
      startDate,
      endDate,
    }).navigate();

    changeDateCallback({ startDate, endDate });
  };

  const previous = () => {
    const startDate = dayjs(start)
      .subtract(daysBetween + 1, 'day')
      .format('YYYY-MM-DD');
    const endDate = dayjs(end)
      .subtract(daysBetween + 1, 'day')
      .format('YYYY-MM-DD');

    addQueryParams({
      startDate,
      endDate,
    }).navigate();

    changeDateCallback({ startDate, endDate });
  };

  // const showPrevious = () => {
  //   const previousDates = getItem(LSType.both, 'previousScheduleDateRange');
  //   const currentDates = getItem(LSType.both, 'currentScheduleDateRange');
  //   return (
  //     previousDates &&
  //     currentDates &&
  //     previousDates.startDate !== currentDates.startDate &&
  //     previousDates.endDate !== currentDates.endDate
  //   );
  // };

  const treatmentOptions = useMemo(() => {
    if (!disciplines) {
      return [];
    }
    return disciplines
      ?.reduce((arr: DisciplineTreatment[], discipline) => {
        if (discipline.treatments?.length) {
          arr.push(...discipline.treatments);
        }
        return arr;
      }, [])
      ?.map((treatment) => ({
        text: treatment.name,
        onClick: () => {
          selectTreatment(treatment);
        },
      }));
  }, [disciplines, selectTreatment]);

  const daysBetween = dayjs(end).diff(dayjs(start), 'day');

  const userInitials = (name: string) => {
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  };

  const toggleUser = (userId: string) => {
    const selectedUsersToSave = { ...selectedUsers };
    selectedUsersToSave[userId] = !selectedUsersToSave[userId];
    setItem(LSType.both, 'scheduleSelectedUsers', {
      selectedScheduleUsers: selectedUsersToSave,
    });
    setSelectedUsers((prev) => {
      const newSelectedUsers = { ...prev };
      newSelectedUsers[userId] = !newSelectedUsers[userId];
      return newSelectedUsers;
    });
  };

  const toggleRoom = (roomId: number) => {
    const selectedRoomsToSave = { ...selectedRooms };
    selectedRoomsToSave[roomId] = !selectedRoomsToSave[roomId];
    setItem(LSType.both, 'scheduleSelectedRooms', {
      selectedScheduleRooms: selectedRoomsToSave,
    });
    setSelectedRooms((prev) => {
      const newSelectedRooms = { ...prev };
      newSelectedRooms[roomId] = !newSelectedRooms[roomId];
      return newSelectedRooms;
    });
  };

  const hasSelectedUsers = useMemo(() => {
    return Object.values(selectedUsers || {}).includes(true);
  }, [selectedUsers]);

  return (
    <header className="flex flex-none items-center justify-between border-b border-gray-300 dark:border-gray-500 px-6 py-4">
      <div>
        <div className="flex items-center text-sm font-medium leading-5 capitalize pb-1 -mt-4">
          <svg
            className="flex-shrink-0 mr-1.5 h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
              clipRule="evenodd"
            />
          </svg>
          {selectedLocationFull?.name || selectedLocationFull?.primaryName}
        </div>
        <div className="relative flex items-center rounded-md bg-white dark:bg-darkGray-800 shadow-sm md:items-stretch">
          <div
            className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
            aria-hidden="true"
          />
          <div
            onClick={previous}
            className="cursor-pointer flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:hover:bg-darkGray-500 dark:bg-darkGray-600"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon
              className="h-5 w-5 dark:text-gray-100"
              aria-hidden="true"
            />
          </div>
          <div className="text-sm font-semibold text-gray-900 focus:relative cursor-default flex items-center border-r border-l border-gray-300 dark:border-gray-500 px-2 hover:bg-gray-50 dark:hover:bg-darkGray-500 dark:bg-darkGray-600 dark:hover-bg-darkGray-500 dark:text-gray-100">
            <DateRangePicker
              start={start}
              end={end}
              onChange={handleValueChange}
              shortcuts={shortcuts}
            />
          </div>
          <span className="relative -mx-px h-5 w-px bg-gray-300 dark:bg-darkGray-500 md:hidden" />
          <div
            onClick={next}
            className="cursor-pointer flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:hover:bg-darkGray-500 dark:bg-darkGray-600"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon
              className="h-5 w-5 dark:text-gray-100"
              aria-hidden="true"
            />
          </div>
        </div>
        <div
          onClick={() => {
            handleValueChange({
              startDate: dayjs().format('YYYY-MM-DD'),
              endDate: dayjs().format('YYYY-MM-DD'),
            });
          }}
          className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs text-center -mb-4"
        >
          Today
        </div>
        {/* {showPrevious() && (
          <div
            onClick={() => goBack()}
            className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs text-center -mb-4"
          >
            Previous date range
          </div>
        )} */}
      </div>{' '}
      {!isFetching && (
        <div>
          <div className="flex flex-row items-center">
            <div className="-space-x-2 overflow-hidden hidden md:flex p-4">
              {nonArchivedRooms?.map((room) => {
                const color = room?.color || 'primary';
                return (
                  <div className="relative" key={room.id}>
                    <div
                      className={classNames(
                        `ring-${color}-500 flex-none bg-gray-300 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer`,
                      )}
                      onClick={() => toggleRoom(room?.id)}
                    >
                      <div
                        className={`${
                          selectedRooms?.[room.id]
                            ? `bg-${color}-400`
                            : `bg-gray-500`
                        } h-8 w-8 text-white rounded-full items-center flex justify-center text-xs`}
                      >
                        {room.name?.slice(0, 4)}
                      </div>
                    </div>

                    {/* {hasSelectedUsers && !selectedUsers[clinician.ID] && (
                  <div className="bg-gray-900/50 w-full h-full rounded-full absolute left-0 top-0 pointer-events-none" />
                )} */}
                  </div>
                );
              })}
            </div>

            <div className="-space-x-2 overflow-hidden hidden md:flex p-4">
              {me.selectedClinic?.clinicians?.map((clinician) => {
                const profileImage = clinician.profileImage?.includes(
                  'https://',
                )
                  ? clinician.profileImage
                  : `${import.meta.env.VITE_S3_ASSETS_URL}/${
                      clinician.profileImage
                    }`;
                return (
                  <div className="relative" key={clinician.ID}>
                    {clinician.profileImage ? (
                      <img
                        className={classNames(
                          'inline-block h-8 w-8 rounded-full ring-2 ring-gray-50 cursor-pointer',
                        )}
                        src={profileImage}
                        alt={clinician.name}
                        onClick={toggleUser.bind(null, clinician.ID)}
                      />
                    ) : (
                      <div
                        className={classNames(
                          'flex-none bg-gray-300 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer',
                        )}
                        onClick={toggleUser.bind(null, clinician.ID)}
                      >
                        <div className="h-8 w-8 text-white rounded-full items-center flex justify-center text-xs">
                          {userInitials(
                            `${clinician.fname} ${clinician.lname}`,
                          )}
                        </div>
                      </div>
                    )}
                    {hasSelectedUsers && !selectedUsers[clinician.ID] && (
                      <div className="bg-gray-900/50 w-full h-full rounded-full absolute left-0 top-0 pointer-events-none" />
                    )}
                  </div>
                );
              })}
            </div>
            {hasRole([
              UserRoles.Admin,
              UserRoles.Provider,
              UserRoles.Staff,
              UserRoles.ClinicalAssistant,
            ]) && (
              <>
                <div className="hidden md:flex md:items-center">
                  {selectedTreatment ? (
                    <Button
                      text={`Scheduling ${selectedTreatment.name}`}
                      color={ButtonColors.plainWithBorder}
                      icon={<XMarkIcon className="h-6 w-6" />}
                      loading={loadingAvailableSlots}
                      onClick={() => {
                        selectTreatment(null);
                      }}
                    />
                  ) : queryParams?.open !== 'add' ? (
                    <Button
                      color={ButtonColors.primary}
                      text="New Appointment"
                      onClick={() => {
                        navigate(addQueryParams({ open: 'add' }).link);
                      }}
                      extraOptions={treatmentOptions}
                    />
                  ) : null}
                </div>
                <Menu as="div" className="relative md:hidden">
                  <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Open menu</span>
                    <EllipsisHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-30 mt-3 w-48 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={addQueryParams({ open: 'add' }).link}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm',
                              )}
                            >
                              New Appointment
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default ScheduleHeader;
