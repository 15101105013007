import { useCallback, useContext, useState } from 'react';
import {
  QueryFunctionContext,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import { MeContext } from '../contexts/me.context';
import patientService from '../services/patient.service';
import { NoteLocation } from '@chiroup/core/enums/NoteTypes.enum';
import { PatientNote } from '@chiroup/core/types/PatientNote.type';

const getPatientNotesQuery = (
  patientId: string,
  clinicId: number,
  limit: number,
  encounter = false,
) => {
  return async (context: QueryFunctionContext) => {
    const searchTerm = context.queryKey[2] as {
      category: string | null;
      type: string | string[] | null;
      locations: NoteLocation[];
    };

    const search = searchTerm || {
      category: null,
      type: null,
      locations: null,
    };

    return patientService.patientNotesList(
      { skip: context.pageParam, search, limit },
      encounter,
      patientId,
      clinicId,
    );
  };
};

const usePatientNotes = (
  patientId: string,
  limit = 25,
  encounter = false,
  type?: string | string[],
  locations?: NoteLocation[],
) => {
  const queryClient = useQueryClient();
  const { me } = useContext(MeContext);
  const [searchQuery, setSearchQuery] = useState<{
    category: string | null;
    type: string | string[] | null;
    locations?: NoteLocation[] | null;
  }>({
    category: null,
    type: type || null,
    locations: locations ? locations : null,
  });

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery<{ data: PatientNote[]; skip: number }>(
    ['patientNotes', patientId, searchQuery],
    getPatientNotesQuery(
      patientId,
      me.selectedClinic?.ID ?? -1,
      limit,
      encounter,
    ),
    {
      staleTime: 15 * 1000,
      enabled: !!patientId && patientId !== '-1',
      getNextPageParam: (lastGroup) => lastGroup?.skip || undefined,
      refetchOnWindowFocus: false,
    },
  );

  const updatePatientNotesCache = async (note: PatientNote) => {
    if (data) {
      const updatedData = {
        pages: [
          {
            data: [...data.pages[0].data, note],
            skip: data.pages[0].skip,
          },
          ...data.pages.slice(1),
        ],
      };

      queryClient.setQueryData(
        ['patientNotes', patientId, searchQuery],
        updatedData,
      );
    }
  };

  const onSearch = useCallback(
    (params: {
      category: string | null;
      type: string | string[] | null;
      locations?: NoteLocation[] | null;
    }) => {
      setSearchQuery(params);
    },
    [],
  );

  return {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    onSearch,
    updatePatientNotesCache,
  };
};

export default usePatientNotes;
