import { InvoiceUseEnum } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { Invoice } from '@chiroup/core/types/Invoice.type';
import { PatientInvoice } from '@chiroup/core/types/PatientInvoice.type';
import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../../../contexts/toast.context';
import patientBillingService from '../../../../../../../services/patientBilling.service';
import { useConsolidatedTransaction } from '../../../hooks/useConsolidatedTransaction';

const BASE_QUERY_KEY = ['invoice'];
const getQuery = function (
  clinicId: number,
  cache: Invoice | undefined | null,
) {
  return async (context: QueryFunctionContext) => {
    const invoiceId = context.queryKey[1] as number,
      use = context.queryKey[2] as InvoiceUseEnum;
    if (invoiceId < 0) return Promise.resolve(cache ?? undefined);
    return patientBillingService.getInvoice({
      clinicId,
      invoiceId,
      use,
    });
  };
};

const useInvoice = ({
  invoiceId,
  invoice,
  use,
}: {
  invoiceId: number;
  use?: InvoiceUseEnum;
  invoice?: Invoice | undefined | null;
}) => {
  // One query key to bind them.
  const QUERY_KEY = [...BASE_QUERY_KEY, invoiceId, use];
  const { setTransactionManually } = useConsolidatedTransaction({
    enabled: false,
  });
  const { createToast } = useContext(ToastContext);
  const [isSaving, setIsSaving] = useState(false);
  const { me } = useContext(MeContext);
  const queryClient = useQueryClient();
  const { status, data, error, isFetching, refetch } = useQuery<Invoice>(
    QUERY_KEY,
    getQuery(me?.selectedClinic?.ID || -1, invoice),
    {
      refetchOnWindowFocus: false,
      enabled: invoiceId !== -1,
    },
  );

  const save = async (
    invoiceData: PatientInvoice | undefined,
    patientId: string,
    dispatched?: boolean,
  ) => {
    if (!invoiceData) {
      return;
    }
    setIsSaving(true);
    try {
      const res = await patientBillingService.updatePatientInvoice({
        invoiceData,
        invoiceId,
        patientId,
        clinicId: me?.selectedClinic?.ID || -1,
        dispatched,
      });
      queryClient.setQueryData<Invoice>(QUERY_KEY, res);

      const invoiceBreakdownItemsByItemId = (
        res?.invoiceData?.invoiceBreakdown?.lineItems || []
      ).reduce((obj: any, item: any) => {
        obj[item.transactionItemId] = {
          detail: item.detail,
          description: item.description,
          hidden: item.hidden,
        };
        return obj;
      }, {});
      queryClient.setQueryData(['transaction', res.billingKey], (prev: any) => {
        const newData = ChiroUpJSON.clone(prev);
        newData.items = newData.items?.map((item: any) => {
          if (invoiceBreakdownItemsByItemId[item.id]) {
            const parsedDisplayData = JSON.parse(item.displayData || '{}');
            parsedDisplayData.invoiceData =
              invoiceBreakdownItemsByItemId[item.id];
            item.displayData = JSON.stringify(parsedDisplayData);
          }
          return item;
        });
        return newData;
      });
      queryClient.invalidateQueries(['invoices']);
      setIsSaving(false);
      return res;
    } catch (e) {
      setIsSaving(false);
    }
  };

  const onFail = () => {
    createToast({
      type: ToastTypes.Fail,
      title: 'Error',
      description: 'There was an error writing off this invoice.',
    });
  };

  const writeOff = async (value: any) => {
    try {
      setIsSaving(true);
      const useThis = data ?? invoice;
      if (useThis?.payorId === null && useThis?.insuranceId === null) {
        const res = await patientBillingService.writeOffPatientInvoice({
          invoiceId: value.invoiceId || -1,
          clinicId: me.selectedClinic?.ID || -1,
          amount: value.amount || 0,
        });

        queryClient.setQueryData(QUERY_KEY, res);
        if (res?._sidecar?.transaction) {
          setTransactionManually(res._sidecar.transaction);
        }

        queryClient.invalidateQueries({
          queryKey: ['patientsBillingBalances', res.patientId],
          exact: true,
        });

        queryClient.invalidateQueries({
          queryKey: ['transactions', res.patientId],
          exact: false,
        });
        return true;
      } else {
        createToast({
          type: ToastTypes.Fail,
          title: 'Error',
          description: 'Write-off of payor invoices is not supported.',
        });
        return false;
      }
    } catch (e) {
      console.error(e);
      onFail();
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  return {
    status,
    data: invoice ?? data,
    error,
    isFetching,
    refetch,
    save,
    isSaving,
    writeOff,
  };
};

export default useInvoice;
