import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import { atAutoPopulateModifier } from './autoPopulateMedicareModifier';
import { MagicAction, MagicActionType } from './commonMagic';
import { transactionOnBlurServiceAmount } from './transactionOnBlurServiceAmount';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { fnTransactionZeroTreatment } from './fnTransactionZeroTreatment';
import { fnTransactionApplyPackage } from './fnTransactionApplyPackage';
import { fnTransactionPayorModifiers } from './fnTransactionPayorModifiers';

export enum TransactionMagicEvent {
  onChangeService = 'onChangeService',
  onChangeInsurance = 'onChangeInsurance',
  onBlurServiceAmount = 'onBlurServiceAmount',
  onFrontendChange = 'onFrontendChange',
}

export type TransactionMagiceResponseType = {
  transaction: PatientTransaction;
  actions: MagicActionType[];
  touched: boolean;
};

const transactionSpells = {
  onChangeService: [
    atAutoPopulateModifier,
    fnTransactionZeroTreatment,
    fnTransactionApplyPackage,
  ],
  onChangeInsurance: [atAutoPopulateModifier, fnTransactionApplyPackage],
  onBlurServiceAmount: [transactionOnBlurServiceAmount],
  onFrontendChange: [
    fnTransactionPayorModifiers,
    atAutoPopulateModifier,
    fnTransactionZeroTreatment,
    fnTransactionApplyPackage,
  ],
};

export const transactionMagic = async ({
  transaction,
  previous,
  event,
  onEntry = null,
  onExit = null,
  options = {},
  trace = false,
}: {
  transaction: PatientTransaction | undefined | null;
  previous?: PatientTransaction | undefined | null;
  event: TransactionMagicEvent;
  onEntry?: ((transaction: PatientTransaction) => void) | null;
  onExit?: ((transaction: PatientTransaction) => void) | null;
  options?: any;
  trace?: boolean;
}) => {
  if (!transaction) {
    return {
      actions: [
        {
          message: `No actions possible without a transaction.`,
          type: MagicAction.Error,
        },
      ],
      transaction: null,
    };
  }
  const actions: MagicActionType[] = [],
    magicResponses: {
      [key: string]: TransactionMagiceResponseType[];
    } = {};

  onEntry?.(transaction);
  if (trace) {
    console.log({ transaction, event });
  }

  let current = ChiroUpJSON.clone(transaction);

  if (transactionSpells[event]) {
    magicResponses[event] = magicResponses[event] || [];
    const original = ChiroUpJSON.clone(previous);
    let prev = ChiroUpJSON.clone(previous);
    for (const magic of transactionSpells[event]) {
      try {
        const res: any = magic(options, {
          original,
          current,
          previous: previous as PatientTransaction,
        });
        if (res instanceof Promise) {
          await res
            .then((resolvedRes: TransactionMagiceResponseType) => {
              magicResponses[event].push(resolvedRes);
              current = resolvedRes.transaction;
              previous = prev;
              prev = ChiroUpJSON.clone(resolvedRes.transaction);
            })
            .catch((e: any) => {
              console.error(e);
              actions.push({
                message: `Error: ${e.message}`,
                type: MagicAction.Error,
              });
            });
        } else {
          const mr: TransactionMagiceResponseType = res;
          magicResponses[event].push(mr);
          current = mr.transaction;
          previous = prev;
          prev = ChiroUpJSON.clone(mr.transaction);
        }
      } catch (e: any) {
        console.error(e);
        actions.push({
          message: `Error: ${e.message}`,
          type: MagicAction.Error,
        });
      }
    }
  } else {
    actions.push({
      message: 'No available spells for this event.',
      type: MagicAction.Warning,
    });
  }

  let touched = false;
  Object.keys(magicResponses).forEach((key) => {
    magicResponses[key].forEach((response) => {
      if (response.touched) {
        touched = true;
      }
      actions.push(...response.actions);
    });
  });
  onExit?.(current);
  return { actions, transaction: current as PatientTransaction, touched };
};
