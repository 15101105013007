import { classNames } from '@chiroup/core/functions/classNames';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';

type Props = {
  location: ClinicLocation;
  containerClassName?: string;
  onClick?: (location: ClinicLocation) => void;
};

export const LocationCard: React.FC<Props> = ({
  location,
  containerClassName,
  onClick,
}) => {
  if (!location) return null;

  const handleClick =
    onClick ||
    ((_: ClinicLocation) => {
      console.warn('LocationCard: no onClick');
    });

  return (
    <div className={containerClassName}>
      <div
        className={classNames(
          'flex flex-col text-xs border border-gray-300 hover:ring-2 hover:ring-primary-500 shadow-md hover:shadow-sm rounded-md ',
          onClick ? 'cursor-pointer hover:fo' : '',
        )}
        onClick={(e: any) => {
          e.preventDefault();
          handleClick(location);
        }}
      >
        <div className="bg-gray-300 dark:bg-darkGray-500 dark:text-gray-300 text-gray-800 rounded-md rounded-b-none p-1 font-bold">
          Location
        </div>
        <div className="p-1 bg-white dark:bg-darkGray-400 dark:text-white text-gray-800 rounded-md rounded-t-none">
          <div>{location.address1}</div>
          <div>{location.city}</div>
          <div>
            {location.state}, {location.zip}
          </div>
        </div>
      </div>
    </div>
  );
};
