import Decimal from 'decimal.js';
import { createDecimal } from './createDecimal';
import { findNewDiscount } from './findNewDiscount';
import {
  PatientTransactionItemType,
  TransactionItemTypeEnum,
  TransactionItemSubtypeEnum,
  TransactionTypeEnum,
} from '../types/PatientTransaction.type';

export const subtotalFromItemsBeforePercentageDiscounts = ({
  items,
}: {
  items: PatientTransactionItemType[];
}): Decimal => {
  if (!items || !Array.isArray(items) || items.length === 0) {
    return createDecimal(0);
  }
  return (items || [])
    .reduce((acc, curr) => {
      if (
        curr?.type === TransactionItemTypeEnum.Void ||
        curr?.subtype === TransactionItemSubtypeEnum.Tax
      ) {
        return acc;
      }

      const amount = createDecimal(curr?.amount || 0);
      const quantity = curr?.units || 0;
      const total = amount.times(quantity);
      if (curr?.type === TransactionItemTypeEnum.Debit) {
        return acc.plus(total);
      } else if (
        curr?.type === TransactionItemTypeEnum.Credit &&
        curr?.subtype === TransactionItemSubtypeEnum.Adjustment &&
        curr?.adjustment?.structure !== '% Discount'
      ) {
        return acc.minus(total);
      }

      return acc;
    }, createDecimal(0))
    .toDP(2);
};

export const subtotalAndTaxFromItems = ({
  items,
  productTaxRate = 0,
  serviceTaxRate = 0,
  makeRateZero = false,
}: {
  items: PatientTransactionItemType[];
  productTaxRate?: number;
  serviceTaxRate?: number;
  makeRateZero?: boolean;
}): {
  subtotal: number;
  tax: number;
  total: number;
  balance: number;
} => {
  const percentAdjustment = items?.find((item) => {
    let theAdjustment = item.adjustment;
    if (theAdjustment && typeof theAdjustment === 'string') {
      theAdjustment = JSON.parse(theAdjustment);
    }
    return !!theAdjustment?.percentOff;
  });
  if (percentAdjustment && typeof percentAdjustment.adjustment === 'string') {
    percentAdjustment.adjustment = JSON.parse(percentAdjustment.adjustment);
  }
  const percentAdjustmentExists = !!percentAdjustment;

  let itemsWithNoTax = items.filter(
    (item) => item.subtype !== TransactionItemSubtypeEnum.Tax,
  );

  if (percentAdjustmentExists) {
    itemsWithNoTax = findNewDiscount(itemsWithNoTax);
  }

  // If there is a treatment on a transaction, we're using the service tax rate, otherwise we're using the product tax rate
  const hasTreatment = itemsWithNoTax.find(
    (item) =>
      // (item?.subtype === TransactionItemSubtypeEnum.Treatment &&
      //   (item?.amount ?? 0) === 0) ||
      item?.subtype === TransactionItemSubtypeEnum.Treatment ||
      item?.subtype === TransactionItemSubtypeEnum.Service ||
      item?.subtype === TransactionItemSubtypeEnum.PatientService,
  );

  const taxRate = makeRateZero
    ? createDecimal(0)
    : hasTreatment
      ? createDecimal(serviceTaxRate)
      : createDecimal(productTaxRate);
  if ((itemsWithNoTax ?? []).length === 0) {
    return {
      subtotal: 0,
      tax: 0,
      total: 0,
      balance: 0,
    };
  }

  const paymentTotal = (items || []).reduce((acc, curr) => {
    // No need to explicitly handle voids here unless changes are made in the future.
    if (
      curr?.type === TransactionItemTypeEnum.Credit &&
      curr?.subtype !== TransactionItemSubtypeEnum.Adjustment &&
      curr?.subtype !== TransactionItemSubtypeEnum.Override
    ) {
      return acc.plus(createDecimal(curr?.amount || 0));
    } else if (
      curr?.type === TransactionItemTypeEnum.Debit &&
      curr?.subtype === TransactionItemSubtypeEnum.PatientRefund
    ) {
      return acc.minus(createDecimal(curr?.amount || 0));
    }
    return acc;
  }, createDecimal(0));

  const override = itemsWithNoTax.find(
    (item) =>
      item?.type === TransactionItemTypeEnum.Debit &&
      item?.subtype === TransactionItemSubtypeEnum.Override,
  );
  if (override) {
    const subtotal = createDecimal(override?.amount || 0);
    const tax = subtotal.times(taxRate.dividedBy(100));
    const total = createDecimal(override?.amount || 0).plus(tax);
    const balance = total.minus(paymentTotal);
    // balance = balance.minus(creditTotal)

    return {
      subtotal: Number(subtotal.toFixed(2)),
      tax: Number(tax.toFixed(2)),
      total: Number(total.toFixed(2)),
      balance: Number(balance.toFixed(2)),
    };
  }

  const { subtotal, itemsTotal } = (itemsWithNoTax || []).reduce(
    ({ subtotal, itemsTotal }, curr) => {
      if (curr?.type !== TransactionItemTypeEnum.Void) {
        // Subtotal stuff
        const amount = createDecimal(curr?.amount || 0);
        const quantity = curr?.units || 0;
        const total = amount.times(quantity);

        if (
          curr?.type === TransactionItemTypeEnum.Debit &&
          curr?.subtype !== TransactionItemSubtypeEnum.PatientRefund &&
          curr?.subtype !== TransactionItemSubtypeEnum.Tax
        ) {
          if (curr?.salesTax) {
            subtotal = subtotal.plus(total);
            itemsTotal = itemsTotal.plus(total);
          } else {
            subtotal = subtotal.plus(total);
            itemsTotal = itemsTotal.plus(total);
          }
        } else if (
          curr?.type === TransactionItemTypeEnum.Credit &&
          curr?.subtype === TransactionItemSubtypeEnum.Adjustment
        ) {
          subtotal = subtotal.minus(total);
        }
      }
      return {
        subtotal,
        itemsTotal,
      };
    },
    {
      subtotal: createDecimal(0),
      itemsTotal: createDecimal(0),
    },
  );

  const totalDollarDiscount = itemsWithNoTax
    .reduce((acc, curr) => {
      if (curr?.adjustment && typeof curr?.adjustment === 'string') {
        // This happens on the backend for some reason. I'm not going to look for it.
        curr.adjustment = JSON.parse(curr.adjustment);
      }
      if (
        curr?.type === TransactionItemTypeEnum.Credit &&
        curr?.subtype === TransactionItemSubtypeEnum.Adjustment &&
        curr?.adjustment?.structure === '$ Discount'
      ) {
        return acc.plus(createDecimal(curr?.amount || 0));
      }
      return acc;
    }, createDecimal(0))
    .toDP(2);

  const itemsToBuy = itemsWithNoTax.filter(
    (item) =>
      item?.type === TransactionItemTypeEnum.Debit &&
      item?.subtype !== TransactionItemSubtypeEnum.PatientRefund &&
      item?.subtype !== TransactionItemSubtypeEnum.Adjustment &&
      item?.subtype !== TransactionItemSubtypeEnum.Tax,
  );

  let discountUsedUp = createDecimal(0);
  const taxForItems: Decimal = makeRateZero
    ? createDecimal(0)
    : (itemsToBuy || [])
        .filter((i) => !!i?.salesTax)
        .reduce((acc: Decimal, curr) => {
          /**
           * [2025-01-22.1043 by Brian via Emily] Treatments use the
           * service tax rate. Otherwise, original logic applies.
           */
          const taxRate =
            curr?.subtype === TransactionItemSubtypeEnum.Treatment
              ? createDecimal(serviceTaxRate)
              : hasTreatment
                ? createDecimal(serviceTaxRate)
                : createDecimal(productTaxRate);
          const isLastItem = curr === itemsToBuy[itemsToBuy.length - 1];
          const itemTotalWithQuantity = createDecimal(curr?.amount || 0).times(
            curr?.units || 1,
          );

          const subtotalAmount = itemTotalWithQuantity;
          if (isLastItem) {
            const discountLeft = totalDollarDiscount.minus(discountUsedUp);
            const amountToTax = subtotalAmount.minus(discountLeft);

            const taxForItem = taxForTransactionItem({
              item: { ...curr, amount: amountToTax.toFixed(2) },
              taxRate,
              percentAdjustment,
            });
            return acc.plus(taxForItem);
          } else {
            const percentageOfTotal = subtotalAmount.dividedBy(itemsTotal);
            const totalDiscount = totalDollarDiscount.times(percentageOfTotal);
            discountUsedUp = discountUsedUp.plus(totalDiscount);
            const amountToTax = subtotalAmount.minus(totalDiscount);
            return acc.plus(
              taxForTransactionItem({
                item: { ...curr, amount: amountToTax.toFixed(2) },
                taxRate,
                percentAdjustment,
              }),
            );
          }
        }, createDecimal(0));

  const finalSubtotal = Number(subtotal.toFixed(2));
  const finalTax = Number(taxForItems.toFixed(2));
  const total = Number(subtotal.plus(finalTax).toFixed(2));
  const balance =
    Number(subtotal.plus(finalTax).minus(paymentTotal).toFixed(2)) || 0;
  return {
    subtotal: finalSubtotal,
    tax: finalTax,
    total,
    balance,
  };
};

export const balanceFromItems = ({
  items,
  productTaxRate,
  serviceTaxRate,
  startingBalance,
  transactionType,
  onEntry, // Mostly so we can say where this is being called from.
}: {
  startingBalance: number | undefined;
  items: PatientTransactionItemType[];
  productTaxRate?: number;
  serviceTaxRate?: number;
  transactionType: TransactionTypeEnum;
  onEntry?: () => void;
}): {
  balance: number;
  tax: number;
  total?: number;
} => {
  onEntry?.();

  if (!items?.length) return { balance: startingBalance || 0, tax: 0 };

  const hasSalesTax = items?.some((item) => item?.salesTax === true);
  const packageApplied = items?.some(
    (item) =>
      item?.subtype === TransactionItemSubtypeEnum.Adjustment &&
      !!item?.packageId,
  );
  const { balance, tax, total } = subtotalAndTaxFromItems({
    items,
    productTaxRate,
    serviceTaxRate,
    makeRateZero: !hasSalesTax || packageApplied,
  });

  if (balance === 0) {
    return {
      balance: balance || 0,
      tax: tax || 0,
      total,
    };
  }

  startingBalance = startingBalance || 0;

  // if (startingBalance !== 0) {
  //   return { balance: startingBalance + balance, tax };
  // }

  if (transactionType === TransactionTypeEnum.Payment) {
    return { balance: -balance, tax: 0, total: 0 };
  }

  return {
    balance,
    tax,
    total: typeof total === 'number' && !isNaN(total) ? total : 0,
  };
};

const taxForTransactionItem = ({
  item,
  taxRate = createDecimal(0),
  percentAdjustment,
}: {
  item: PatientTransactionItemType;
  taxRate?: Decimal;
  percentAdjustment?: PatientTransactionItemType;
}): Decimal => {
  const chargedAmount = percentAdjustment?.adjustment?.percentOff
    ? Number(item?.amount || 0) *
      (1 - percentAdjustment?.adjustment?.percentOff / 100)
    : item?.amount;
  const taxRateToUse = taxRate.dividedBy(100);
  const price = createDecimal(chargedAmount || 0);
  const taxToCharge = price.times(taxRateToUse);
  return taxToCharge.toDP(2);
};
