import {
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import {
  ConsolidatedTransactionGetResponse,
  PatientTransaction,
} from '@chiroup/core/types/PatientTransaction.type';
import { Connection } from 'mysql2/promise';
import { MagicAction, MagicActionType } from './commonMagic';
import { fnProviderChangeRules } from './fnProviderChangeRules';
import { fnTreatmentRules } from './fnTreatmentRules';

export enum ConsolidatedTransactionMagicEvent {
  beforeSaveTransactions = 'beforeSaveTransactions',
}

export type ConsolidatedTransactionMagiceResponse = {
  payload: ConsolidatedTransactionGetResponse;
  actions: MagicActionType[];
  touched: boolean;
};

export type ConsolidatedTransactionMagiceInputType = {
  connection?: Connection;
  payload: ConsolidatedTransactionGetResponse;
  event: ConsolidatedTransactionMagicEvent;
  onEntry?: ((payload: ConsolidatedTransactionGetResponse) => void) | null;
  onExit?: ((payload: ConsolidatedTransactionGetResponse) => void) | null;
  trace?: boolean;
  options?: any;
};

const spells = {
  [ConsolidatedTransactionMagicEvent.beforeSaveTransactions]: [
    fnTreatmentRules,
    fnProviderChangeRules,
  ],
} as STRING_ANY_HASH;

export const consolidatedTransactionMagic = async ({
  connection,
  payload: incomingPayload,
  event,
  onEntry = null,
  onExit = null,
  options = {},
}: ConsolidatedTransactionMagiceInputType) => {
  let payload = ChiroUpJSON.clone(incomingPayload, null);

  // Attach the connection to the options for the spells.
  if (connection) {
    options.connection = connection;
  }

  if (!payload) {
    return {
      actions: [
        {
          message: `No actions possible without a payload.`,
          type: MagicAction.Error,
        },
      ],
      payload,
    };
  }
  if (!payload?.group?.length && !connection) {
    return {
      actions: [
        {
          message: `No actions possible without a group or a connection.`,
          type: MagicAction.Error,
        },
      ],
      payload,
    };
  }

  const items = payload?.items ?? [];
  const itemsByBillingKey = items.reduce(
    (acc: STRING_BOOLEAN_HASH, item: PatientTransaction) => {
      acc[item.billingKey] = true;
      if (item?.merged) {
        acc[item.merged] = true;
      }
      return acc;
    },
    {} as STRING_BOOLEAN_HASH,
  );
  const bks = Object.keys(itemsByBillingKey || {}) ?? [];

  if (!bks.length) {
    return {
      actions: [
        {
          message: `No actions possible without items.`,
          type: MagicAction.Error,
        },
      ],
      payload,
    };
  }

  const actions: MagicActionType[] = [];
  const magicResponses: {
    [key: string]: ConsolidatedTransactionMagiceResponse[];
  } = {};

  onEntry?.(payload);
  // if (trace) {
  //   console.log({ payload, event });
  // }

  /**
   * Use case #1: This is a transaction _with_ appointments.
   *
   * Go get the appointment group from the appointments if
   * they exist. [Remember, encounters _don't_ have to have
   * appointments so this might not work.]
   */
  if (!payload?.group?.length) {
    const sql = `
    SELECT a.id AS itemId
         , a.treatmentId
         , a.disciplineId
         , (SELECT DT.name 
              FROM DisciplineTreatment DT
             WHERE a.treatmentId = DT.ID
           ) AS treatmentName
         , (SELECT pD.name 
              FROM Discipline pD 
             WHERE a.disciplineId = pD.id
           ) AS disciplineName
      FROM Appointment a        
     WHERE id IN (:bks)
      `;
    const args = { bks };
    // console.log(
    //   '....... sql .......\n',
    //   sql,
    //   bks,
    //   itemsByBillingKey,
    //   '\n ....... /end .......',
    // );
    const [rows] = (await connection?.query(sql, args)) as unknown as any[];
    // console.log(rows);
    payload.group = rows;
  }

  /**
   * Use case #2: This is a transaction _without_ appointments.
   */
  if (!payload?.group?.length) {
    const sql = `
    SELECT pt.billingKey AS itemId
         , pt.treatmentId
         , pt.disciplineId
         , (SELECT DT.name 
              FROM DisciplineTreatment DT
             WHERE pt.treatmentId = DT.ID
           ) AS treatmentName
         , (SELECT pD.name 
              FROM Discipline pD 
             WHERE pt.disciplineId = pD.id
           ) AS disciplineName
      FROM PatientTransaction pt        
     WHERE pt.billingKey IN (:bks)
      `,
      args = { bks };
    // console.log(
    //   '....... use case #2: sql .......\n',
    //   sql,
    //   bks,
    //   itemsByBillingKey,
    //   '\n ....... /end .......',
    // );
    const [rows] = (await connection?.query(sql, args)) as unknown as any[];
    // console.log(rows);
    payload.group = rows;
  }

  /**
   * Use case #3: This is not a transaction with appts.
   *
   * The spells (functions) need to adapt to this.
   */

  if (spells[event]) {
    magicResponses[event] = magicResponses[event] || [];
    // This needs to be synchronous as it may get a promise
    // back it needs to wait for.
    for (const magic of spells[event]) {
      try {
        const res = magic(options, payload);
        if (res instanceof Promise) {
          await res
            .then((mr: ConsolidatedTransactionMagiceResponse) => {
              // console.log('\n\n...... promise return.......\n', mr?.actions);
              magicResponses[event].push(mr);
              const { payload: newPayload } = mr;
              payload = newPayload;
            })
            .catch((e: any) => {
              console.error(e);
              actions.push({
                message: `Error: ${e.message}`,
                type: MagicAction.Error,
              });
            });
        } else {
          const mr: ConsolidatedTransactionMagiceResponse = res;
          // console.log('\n\n...... non-promise return.......\n', mr);
          magicResponses[event].push(mr);
          const { payload: newPayload } = mr;
          payload = newPayload;
        }
      } catch (e: any) {
        console.error(e);
        actions.push({
          message: `Error: ${e.message}`,
          type: MagicAction.Error,
        });
      }
    }
  } else {
    actions.push({
      message: 'No available spells for this event.',
      type: MagicAction.Warning,
    });
  }

  // console.log(
  //   '\n\n....... magicResponses\n',
  //   magicResponses,
  //   '\n....... /end .......',
  // );

  // console.log(`payload.changed=${(payload as any).changed}`);

  let touched = false;
  Object.keys(magicResponses || {}).forEach((key) => {
    magicResponses[key].forEach((response) => {
      if (response.touched) {
        touched = true;
      }
      actions.push(...response.actions);
    });
  });
  onExit?.(payload);
  return { actions, payload, touched };
};
