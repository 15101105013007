import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { GetInvoiceNotesResponse } from '@chiroup/core/types/Invoice.type';

const invoiceNoteService = () => {
  const getAll = async (clinicId: number, invoiceId: number) => {
    if (!clinicId || !invoiceId) {
      return { rows: [] } as GetInvoiceNotesResponse;
    }
    return await ChiroUpAPI.get(
      'api',
      `/${clinicId}/invoice-notes/${invoiceId}`,
      {},
    );
  };

  const create = async (clinicId: number, invoiceId: number, note: string) => {
    return await ChiroUpAPI.post(
      'api',
      `/${clinicId}/invoice-notes/${invoiceId}`,
      {
        body: { note },
      },
    );
  };

  const remove = async (
    clinicId: number,
    invoiceId: number,
    noteId: number,
  ) => {
    return await ChiroUpAPI.del(
      'api',
      `/${clinicId}/invoice-notes/${invoiceId}/${noteId}`,
      {},
    );
  };

  const toggleFlag = async (
    clinicId: number,
    invoiceId: number,
    isFlagged: boolean,
  ) => {
    return await ChiroUpAPI.put(
      'api',
      `/transactions/${clinicId}/invoice/${invoiceId}/flag`,
      {
        body: { isFlagged },
      },
    );
  };

  const downloadPdf = async (clinicId: number, invoiceId: number) => {
    const response = await ChiroUpAPI.get(
      'api',
      `/${clinicId}/invoice-notes/${invoiceId}/pdf`,
      {},
    );
    if (response.filename && response.data && response.contentType) {
      const byteCharacters = atob(response.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.contentType });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = response.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return response;
  };

  return {
    getAll,
    create,
    remove,
    toggleFlag,
    downloadPdf,
  };
};

export default invoiceNoteService();
