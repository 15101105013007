import { useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import patientBillingService from '../../../../../services/patientBilling.service';
import { MeContext } from '../../../../../contexts/me.context';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';

const getQuery = function (clinicId: number, patientId: string) {
  return async (context: QueryFunctionContext) => {
    const id = context.queryKey[1] as string;
    if (id === 'add') {
      return {} as Insurance;
    }
    return patientBillingService.getPatientInsurance(id, clinicId, patientId);
  };
};

const useInsurance = function ({
  id,
  patientId,
  billingKey,
  serviceCodes = [],
}: {
  id: string;
  patientId: string;
  billingKey?: string;
  serviceCodes?: string[];
}) {
  // console.log({ useInsurance: { id, patientId, billingKey } });
  const toast = useContext(ToastContext);
  const { me } = useContext(MeContext);
  const queryClient = useQueryClient();
  const [isDeleting, setIsDeleting] = useState(false);

  const { status, data, error, isFetching, refetch } = useQuery(
    ['insurance', id],
    getQuery(me?.selectedClinic?.ID || -1, patientId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const save = async (val: any) => {
    const { id: _, ...rest } = val;
    rest.serviceCodes = serviceCodes;
    if (!id || id === 'add') {
      const res: any = await patientBillingService.createPatientInsurance(
        rest,
        me?.selectedClinic?.ID || -1,
        patientId,
      );
      queryClient.setQueryData(
        ['insurance', patientId],
        (oldData: { data: Insurance[] } | undefined) => {
          if (!oldData) return { data: [res] };
          return {
            data: [...oldData.data, res],
          };
        },
      );
      queryClient.invalidateQueries(['insuranceList', patientId]);
      if (billingKey) {
        queryClient.invalidateQueries(['transaction', billingKey]);
      }
      return res;
    } else {
      const res = await patientBillingService.updatePatientInsurance(
        id,
        val,
        me?.selectedClinic?.ID || -1,
        patientId,
      );
      queryClient.setQueryData(
        ['insurance', patientId],
        (oldData: { data: Insurance[] } | undefined) => {
          if (!oldData) return { data: [] };
          return {
            data: [
              ...oldData.data.map((item) => (item.id === id ? res : item)),
            ],
          };
        },
      );
      queryClient.invalidateQueries(['insuranceList', patientId]);
      if (billingKey) {
        queryClient.invalidateQueries(['transaction', billingKey]);
      }
      return res;
    }
  };

  const del = async (id: string) => {
    if (!id) return;
    setIsDeleting(true);
    try {
      await patientBillingService.deletePatientInsurance(
        id,
        me?.selectedClinic?.ID || -1,
        patientId,
      );

      queryClient.invalidateQueries(['insuranceList', patientId]);
      setIsDeleting(false);
    } catch (err) {
      setIsDeleting(false);
      toast.createToast({
        type: ToastTypes.Fail,
        title: 'Failed to delete',
        description: (
          <>An error ocurred trying to delete this item. Please try again.</>
        ),
      });
    }
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    save,
    del,
    isDeleting,
  };
};

export default useInsurance;
