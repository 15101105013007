import { STRING_BOOLEAN_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import {
  ConsolidatedTransactionGetResponse,
  isaTreatmentTransactionType,
} from '@chiroup/core/types/PatientTransaction.type';
import { Connection } from 'mysql2/promise';
import { MagicAction, MagicActionType } from './commonMagic';
import { ConsolidatedTransactionMagiceResponse } from './consolidatedTransactionMagic';

type singleProvider = {
  prev?: { id: string };
  next?: { id: string; displayName: string };
};

type simpleEncounterRow = {
  id: string;
  clinician: { id: string; name: string };
  signed: string;
};

export const fnProviderChangeRules = async (
  options: any,
  payload: ConsolidatedTransactionGetResponse,
): Promise<ConsolidatedTransactionMagiceResponse> => {
  const actions: MagicActionType[] = [],
    trace = options?.trace ?? false,
    connection = (options?.connection ?? null) as Connection | null,
    usedBillingKeys = {} as STRING_BOOLEAN_HASH,
    isAppointmentMode =
      !!options?.appointment && !!options?.previousAppointment,
    nextSlots = options?.appointment?.slots ?? [],
    prevSlots = options?.previousAppointment?.slots ?? [];

  if (!payload) {
    actions.push({
      message: `No actions possible without a payload.`,
      type: MagicAction.Error,
    });
  }
  let touched = false;

  if (trace) {
    console.log({
      fnProviderChangeRules: { payload, options, isAppointmentMode },
    });
  }

  const items = payload.items ?? [],
    providersByBillingKey = {} as { [key: string]: singleProvider };

  /**
   * Use case #1: We're tweaking from saving the appointment. This
   * means we need to check the transactions and the encounter.
   */
  if (isAppointmentMode) {
    for (const slot of nextSlots) {
      const bk = slot?.id;
      if (!bk) continue;
      usedBillingKeys[bk] = true;
      providersByBillingKey[bk] = providersByBillingKey[bk] ?? {};
      providersByBillingKey[bk].next = {
        id: slot?.clinicianId,
        displayName: slot?.displayValues?.clinicianName,
      };
    }

    if (trace) {
      console.log(
        '....... providersByBillingKey\n',
        providersByBillingKey,
        '\n/end .......',
      );
    }

    for (const slot of prevSlots) {
      const bk = slot?.id;
      if (!bk) continue;
      usedBillingKeys[bk] = true;
      providersByBillingKey[bk] = providersByBillingKey[bk] ?? {};
      providersByBillingKey[bk].prev = {
        id: slot?.clinicianId,
      };
    }

    if (trace) {
      console.log(
        '....... providersByBillingKey after previous slots\n',
        providersByBillingKey,
        '\n/end .......',
      );
    }

    if (!items.length) {
      actions.push({
        message: `No actions possible without items.`,
        type: MagicAction.Error,
      });
    }
    // Fix the transactions we're working on.
    if (Object.keys(usedBillingKeys).length) {
      for (const item of items) {
        if (trace) {
          console.log(
            '\n\n....... item bk\n',
            item?.billingKey,
            '\n isaTreatmentTransactionType \n',
            isaTreatmentTransactionType(item),
          );
        }
        if (!isaTreatmentTransactionType(item)) continue;
        const bk = item?.billingKey;
        if (!bk) continue;
        const provider = providersByBillingKey[bk],
          prevId = provider?.prev?.id ?? '',
          nextId = provider?.next?.id ?? '';

        if (trace) {
          console.log(
            '....... provider\n',
            provider,
            '\nprevId\n',
            prevId,
            '\nnextId\n',
            nextId,
            '\n item?.provider \n',
            item?.provider,
            '....... /end .......',
          );
        }

        if (prevId !== nextId) {
          if (item?.merged) {
            actions.push({
              message: `Please unmerge the transaction for this time slot before changing the clinician.`,
              type: MagicAction.Error,
            });
          } else {
            touched = true;
            actions.push({
              message: `Updated provider on transaction to ${provider?.next?.displayName}.`,
              type: MagicAction.Info,
            });
            item.provider = provider.next;
          }
        }
      }
    }

    /**
     * Try to sync up the clinician to the encounter. One thing to note
     * is that once someone is checked in, the clinician can't normally
     * be changed _by the appointment slot_.
     *
     * I would prefer this not not be here as it doesn't really have to
     * do with the encounter, but I don't want to duplicate the exact
     * business logic in more than one place either.
     */
    if (connection) {
      if (trace) {
        console.log('....... connection defined .......');
      }
      try {
        const sql = `SELECT id, clinician, signed FROM Encounter where id IN (:ids)`,
          ids = Object.keys(usedBillingKeys);
        if (trace) {
          console.log('...... looking for encounters\n', sql, '\nids\n', ids);
        }
        if (ids.length) {
          const [rows] = (await connection.query(sql, {
            ids,
          })) as unknown as [simpleEncounterRow[]];
          if (trace) {
            console.log(
              '....... encounter rows\n',
              rows,
              '\n....... /end .......',
            );
          }
          for (const row of rows) {
            if (row?.signed) {
              actions.push({
                message: `Encounter is already signed.`,
                type: MagicAction.Error,
              });
              continue;
            }
            const bk = row?.id;
            if (!bk) continue;
            const provider = providersByBillingKey[bk],
              prevId = provider?.prev?.id ?? '',
              nextId = provider?.next?.id ?? '';
            if (prevId !== nextId) {
              const clinician = {
                id: provider.next?.id,
                name: provider.next?.displayName,
              };
              if (trace) {
                console.log(
                  'clinician',
                  clinician,
                  'row.clinician',
                  row.clinician,
                );
              }

              await connection.query(
                `UPDATE Encounter SET clinician = :clinician WHERE id = :id`,
                {
                  clinician: ChiroUpJSON.stringify(clinician),
                  id: row.id,
                },
              );
              touched = true;
              actions.push({
                message: `Updated clinician on encounter to ${provider?.next?.displayName}.`,
                type: MagicAction.Info,
              });
            }
          }
        } else {
          if (trace) {
            console.log('No ids found for encounters\n....... /end .......');
          }
        }
      } catch (err) {
        console.error('Error updating encounter clinician', err);
        actions.push({
          message: `The clinician on the encounter could not be updated.`,
          type: MagicAction.Error,
        });
      }
    }
  } else {
    /**
     * We're saving from the transaction...
     *
     * [BWM] This is a noop for now. savePatientTransaction will update
     * the appointment and encounter as needed. Likewise, changing the
     * clinician on the encounter takes care of it too.
     */
  }

  if (trace) {
    console.log(
      '... actions \n',
      actions,
      '\n touched\n',
      touched,
      '\n.../end...',
    );
  }

  return {
    actions,
    payload,
    touched,
  };
};
