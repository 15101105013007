import Decimal from 'decimal.js';
import { subtotalFromItemsBeforePercentageDiscounts } from './balanceFromItems';
import { createDecimal } from './createDecimal';
import { PatientTransactionItemType } from '../types/PatientTransaction.type';

export const findNewDiscount = (items: PatientTransactionItemType[]) => {
  const percentDiscountApplied = items?.find(
    (item) =>
      item.subtype === 'Adjustment' &&
      item?.adjustment?.structure === '% Discount',
  ) as PatientTransactionItemType;
  const percentOff = percentDiscountApplied?.adjustment?.percentOff;
  const filteredOutPercentOff = items?.filter(
    (item) =>
      !(
        item.subtype === 'Adjustment' &&
        item?.adjustment?.structure === '% Discount'
      ),
  );

  const subtotal: Decimal = subtotalFromItemsBeforePercentageDiscounts({
    items: filteredOutPercentOff || [],
  });

  const newDiscount = !percentOff
    ? 0
    : createDecimal(subtotal.times(createDecimal(percentOff)))
        .dividedBy(100)
        .toFixed(2);

  const newItems = [
    ...filteredOutPercentOff,
    { ...percentDiscountApplied, amount: newDiscount },
  ];

  return newItems;
};
