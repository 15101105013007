import { Checkbox, Currency } from '@chiroup/components';
import { STRING_NUMBER_HASH } from '@chiroup/core/constants/globals';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import {
  PatientTransaction,
  TransactionItemSubtypeEnum,
  TransactionItemTypeEnum,
  isaServiceItem,
} from '@chiroup/core/types/PatientTransaction.type';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import Loading from '../../../../common/Loading';
import { TransactionItem } from './TransactionItem';

type Props = {
  transaction: PatientTransaction | null;
  editing?: boolean;
  hiddenWhenEmpty?: boolean;
  hideUnits?: boolean;
  isLoading?: boolean;
  onChange: (ord: number) => (property: string) => (value: string) => void;
  onRemove?: (ord: number) => void;
  readonly?: boolean;
};

const sortWeights = {
  [TransactionItemSubtypeEnum.Treatment]: 20,
  [TransactionItemSubtypeEnum.Supplement]: 40,
  [TransactionItemSubtypeEnum.Service]: 60,
  [TransactionItemSubtypeEnum.PatientService]: 80,
  [TransactionItemSubtypeEnum.AdditionalFee]: 100,
  [TransactionItemSubtypeEnum.Adjustment]: 120,
  [TransactionItemSubtypeEnum.AdjustmentPackage]: 140,
  [TransactionItemSubtypeEnum.AppliedPayment]: 160,
  [TransactionItemSubtypeEnum.BalanceAllocatedToPatient]: 180,
  [TransactionItemSubtypeEnum.BalanceTransfer]: 200,
  [TransactionItemSubtypeEnum.Canceled]: 220,
  [TransactionItemSubtypeEnum.NoShow]: 240,
  [TransactionItemSubtypeEnum.Override]: 260,
  [TransactionItemSubtypeEnum.Package]: 280,
  [TransactionItemSubtypeEnum.PatientCredit]: 300,
  [TransactionItemSubtypeEnum.PatientPayment]: 320,
  [TransactionItemSubtypeEnum.PatientRefund]: 340,
  [TransactionItemSubtypeEnum.PatientWriteOff]: 360,
  [TransactionItemSubtypeEnum.Payment]: 380,
  [TransactionItemSubtypeEnum.PayorWriteOff]: 400,
  [TransactionItemSubtypeEnum.ProcessingFee]: 420,
  [TransactionItemSubtypeEnum.RefundUnappliedPayment]: 440,
  [TransactionItemSubtypeEnum.Reprocessed]: 9000,
  [TransactionItemSubtypeEnum.Tax]: 99999,
} as STRING_NUMBER_HASH;

export const ConsolidatedTransactionLedger: React.FC<Props> = ({
  transaction,
  editing,
  hiddenWhenEmpty = true,
  hideUnits = false,
  isLoading = false,
  onChange,
  onRemove,
  readonly = false,
}) => {
  const [patientActivitySelected, setPatientActivitySelected] = useState(true);
  const [payorActivitySelected, setPayorActivitySelected] = useState(true);

  const {
    billingKey,
    hasServices,
    incomingItems,
    patientId,
    patientName,
    // showActivityOptions,
    activityOptions,
  } = useMemo(() => {
    const showActivityOptions = false; //transaction?.type !== TransactionTypeEnum.AdHoc;
    return {
      billingKey: transaction?.billingKey,
      hasServices: transaction?.items?.some((item) => isaServiceItem(item)),
      incomingItems: transaction?.items ?? [],
      patientId: transaction?.patient?.id,
      patientName: transaction?.patient?.displayName,
      showActivityOptions,
      activityOptions: showActivityOptions ? (
        <>
          {' '}
          <div className="flex flex-row space-x-2 text-sm font-normal">
            <div>Patient Activity</div>
            <Checkbox
              value={patientActivitySelected}
              onChange={() =>
                setPatientActivitySelected(!patientActivitySelected)
              }
            />
          </div>
          <div className="flex flex-row space-x-2 text-sm font-normal">
            <div>Payor Activity</div>
            <Checkbox
              value={payorActivitySelected}
              onChange={() => setPayorActivitySelected(!payorActivitySelected)}
            />
          </div>
        </>
      ) : null,
    };
  }, [
    patientActivitySelected,
    payorActivitySelected,
    transaction?.billingKey,
    transaction?.items,
    transaction?.patient?.displayName,
    transaction?.patient?.id,
  ]);

  const items = useMemo(() => {
    return (
      incomingItems
        // ?.filter((item) => {
        //   const activity = {
        //     [TransactionItemSubtypeEnum.AdditionalFee]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Adjustment]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.AdjustmentPackage]:
        //       patientActivitySelected,
        //     [TransactionItemSubtypeEnum.AppliedPayment]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.BalanceAllocatedToPatient]:
        //       patientActivitySelected,
        //     [TransactionItemSubtypeEnum.BalanceTransfer]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Canceled]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.NoShow]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Override]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Package]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.PatientCredit]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.PatientPayment]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.PatientRefund]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.PatientService]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.PatientWriteOff]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Payment]:
        //       (patientActivitySelected && item?.payorName === 'Patient') ||
        //       (payorActivitySelected && item?.payorName !== 'Patient'),
        //     [TransactionItemSubtypeEnum.PayorWriteOff]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.ProcessingFee]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.RefundUnappliedPayment]:
        //       patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Reprocessed]: payorActivitySelected,
        //     [TransactionItemSubtypeEnum.Service]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Supplement]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Tax]: patientActivitySelected,
        //     [TransactionItemSubtypeEnum.Treatment]: patientActivitySelected,
        //   } as STRING_BOOLEAN_HASH;
        //   if (activity[item?.subtype ?? ''] === false) {
        //     return false;
        //   }
        //   return true;
        // })
        .sort((a, b) => {
          return sortWeights[a.subtype ?? ''] - sortWeights[b.subtype ?? ''];
        })
    );
  }, [incomingItems]);

  const totalDebits = useMemo(() => {
    const hasOverride = incomingItems?.some(
      (item) => item?.subtype === TransactionItemSubtypeEnum.Override,
    );
    return incomingItems
      ?.filter((item) => item?.type === TransactionItemTypeEnum.Debit)
      ?.reduce((acc, item) => {
        if (
          hasOverride &&
          item?.subtype !== TransactionItemSubtypeEnum.Override &&
          item?.subtype !== TransactionItemSubtypeEnum.Tax
        ) {
          return acc;
        }
        return acc.plus(
          createDecimal(item?.amount || 0).times(item?.units || 1),
        );
      }, createDecimal(0))
      .toNumber();
  }, [incomingItems]);

  const totalCredits = useMemo(() => {
    return incomingItems
      ?.filter(
        (item) =>
          item?.type === TransactionItemTypeEnum.Credit &&
          item?.subtype !== TransactionItemSubtypeEnum.Override,
      )
      .reduce((acc, item) => {
        return acc.plus(
          createDecimal(item?.amount || 0).times(item?.units || 1),
        );
      }, createDecimal(0))
      .toNumber();
  }, [incomingItems]);

  const netDecimal = useMemo(() => {
    return createDecimal(totalDebits).minus(totalCredits);
  }, [totalDebits, totalCredits]);

  if (!items || !items.length) {
    if (hiddenWhenEmpty) return null;

    return isLoading ? (
      <div className="flex justify-end">
        <Loading color="text-gray-400" />
      </div>
    ) : (
      <div className="mt-4 overflow-hidden border border-gray-300 dark:border-darkGray-600 rounded-lg shadow-sm w-full">
        <div
          data-component="consolidated-transaction-ledger"
          className="flex flex-col"
        >
          <div className="flex flex-row justify-between w-full font-sans font-bold py-2 bg-gray-100 dark:bg-darkGray-700">
            <div className="px-4 dark:text-white">Ledger</div>
            {activityOptions}
          </div>
        </div>
      </div>
    );
  }

  const overrideApplied = items?.some(
    (item) =>
      item?.type &&
      TransactionItemTypeEnum.Debit &&
      item?.subtype === TransactionItemSubtypeEnum.Override,
  );

  const itemsToShow = items?.filter((item) => true);

  const showPaymentWarning =
    editing && itemsToShow.some((item) => item.subtype === 'Payment');

  return (
    <div className="mt-4 overflow-hidden border border-gray-300 dark:border-darkGray-600 rounded-lg shadow-sm">
      <div
        data-component="consolidated-transaction-ledger"
        className="flex flex-col"
      >
        <div className="flex flex-row justify-between w-full font-sans font-bold py-2 bg-gray-100 dark:bg-darkGray-700">
          <div className="px-4 dark:text-white">Ledger</div>
          {activityOptions}
        </div>
        <div className="flex flex-row justify-between border border-t-2 border-b-2 border-x-0 border-collapse border-gray-200 dark:border-darkGray-600">
          <div className="flex justify-center w-full font-sans font-bold py-2 text-red-600 dark:text-red-400 border border-y-0 border-l-0 border-r-2 border-gray-200 dark:border-darkGray-600">
            Debits
          </div>
          <div className="flex justify-center w-full font-sans font-bold py-2 text-black dark:text-white">
            Credits
          </div>
        </div>
        <div className="flex flex-row w-full">
          <div className="w-1/2 border border-y-0 border-l-0 border-r-2 border-gray-200 dark:border-darkGray-600 bg-white dark:bg-darkGray-800">
            <div className="flex flex-col">
              {itemsToShow
                .filter((item) => item?.type === TransactionItemTypeEnum.Debit)
                .map((item, index) => (
                  <TransactionItem
                    index={index}
                    value={item}
                    key={`teti-${index}`}
                    onRemove={onRemove}
                    readonly={readonly}
                    onChange={onChange(item.id)}
                    overrideApplied={overrideApplied}
                    hideUnits={hideUnits}
                    hasServices={hasServices}
                    patientName={patientName}
                    patientId={patientId}
                    billingKey={billingKey}
                    clickableRows={
                      item.subtype === TransactionItemSubtypeEnum.Payment
                    }
                    usage={`ledger`}
                    lineThrough={
                      itemsToShow.some(
                        (item) =>
                          item.subtype === TransactionItemSubtypeEnum.Override,
                      ) &&
                      item.subtype !== TransactionItemSubtypeEnum.Override &&
                      item.subtype !== TransactionItemSubtypeEnum.Tax
                    }
                  />
                ))}
            </div>
          </div>
          <div className="w-1/2 bg-white dark:bg-darkGray-800">
            <div className="flex flex-col">
              {itemsToShow
                .filter(
                  (item) =>
                    item?.type === TransactionItemTypeEnum.Credit &&
                    item?.subtype !== TransactionItemSubtypeEnum.Override,
                )
                .map((item, index) => (
                  <TransactionItem
                    index={index}
                    value={item}
                    key={`teti-${index}`}
                    onRemove={onRemove}
                    readonly={readonly}
                    onChange={onChange(item.id)}
                    overrideApplied={overrideApplied}
                    hideUnits={hideUnits}
                    hasServices={hasServices}
                    patientName={patientName}
                    patientId={patientId}
                    billingKey={billingKey}
                    clickableRows={
                      item.subtype === TransactionItemSubtypeEnum.Payment
                    }
                    usage={`ledger`}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between w-full font-sans text-sm border border-t-2 border-b-0 border-x-0 border-collapse border-gray-200 dark:border-darkGray-600">
          <div className="text-red-600 dark:text-red-400 py-2 w-1/2 justify-end flex px-2 border border-y-0 border-l-0 border-r-2 border-gray-200 dark:border-darkGray-600">
            <Currency
              value={totalDebits}
              debit={true}
              negativeClassName="text-red-600 dark:text-red-400"
            />
          </div>
          <div className="text-black dark:text-white py-2 w-1/2 justify-end flex px-2">
            <Currency value={totalCredits} />
          </div>
        </div>
        <div
          className={classNames(
            netDecimal.isPositive() && !netDecimal.isZero()
              ? 'text-red-600 dark:text-red-400'
              : 'text-black dark:text-white',
            'w-full flex flex-row justify-between bg-gray-100 dark:bg-darkGray-700 p-2 border border-t-2 border-b-0 border-x-0 border-collapse border-gray-200 dark:border-darkGray-600',
          )}
        >
          <div className="font-bold px-2">Net</div>
          <Currency
            value={netDecimal.toFixed(2)}
            debit={netDecimal.isPositive()}
          />
        </div>
      </div>

      {showPaymentWarning && (
        <p className="mt-8 inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/50">
          <span>
            Alert: Payment has been Received! Please note that any modifications
            to this Purchase could affect the associated invoice and applied
            payments. Exercise caution while making changes.
          </span>
        </p>
      )}
    </div>
  );
};
