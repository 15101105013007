import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { GLOBAL_MANY_IDS_SEP } from '../constants/globals';

export const GetServiceCodesResponse = {};

/**
 * This implies the billing code data set, so no database is required, just
 * the clinicId and the code.
 *
 * @param param
 * @returns
 */
export const getServiceCodes = async <T>({
  clinicId,
  codes,
  payors,
}: {
  clinicId: number | undefined;
  codes: string[] | undefined;
  payors?: boolean;
}): Promise<T> => {
  if (!clinicId || !codes || (!!codes && codes.length === 0)) {
    throw new Error(
      `A clinic (${clinicId}) id and code (${codes}) are required.`,
    );
  }
  try {
    return ChiroUpAPI.get(
      'api',
      `/settings/${clinicId}/codes/${codes.join(GLOBAL_MANY_IDS_SEP)}${
        typeof payors === 'boolean' ? `?payors=${payors}` : ''
      }`,
      {},
    );
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};
