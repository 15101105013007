/**
 * [2024-12-03.1354 by Brian]
 *
 * This is abstracted out to a standalone function so that it can be tested.
 * I'm not saying I wrote any tests, but we can sure write tests to send in
 * canned data and test to see if it merges the way it should.
 *
 * ...baby steps.
 *
 */
import {
  BusinessRuleItemType,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import {
  isaPaymentItem,
  isaServiceItem,
  PatientTransaction,
  PatientTransactionItemType,
  toTransactionItemSubtype,
  TransactionItemSubtypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';

export type MergeTransactionResponseType = {
  transactions: PatientTransaction[];
  messages: BusinessRuleItemType[];
  isMerged: boolean;
};

export type MergeTransactionsOptions = {
  treatments?: boolean;
  units?: boolean;
  providers?: STRING_BOOLEAN_HASH;
};

// This is for the typing. A simpler data structure was easier
// to bork.
export const mergeTransactionsStandalone = ({
  transactions,
  options,
  trace = false,
}: {
  transactions: PatientTransaction[];
  options: MergeTransactionsOptions;
  trace?: boolean;
}): MergeTransactionResponseType => {
  const messages: BusinessRuleItemType[] = [],
    targets: { [key: string]: PatientTransaction } = {},
    sources: { [key: string]: PatientTransaction[] } = {},
    newById: { [key: string]: PatientTransaction } = {},
    clone = ChiroUpJSON.clone(transactions) as PatientTransaction[], // Don't bork the original.
    original = ChiroUpJSON.clone(transactions) as PatientTransaction[]; // For diff detection.

  let notMergedDueToPayments = false,
    notMergedDueToServicesLocked = false,
    notMergedDueToBillingStarted = false;

  if (trace) {
    console.log({
      mergeTransaction_incoming: {
        transactions,
        options,
      },
    });
  }

  /**
   * Business rule:
   *   - We can't merge a transaction IF it has any payments on
   *     it. That would require a LOT of extra work on the backend.
   *
   *     TODO: Maybe add support for this. Would have to find the
   *     payment and then change its paymentToward.
   */
  for (const row of clone ?? []) {
    const providerId = row?.provider?.id ?? '',
      treatmentId = options.treatments ? 'any' : row?.treatmentId,
      key = [row?.provider?.primaryId ?? row?.provider?.id, treatmentId].join(
        '.',
      ),
      hasPayments = row?.items?.some((i: PatientTransactionItemType) =>
        isaPaymentItem(i),
      ),
      services = row?.items?.filter((i: PatientTransactionItemType) =>
        isaServiceItem(i),
      ),
      isServicesLocked = services?.some((i) => i?.locked);

    // Yes, they REALLY have to want to skip this ;-)
    if (
      typeof options?.providers?.[providerId] === 'boolean' &&
      options?.providers?.[providerId] === false
    ) {
      continue;
    }

    if (hasPayments) {
      notMergedDueToPayments = true;
    }
    if (isServicesLocked) {
      notMergedDueToServicesLocked = true;
    }
    if (row?.isBillingStarted) {
      notMergedDueToBillingStarted = true;
    }

    if (
      notMergedDueToPayments ||
      notMergedDueToServicesLocked ||
      notMergedDueToBillingStarted
    ) {
      continue;
    }

    if (!targets[key]) {
      targets[key] = row;
    } else {
      if (!sources[key]) {
        sources[key] = [];
      }
      sources[key].push(row);
    }
  }

  for (const [t, s] of Object.entries(sources)) {
    for (const row of s) {
      for (const item of row?.items ?? []) {
        // We merge the treatment unless told not to do so.
        if (
          item.subtype === TransactionItemSubtypeEnum.Treatment &&
          typeof options?.treatments === 'boolean' &&
          options?.treatments === false
        ) {
          continue; // omit the treatment.
        }
        /**
         * Use case...
         *
         * They MAY have multiple treatments on one transaction this
         * way, but they cannot bill for it. So, this merge may break
         * the ability of the user to generate EDI.
         */
        const treatmentAlreadyThere = targets[t].items.find((i) => {
          return (
            i.subtype === TransactionItemSubtypeEnum.Treatment &&
            i.description === item.description // Not idea, but we were only supposed to have one.
          );
        });
        if (
          !treatmentAlreadyThere &&
          item.subtype === TransactionItemSubtypeEnum.Treatment
        ) {
          targets[t].items.push(
            toTransactionItemSubtype(TransactionItemSubtypeEnum.AddTreatment, {
              ...item,
            }),
          );
        } else {
          targets[t].items.push(item);
        }
      }
      newById[row.billingKey] = {
        ...row,
        merged: targets[t].billingKey,
        items: [],
        services: [],
        insurances: [],
      };
    }
  }

  if (options.units) {
    for (const t of Object.values(targets)) {
      const itemsByCodeAndType = (t.items || []).reduce(
        (acc, item) => {
          if (isaServiceItem(item)) {
            const key = [item.code, item.subtype].join('.');
            if (!acc[key]) {
              acc[key] = item;
            } else {
              acc[key].units = (acc[key].units ?? 0) + (item?.units ?? 0);
            }
          }
          return acc;
        },
        {} as { [key: string]: PatientTransactionItemType },
      );
      const nonServiceItems = t.items.filter((i) => !isaServiceItem(i));
      t.items = [...nonServiceItems, ...Object.values(itemsByCodeAndType)];
      // Maybe keep them in the same order. Might cut down on saves.
      t.items.sort((a, b) => {
        if (a.type !== b.type) {
          return a?.type?.localeCompare(b?.type ?? '') ?? 0;
        }
        if (a.subtype !== b.subtype) {
          return a?.subtype?.localeCompare(b?.subtype ?? '') ?? 0;
        }
        return (a?.id ?? 0) - (b?.id ?? 0);
      });
    }
  }

  // At this point, we should have all the services added or units
  // merged so we can set the services array again.
  for (const t of Object.values(targets)) {
    t.services = t.items.filter((i) => isaServiceItem(i));
    newById[t.billingKey] = t;
  }

  for (let i = 0; i < (clone?.length ?? 0); i++) {
    if (newById[clone[i].billingKey]) {
      clone[i] = newById[clone[i].billingKey];
    }
  }

  if (notMergedDueToPayments) {
    messages.push({
      text: `Merging transactions with payments is not supported.`,
    });
  }

  if (notMergedDueToServicesLocked) {
    messages.push({
      text: `Merging transactions with locked services is not supported.`,
    });
  }

  if (notMergedDueToBillingStarted) {
    messages.push({
      text: `Merging transactions with billing started is not supported.`,
    });
  }

  const originalCountofMerged = original.reduce((acc, t) => {
      if (t.merged) {
        acc++;
      }
      return acc;
    }, 0),
    originalCountofItemsByBillingKey = original.reduce(
      (acc, t) => {
        if (!acc[t.billingKey]) {
          acc[t.billingKey] = t.items.length;
        }
        return acc;
      },
      {} as { [key: string]: number },
    ),
    countofMerged = clone.reduce((acc, t) => {
      if (t.merged) {
        acc++;
      }
      return acc;
    }, 0),
    countofItemsByBillingKey = clone.reduce(
      (acc, t) => {
        if (!acc[t.billingKey]) {
          acc[t.billingKey] = t.items.length;
        }
        return acc;
      },
      {} as { [key: string]: number },
    );

  let isMerged = false;
  if (originalCountofMerged === countofMerged) {
    isMerged = true;
    for (const [key, value] of Object.entries(
      originalCountofItemsByBillingKey,
    )) {
      if (countofItemsByBillingKey[key] !== value) {
        isMerged = false;
        break;
      }
    }
  }
  // console.log({
  //   isMerged,
  //   originalCountofMerged,
  //   countofMerged,
  //   countofItemsByBillingKey,
  //   originalCountofItemsByBillingKey,
  // });

  /**
   * ..finally...merge the tax...
   */
  for (const t of clone) {
    const taxItems =
        t.items.filter((i) => i.subtype === TransactionItemSubtypeEnum.Tax) ??
        [],
      nonTax =
        t.items.filter((i) => i.subtype !== TransactionItemSubtypeEnum.Tax) ??
        [];
    if (taxItems.length !== 0) {
      const totalTax = taxItems.reduce((acc, i) => {
        if (i.subtype === TransactionItemSubtypeEnum.Tax) {
          acc = acc.add(createDecimal(i?.amount ?? 0));
        }
        return acc;
      }, createDecimal(0));
      t.items = nonTax;
      // We have at least one or we would not be here.
      t.items.push({
        ...taxItems[0],
        amount: totalTax.toDP(2).toNumber(),
      });
    }
  }
  if (trace) {
    console.log({
      mergeTransaction: {
        targets,
        sources,
        sourceById: newById,
        messages,
        notMergedDueToPayments,
      },
    });
  }

  return {
    transactions: clone,
    messages,
    isMerged,
  };
};
