import { clog } from '@chiroup/core/functions/clog';
import { createNeverNullDayjs } from '@chiroup/core/functions/time';
import {
  Appointment,
  AppointmentForUI,
  AppointmentStatuses,
  CreateRecurringAppointmentsParams,
} from '@chiroup/core/types/Appointment.type';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useCallback, useContext, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../../contexts/toast.context';
import patientService from '../../../services/patient.service';
import { processAppointmentOrSlots } from './appointmentHelpers';

dayjs.extend(timezone);

const getAppointmentsQuery = (
  timezone: string,
  clinicId: number,
  appointmentId: string | null,
  location: any,
  navigate: any,
) => {
  return async (context: QueryFunctionContext) => {
    if (!appointmentId) {
      return null;
    }
    const resp = await patientService.getAppointment(
      timezone,
      clinicId,
      appointmentId,
    );
    const slotInAppointment =
      resp?.slots?.reduce(
        (a, slot) => {
          a[slot.id] = slot;
          return a;
        },
        {} as Record<string, any>,
      ) ?? {};

    if (
      appointmentId &&
      resp?.id &&
      appointmentId !== 'undefined' &&
      appointmentId !== resp?.id &&
      !slotInAppointment[resp?.id]
    ) {
      const url =
        location.pathname + location.search.replace(appointmentId, resp?.id);

      navigate(url);
    }
    // Default clinicianId for the UI to have something to show.
    resp?.slots?.forEach((slot) => {
      if (!slot?.clinicianId && slot?.roomId) {
        slot.clinicianId = '-1';
      }
    });
    return resp;
  };
};

const useAppointment = ({
  appointmentId,
  sessionId,
  enabled = true,
}: {
  appointmentId: string | null;
  sessionId: string;
  enabled?: boolean;
}) => {
  const { me, selectedLocationFull } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const location = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [isCheckingIn, setIsCheckingIn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const { status, data, error, isFetching, refetch } =
    useQuery<AppointmentForUI | null>(
      ['appointments', 'detail', appointmentId],
      getAppointmentsQuery(
        selectedLocationFull.timezone as string,
        me.selectedClinic?.ID ?? -1,
        appointmentId,
        location,
        navigate,
      ),
      {
        refetchOnWindowFocus: false,
        enabled: appointmentId === 'add' ? false : enabled,
      },
    );

  const saveAppointmentUI = useCallback(
    (appointment: AppointmentForUI) => {
      queryClient.setQueryData(
        ['appointments', 'detail', appointment?.id],
        appointment,
      );

      const slotInAppointment =
        appointment?.slots?.reduce(
          (a, slot) => {
            a[slot.id] = slot;
            return a;
          },
          {} as Record<string, any>,
        ) ?? {};

      processAppointmentOrSlots(appointment, {
        queryClient,
        selectedLocationFull,
        queryKey: ['appointments', 'list'],
        setIsSubmitting,
        setIsChangingStatus,
      });

      // This can happen in a group appointment if they remove
      // the first slot!
      if (
        appointmentId &&
        appointment?.id &&
        appointmentId !== 'undefined' &&
        appointmentId !== appointment?.id &&
        !slotInAppointment[appointment?.id]
      ) {
        const url =
          location.pathname +
          location.search.replace(
            appointmentId,
            appointment?.id ?? appointmentId,
          );
        navigate(url);
      }
      queryClient.invalidateQueries(['dashboardAppointments']);
    },
    [appointmentId, location, navigate, queryClient, selectedLocationFull],
  );

  const checkRecurringAvailability = async (
    appointmentUsedToCreateRecurringTimeStamp: number,
    appointment: Partial<AppointmentForUI>,
  ) => {
    const res = await patientService.checkRecurringAvailability(
      appointmentUsedToCreateRecurringTimeStamp,
      me.selectedClinic?.ID || -1,
      appointment,
    );
    return res;
  };

  const updateRecurringAppointments = async ({
    body,
    clinicId,
    sessionId,
    locationId,
    notify,
    originalAppointment,
  }: CreateRecurringAppointmentsParams) => {
    const res = await patientService.updateRecurringAppointments({
      body,
      clinicId: clinicId || me.selectedClinic?.ID || -1,
      sessionId,
      locationId,
      notify,
      originalAppointment,
    });

    queryClient.refetchQueries(['appointments', 'list']);
    queryClient.invalidateQueries(['dashboardAppointments']);
    return res;
  };

  const saveRecurringAppointments = async ({
    body,
    locationId,
    sessionId,
    notify,
  }: CreateRecurringAppointmentsParams) => {
    const res = await patientService.createRecurringAppointments({
      body,
      clinicId: me.selectedClinic?.ID || -1,
      sessionId,
      locationId,
      notify,
    });

    queryClient.refetchQueries(['appointments', 'list']);
    queryClient.invalidateQueries(['dashboardAppointments']);
    return res;
  };

  const deleteRecurringAppointments = async (appointment: AppointmentForUI) => {
    const res = await patientService.deleteRecurringAppointments(
      appointment,
      me.selectedClinic?.ID || -1,
      sessionId,
    );
    queryClient.refetchQueries(['appointments', 'list']);
    queryClient.invalidateQueries(['dashboardAppointments']);
    return res;
  };

  const deleteAppointmentUI = useCallback(
    (appointment: {
      id: string;
      startTime: Dayjs;
      clinicianId?: string;
      roomId?: number;
    }) => {
      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        undefined,
      );
      const listCache = queryClient
        .getQueryCache()
        .findAll(['appointments', 'list']);

      if (!appointment?.startTime) {
        throw new Error('startTime is required');
      }
      const dayjsStartTime = createNeverNullDayjs({
        datetime: appointment.startTime,
        timezone: selectedLocationFull.timezone as string,
      });

      const day = dayjsStartTime.format('YYYY-MM-DD');

      let cacheWithAppointment = -1;
      let previousDay: string | null = null;
      let previousClinicianId: string | null = null;
      let previousRoomId: string | null = null;
      let cacheWithNewDay = -1;

      if (listCache) {
        for (let i = 0; i < listCache.length; i++) {
          const item = listCache[i];
          const data = item.state.data as any;
          const dataAppointments = data || {};
          const days = Object.keys(dataAppointments || {});
          if (days.includes(day)) {
            cacheWithNewDay = i;
          }
          const itemEntries = Object.entries(dataAppointments || {}) as any;
          for (let j = 0; j < itemEntries.length; j++) {
            const [dayKey, value] = itemEntries[j];
            const valueValues = Object.values(value);
            for (let k = 0; k < valueValues.length; k++) {
              const innerItem: any = valueValues[k];
              const appointments = innerItem.appointments;
              if (appointments) {
                for (let l = 0; l < appointments.length; l++) {
                  const appItem = appointments[l];
                  if (appItem.id === appointment.id) {
                    cacheWithAppointment = i;
                    previousDay = dayKey;
                    previousClinicianId = appItem.clinicianId;
                    previousRoomId = appItem.roomId;
                  }
                }
              }
            }
          }
        }
      }

      if (cacheWithAppointment > -1 && previousDay) {
        const dayKey = previousDay;
        const clinicianId = previousClinicianId;
        const roomId = previousRoomId;

        queryClient.setQueryData(
          listCache[cacheWithAppointment].queryKey,
          (prev: any) => {
            // Remove from clinician
            if (clinicianId && prev[dayKey]?.[clinicianId]) {
              const clinicianData = prev[dayKey][clinicianId];
              if (clinicianData.appointments) {
                const newAppointments = clinicianData.appointments.filter(
                  (item: Appointment) => item.id !== appointment.id,
                );
                clinicianData.appointments = newAppointments;
              }
            }

            // Remove from room
            if (roomId && prev[dayKey]?.[roomId]) {
              const roomData = prev[dayKey][roomId];
              if (roomData.appointments) {
                const newAppointments = roomData.appointments.filter(
                  (item: Appointment) => item.id !== appointment.id,
                );
                roomData.appointments = newAppointments;
              }
            }

            if (cacheWithNewDay === cacheWithAppointment) {
              try {
                // Remove from clinician on new day
                if (
                  appointment.clinicianId &&
                  prev[day]?.[appointment.clinicianId]
                ) {
                  const newAppointments = prev[day][
                    appointment.clinicianId
                  ].appointments?.filter(
                    (item: Appointment) => item.id !== appointment.id,
                  );
                  prev[day][appointment.clinicianId].appointments =
                    newAppointments || [];
                }
                // Remove from room on new day
                if (appointment.roomId && prev[day]?.[appointment.roomId]) {
                  const newAppointments = prev[day][
                    appointment.roomId
                  ].appointments?.filter(
                    (item: Appointment) => item.id !== appointment.id,
                  );
                  prev[day][appointment.roomId].appointments =
                    newAppointments || [];
                }
              } catch (err) {
                clog(`Error deleting appointment from UI: ${err}`);
              }
            }
            return { ...prev };
          },
        );
      }

      if (cacheWithNewDay > -1 && cacheWithNewDay !== cacheWithAppointment) {
        queryClient.setQueryData(
          listCache[cacheWithNewDay].queryKey,
          (prev: any) => {
            // Remove from clinician
            if (
              appointment.clinicianId &&
              prev[day]?.[appointment.clinicianId]
            ) {
              const newAppointments = prev[day][
                appointment.clinicianId
              ].appointments?.filter(
                (item: Appointment) => item.id !== appointment.id,
              );
              prev[day][appointment.clinicianId].appointments =
                newAppointments || [];
            }
            // Remove from room
            if (appointment.roomId && prev[day]?.[appointment.roomId]) {
              const newAppointments = prev[day][
                appointment.roomId
              ].appointments?.filter(
                (item: Appointment) => item.id !== appointment.id,
              );
              prev[day][appointment.roomId].appointments =
                newAppointments || [];
            }
            return { ...prev };
          },
        );
      }

      queryClient.invalidateQueries(['dashboardAppointments']);
    },
    [appointmentId, queryClient, selectedLocationFull?.timezone],
  );

  const saveAppointment = useCallback(
    async ({
      appointment,
      notify,
      removeRecurringId,
      fee,
      doubleBook,
    }: {
      appointment: Partial<AppointmentForUI>;
      notify?: boolean;
      removeRecurringId?: boolean;
      fee?: {
        amount: number;
        subtype: string;
        description: string;
        name: string;
      };
      doubleBook?: boolean;
    }) => {
      if (!selectedLocationFull?.timezone) return;
      const slots = appointment.slots;
      const primaryProvider = appointment.clinicianId;
      //This is just giving the same trackId to all the slots with the same clinician.
      const firstProviderSlotIndex = slots?.reduce(
        (obj: { [key: string]: number }, slot, index) => {
          if (
            slot?.clinicianId &&
            obj[slot.clinicianId] !== 0 &&
            !obj[slot.clinicianId]
          ) {
            obj[slot.clinicianId] = index;
          }
          return obj;
        },
        {},
      );

      const clinicianTrackMap = slots?.reduce<{ [key: string]: number | null }>(
        (map, slot, index) => {
          if (firstProviderSlotIndex?.[slot.clinicianId] === index) {
            map[slot.clinicianId] = slot?.trackId || null;
          }
          return map;
        },
        {},
      );

      const newSlots = (slots || []).map((slot) => {
        const noClinicianOrMinusOne =
          !slot?.clinicianId || slot?.clinicianId === '-1';

        if (noClinicianOrMinusOne) {
          return {
            ...slot,
            trackId:
              clinicianTrackMap?.[primaryProvider as string] === -1
                ? null
                : clinicianTrackMap?.[primaryProvider as string],
          };
        } else {
          return {
            ...slot,
            trackId:
              clinicianTrackMap?.[slot.clinicianId] === -1
                ? null
                : clinicianTrackMap?.[slot.clinicianId],
          };
        }
      });

      appointment.slots = newSlots;

      const newAppointment = await (appointment.id
        ? patientService.updateAppointment(
            me.selectedClinic?.ID,
            sessionId,
            appointment,
            null,
            notify,
            removeRecurringId,
            fee,
            doubleBook,
          )
        : patientService.addAppointment(
            me.selectedClinic?.ID,
            me.selectedLocation,
            sessionId,
            appointment,
            notify,
            doubleBook,
          ));

      if (appointment.deleted) {
        const asAny = newAppointment as any;
        if (Array.isArray(asAny)) {
          asAny.forEach((id: string) => {
            if (typeof id !== 'string') return;
            deleteAppointmentUI({
              id,
              startTime: createNeverNullDayjs({
                datetime: appointment.startTime,
              }), // First time-slot start time.
              clinicianId: appointment.clinicianId as string, // The primary clinician id.
              roomId: appointment.roomId as number,
            });
          });
        } else {
          console.log('TODO: Old code, should not be running!');
          deleteAppointmentUI({
            id: appointment.id as string,
            startTime: createNeverNullDayjs({
              datetime: appointment?.startTime,
            }),
            clinicianId: appointment.clinicianId as string,
            roomId: appointment.roomId as number,
          });
        }
      } else {
        saveAppointmentUI(newAppointment);
        queryClient.setQueryData(
          ['appointments', 'detail', newAppointment.id],
          newAppointment,
        );
      }
      if (fee) {
        queryClient.setQueryData(
          ['transaction', appointmentId],
          (prev: any) => {
            return {
              ...prev,
              items: [fee],
            };
          },
        );
      }
      queryClient.invalidateQueries(['dashboardAppointments']);
      return newAppointment;
    },
    [
      appointmentId,
      deleteAppointmentUI,
      me.selectedClinic?.ID,
      me.selectedLocation,
      queryClient,
      saveAppointmentUI,
      selectedLocationFull?.timezone,
      sessionId,
    ],
  );

  const approvePatient = async () => {
    if (!data) return;
    try {
      const res = await patientService.approve(
        me.selectedClinic?.ID || -1,
        data.patientId,
      );
      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        (prev: Appointment | undefined) => {
          if (!prev) return {} as Appointment;
          return {
            ...prev,
            patientId: res.ID,
            displayValues: {
              ...prev.displayValues,
              pendingPatient: false,
            },
          };
        },
      );
    } catch (err) {
      console.error({ err });
    }
  };

  const checkIn = async () => {
    if (!appointmentId || !selectedLocationFull?.timezone) return;
    setIsCheckingIn(true);
    try {
      const newAppointment = await patientService.updateAppointment(
        me.selectedClinic?.ID,
        sessionId,
        {
          id: appointmentId,
          status: AppointmentStatuses.CheckedIn,
        },
      );
      saveAppointmentUI(newAppointment);
      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        newAppointment,
      );
      queryClient.invalidateQueries(['dashboardAppointments']);
      queryClient.invalidateQueries(['patientTracks']);
      return newAppointment;
    } finally {
      setIsCheckingIn(false);
    }
  };

  const markAsScheduled = async () => {
    if (!appointmentId || !selectedLocationFull?.timezone) return;
    try {
      setIsChangingStatus(true);
      const newAppointment = await patientService.updateAppointment(
        me.selectedClinic?.ID,
        sessionId,
        {
          id: appointmentId,
          status: AppointmentStatuses.Scheduled,
        },
      );
      saveAppointmentUI(newAppointment);
      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        newAppointment,
      );
      queryClient.invalidateQueries(['dashboardAppointments']);
      queryClient.invalidateQueries(['transaction', appointmentId]);

      return newAppointment;
    } catch (err: any) {
      createToast({
        title: 'Error',
        description: err?.response?.data?.message || 'Error saving appointment',
        type: ToastTypes.Fail,
        duration: 5000,
      });
    } finally {
      setIsChangingStatus(false);
    }
  };

  const markAsNoShow = async (fee?: {
    amount: number;
    subtype: string;
    description: string;
    name: string;
  }) => {
    if (!appointmentId || !selectedLocationFull?.timezone) return;
    const newAppointment = await patientService.updateAppointment(
      me.selectedClinic?.ID,
      sessionId,
      {
        id: appointmentId,
        status: AppointmentStatuses.NoShow,
      },
      null,
      false,
      false,
      fee,
    );
    saveAppointmentUI(newAppointment);
    queryClient.setQueryData(
      ['appointments', 'detail', appointmentId],
      newAppointment,
    );
    queryClient.invalidateQueries(['dashboardAppointments']);
    queryClient.invalidateQueries(['transaction', appointmentId]);
    return newAppointment;
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    saveAppointment,
    checkRecurringAvailability,
    checkIn,
    markAsScheduled,
    isCheckingIn,
    markAsNoShow,
    approvePatient,
    saveRecurringAppointments,
    deleteRecurringAppointments,
    updateRecurringAppointments,
    isSubmitting,
    isChangingStatus,
  };
};

export default useAppointment;
