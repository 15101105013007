import React, { useState, useCallback } from 'react';
import {
  CheckIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import IconButton from '../../../../apps/chiroup/src/components/common/IconButton'; // Adjust the import path as needed
import { TrivialTooltip } from './TrivialTooltip'; // Adjust the import path as needed
import classNames from 'classnames';

interface TrimAndTooltipProps {
  len?: number;
  text: string;
  delay?: number; // Optional delay for tooltip visibility
  className?: string;
  copiedClassName?: string;
}

export const TrimAndTooltip: React.FC<TrimAndTooltipProps> = ({
  len = 80,
  text,
  delay = 1000,
  className = 'h-6 w-6',
  copiedClassName = 'text-primary-500',
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), delay);
  }, [delay, text]);

  const fullLength = text ? text.length : 0;

  if (!text || fullLength <= len) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{text}</>;
  }

  const trimmedText = text.substring(0, len) + '...';

  return (
    <div className="flex flex-row space-x-2">
      <div className="flex">{trimmedText}</div>
      <TrivialTooltip text={text} type="info" iconColorClassName="text-black" />
      {copied ? (
        <CheckIcon
          className={classNames(className, copiedClassName)}
          title="Copied!"
        />
      ) : (
        <IconButton
          icon={<ClipboardDocumentListIcon />}
          onClick={handleCopy}
          tooltip="Copy full text to clipboard."
          className={className}
        />
      )}
    </div>
  );
};
