import { PatientTrack } from '@chiroup/core/types/PatientTrack.type';
import { useContext } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';

const getPatientsTracksQuery = (clinicId = -1, patientId: string) => {
  return async (context: QueryFunctionContext) => {
    return patientService.trackList({
      clinicId,
      patientId,
    });
  };
};

const usePatientTracks = ({
  patientId,
  staleTime = 60000,
}: {
  patientId: string;
  staleTime?: number;
}) => {
  const { me } = useContext(MeContext);
  const queryClient = useQueryClient();
  const { data, isFetching, refetch } = useQuery<PatientTrack[]>(
    ['patientTracks', patientId, me.selectedClinic?.ID],
    getPatientsTracksQuery(me.selectedClinic?.ID, patientId),
    {
      staleTime,
      refetchOnMount: true,
      enabled: !!patientId?.length,
      refetchOnWindowFocus: false,
    },
  );

  const saveNewTrack = async (track: PatientTrack) => {
    const res = await patientService.trackCreate({
      clinicId: me.selectedClinic?.ID,
      patientId,
      track,
    });
    queryClient.setQueryData(
      ['patientTracks', patientId, me.selectedClinic?.ID],
      (prev: any) => {
        return prev.concat(res);
      },
    );
  };

  const updateTrack = async (track: PatientTrack) => {
    const res = await patientService.trackUpdate({
      clinicId: me.selectedClinic?.ID,
      patientId,
      track,
    });
    queryClient.setQueryData(
      ['patientTracks', patientId, me.selectedClinic?.ID],
      (prev: any) => {
        return prev.map((track: PatientTrack) => {
          if (track.id === res.id) {
            return res;
          }
          return track;
        });
      },
    );
  };

  const deleteTrack = async (trackId: number) => {
    await patientService.trackDelete({
      clinicId: me.selectedClinic?.ID,
      patientId,
      trackId,
    });
  };

  return {
    data,
    isFetching,
    refetch,
    saveNewTrack,
    updateTrack,
    deleteTrack,
  };
};

export default usePatientTracks;
