import { AlertBlock, LoadingPage, OpenClosedStates } from '@chiroup/components';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  KnownSidecarEnum,
  KnownSidecars,
} from '@chiroup/core/constants/globals';
import { IntegrationInvoice } from '@chiroup/core/types/Invoice.type';
import { PatientTransaction } from '@chiroup/core/types/PatientTransaction.type';
import { useContext, useState } from 'react';
import { MeContext } from '../../../../../contexts/me.context';
import CommonInvoiceModal from '../../../../billing/CommonInvoiceModal';
import List from '../../../../common/list/List';
import useInvoices from './Invoice/hooks/useInvoices';
import TransactionInvoiceListRow from './TransactionInvoiceListRow';
import TransactionPatientInvoice from './TransactionPatientInvoice';
import TransactionSuperbillInvoice from './TransactionSuperbillInvoice';
import WriteOffModal from './WriteOffModal';

type Props = {
  workingTransaction: PatientTransaction | null;
  refetchBalance?: () => void;
  fixAppliedCallback?: (response: any) => void;
  setTransactionManually: (transaction: PatientTransaction) => void;
};
const TransactionInvoiceList: React.FC<Props> = ({
  workingTransaction,
  refetchBalance,
  fixAppliedCallback,
  setTransactionManually,
}) => {
  const { me } = useContext(MeContext);
  const {
    data,
    isFetching,
    refetch,
    restError,
    setDataManually: setInvoiceListManually,
  } = useInvoices({
    patientId: workingTransaction?.patient?.id ?? '',
    billingKey: workingTransaction?.billingKey ?? '',
    returnIntegrationInvoices: true,
    // trace: true,
  });
  const [selectedInvoice, setSelectedInvoice] =
    useState<IntegrationInvoice | null>(null);
  const [invoiceModalState, setInvoiceModalState] = useState(false);
  const [writeOffInvoice, setWriteOffInvoice] =
    useState<IntegrationInvoice | null>(null);
  const [superbillInvoice, setSuperbillInvoice] =
    useState<IntegrationInvoice | null>(null);

  const [displayPatientInvoiceId, setDisplayPatientInvoiceId] = useState<
    string | number | null
  >(null);
  const { hasAccess } = useContext(MeContext);

  const clickInvoiceCallback = (invoice: IntegrationInvoice) => {
    // console.log({ invoice });
    if (invoice?.payorId) {
      setSelectedInvoice((p) => {
        p = invoice;
        return p;
      });
      setInvoiceModalState(true);
    } else {
      setDisplayPatientInvoiceId(invoice.id);
    }
  };

  const updateInvoiceCallback = ({
    invoice,
  }: {
    invoice: IntegrationInvoice | null | undefined;
    fetch?: boolean;
  }) => {
    // clog({ updateInvoiceCallback: invoice });
    if (!invoice) return;
    const sidecar = (invoice as any)?._sidecar as KnownSidecars | undefined;
    setSelectedInvoice((p) => {
      p = invoice;
      return p;
    });
    // console.log({ sidecar });s
    if (sidecar?.[KnownSidecarEnum.Invoices]) {
      // Some testing code so you can see if the uI is updating.
      // const nobj = (
      //   sidecar[KnownSidecarEnum.Invoices] as IntegrationInvoice[]
      // ).map((i: IntegrationInvoice, ord) => {
      //   i.number = String(ord);
      //   return i;
      // });
      setInvoiceListManually(sidecar[KnownSidecarEnum.Invoices]);
    }
    if (sidecar?.[KnownSidecarEnum.Transaction]) {
      // Some testing code so you can see if the uI is updating.
      // const nobj = sidecar[KnownSidecarEnum.Transaction] as PatientTransaction;
      // nobj.items = nobj.items.map((i) => {
      //   i.description = `BWM: ${i.description}`;
      //   return i;
      // });
      setTransactionManually(sidecar[KnownSidecarEnum.Transaction]);
    }
    // refetch(); Bad boy!!!
  };

  if (!hasAccess(FeatureFlags.ehr)) {
    return null;
  }

  return (
    <>
      {isFetching ? (
        <LoadingPage />
      ) : (
        <div className="print:hidden">
          <AlertBlock message={restError} level={`error`} />
          <List
            isFetching={isFetching}
            className="w-full text-left text-sm text-gray-600 overflow-hidden bg-transparent rounded-b-md"
          >
            {data?.map((invoice: IntegrationInvoice) => {
              return (
                <TransactionInvoiceListRow
                  item={invoice}
                  loading={isFetching}
                  key={invoice.id}
                  callbacks={{
                    clickInvoice: clickInvoiceCallback,
                    clickWriteOff: (invoice: IntegrationInvoice) => {
                      setWriteOffInvoice(invoice);
                    },
                    clickSuperbill: (invoice: IntegrationInvoice) => {
                      setSuperbillInvoice(invoice);
                    },
                  }}
                />
              );
            })}
          </List>
        </div>
      )}
      {invoiceModalState ? (
        <CommonInvoiceModal
          invoiceId={selectedInvoice?.id ?? -1}
          state={OpenClosedStates.Open}
          close={(isDirty) => {
            setInvoiceModalState(false);
            // if (isDirty) refetch();
          }}
          clinicId={me?.selectedClinic?.ID ?? -1}
          containerCallback={function (
            _: IntegrationInvoice | undefined | null,
          ): void {
            throw new Error('Function not implemented.');
          }}
          updateInvoiceCallback={updateInvoiceCallback}
          patientBillingServiceOptions={{
            sidecar: {
              [KnownSidecarEnum.Transaction]: true,
              [KnownSidecarEnum.Invoices]: true,
            },
          }}
          fixAppliedCallback={fixAppliedCallback}
          view={'new'}
        />
      ) : null}
      {displayPatientInvoiceId ? (
        <TransactionPatientInvoice
          invoiceId={displayPatientInvoiceId}
          callbacks={{
            onClose: () => {
              setDisplayPatientInvoiceId(null);
            },
          }}
        />
      ) : null}
      {writeOffInvoice ? (
        <WriteOffModal
          invoice={writeOffInvoice}
          callbacks={{
            onClose: () => {
              setWriteOffInvoice(null);
            },
            onSuccess: () => {
              setWriteOffInvoice(null);
              refetch();
              refetchBalance?.();
            },
          }}
        />
      ) : null}
      {superbillInvoice ? (
        <TransactionSuperbillInvoice
          invoice={superbillInvoice}
          callbacks={{
            onClose: () => {
              setSuperbillInvoice(null);
            },
            onSuccess: () => {
              setSuperbillInvoice(null);
              refetch();
            },
          }}
          trace={false}
        />
      ) : null}
    </>
  );
};
export default TransactionInvoiceList;
