import { LoadingPage } from '@chiroup/components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Me } from '@chiroup/core/types/Me.type';
import patientService from '../../services/patient.service';
import { AppointmentHistoryResult } from '@chiroup/core/types/Appointment.type';

dayjs.extend(relativeTime);

type Props = {
  patientId: string;
  me: Me;
};

const SchedulePatientHistory: React.FC<Props> = ({ patientId, me }) => {
  const [loading, setLoading] = useState(true);
  const [appointmentHistory, setAppointmentHistory] =
    useState<AppointmentHistoryResult | null>(null);
  const { selectedClinic, selectedLocation } = me;

  useEffect(() => {
    let isMounted = true;

    async function getHistory() {
      try {
        setLoading(true);
        const res = await patientService.getPatientAppointmentHistory({
          patientId,
          clinicId: selectedClinic?.ID || -1,
          locationId: selectedLocation || -1,
        });
        if (isMounted) {
          setAppointmentHistory(res);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching appointment history:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    if (!appointmentHistory) {
      getHistory();
    }

    return () => {
      isMounted = false;
    };
  }, [appointmentHistory, patientId, selectedClinic, selectedLocation]);

  const constructAppointmentString = (appointment: {
    id: string | null;
    clinician: string | null;
    startTime: string | null;
  }) => {
    if (!appointment.id) {
      return null;
    }
    const appointmentText = `${appointment.startTime} with ${appointment.clinician}`;
    return (
      <Link
        className="text-primary-600 hover:text-primary-400"
        to={`/schedule?open=${appointment.id}`}
      >
        {appointmentText}
      </Link>
    );
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div className="sm:col-span-2 justify-between rounded-2xl bg-gray-50 p-6">
        <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">
          {appointmentHistory?.totalCheckedIn}
        </p>
        <div className="w-full flex flex-col gap-2">
          <p className="text-lg font-semibold tracking-tight text-gray-900">
            Total appointments
          </p>
          <div className="flex flex-col gap-1 text-sm">
            {appointmentHistory?.previousAppointment?.id && (
              <p className=" text-gray-600">
                <span className="font-semibold">Previous: </span>
                {constructAppointmentString(
                  appointmentHistory.previousAppointment,
                )}
              </p>
            )}
            {appointmentHistory?.nextAppointment?.id && (
              <p className=" text-gray-600">
                <span className="font-semibold">Next: </span>
                {constructAppointmentString(appointmentHistory.nextAppointment)}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="justify-between rounded-2xl bg-gray-900 dark:bg-darkGray-700 p-6">
        <p className="flex-none text-3xl font-bold tracking-tight text-white">
          {appointmentHistory?.totalScheduledFuture}
        </p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-white">
            Upcoming
          </p>
        </div>
      </div>
      <div className="justify-between rounded-2xl bg-primary-600 p-6">
        <p className="flex-none text-3xl font-bold tracking-tight text-white">
          {appointmentHistory?.totalCanceled}
        </p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-white">
            Canceled
          </p>
        </div>
      </div>
      <div className="justify-between rounded-2xl bg-primary-600 p-6">
        <p className="flex-none text-3xl font-bold tracking-tight text-white">
          {appointmentHistory?.totalNoShow}
        </p>
        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
          <p className="text-lg font-semibold tracking-tight text-white">
            No shows
          </p>
        </div>
      </div>
    </div>
  );
};

export default SchedulePatientHistory;
