import { InfoItemHtml, LoadingPage, TrivialTooltip } from '@chiroup/components';
import {
  ACTIVITY_CHANGE_TYPE,
  CRUD_ENUM,
  STRING_BOOLEAN_HASH,
} from '@chiroup/core/constants/globals';
import { classNames } from '@chiroup/core/functions/classNames';
import { formatCurrency } from '@chiroup/core/functions/format';
import { titleCase } from '@chiroup/core/functions/titleCase';
import {
  PatientTransactionActivity,
  TransactionActivityType,
} from '@chiroup/core/types/PatientTransaction.type';
import { ArrowPathIcon, PencilIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useContext } from 'react';
import { MeContext } from '../../../../../contexts/me.context';
import TabActivityItem from './TabActivitySummary';
import useTransactionActivity from './useTransactionActivity';

dayjs.extend(relativeTime);

export function transactionItemActivityMessage(
  item: ACTIVITY_CHANGE_TYPE,
  version?: number,
): string {
  const { field: fieldLabel, operation, from: fromValue, to: toValue } = item;

  const moneyFields = {
    balance: true,
    'Patient allocation amount': true,
    amount: true,
  } as STRING_BOOLEAN_HASH;
  const numberFields = {
    units: true,
  } as STRING_BOOLEAN_HASH;
  const booleanFields = {
    'Electronic Billing': true,
  } as STRING_BOOLEAN_HASH;
  const isMoneyField = fieldLabel ? moneyFields[fieldLabel] : false;
  const isNumberField = fieldLabel ? numberFields[fieldLabel] : false;
  const isBooleanField = fieldLabel ? booleanFields[fieldLabel] : false;

  let fromVal = fromValue ?? '';
  let toVal = toValue ?? '';

  if (isMoneyField) {
    fromVal = formatCurrency(+fromVal);
    toVal = formatCurrency(+toVal);
  } else if (isNumberField) {
    fromVal = String(+fromVal);
    toVal = String(+toVal);
  } else if (isBooleanField) {
    fromVal = !!fromVal === true ? 'yes' : 'no';
    toVal = !!toVal === true ? 'yes' : 'no';
  } else {
    toVal = `"${toVal}"`;
    fromVal = `"${fromVal}"`;
  }

  const fieldLabelToUse = fieldLabel ? titleCase(fieldLabel) : '- anon field -';

  const messageHasPeriod = /\.$/.test(item?.msg ?? '');
  if (!!item?.msg && !messageHasPeriod) {
    item.msg = `${item?.msg}.`;
  }

  if (item?.operation === CRUD_ENUM.Create && version === 0) {
    const ret = 'Created.';
    if (item?.field === 'All') {
      return ret;
    } else if (item?.field === 'Item') {
      return item?.msg || ret;
    }
  }

  if (version === 2) {
    switch (operation) {
      case CRUD_ENUM.Create:
        if (item?.field && item?.to) {
          return `${fieldLabelToUse} set to ${toVal}.`;
        }
        if (item?.msg) {
          return item?.msg;
        }
        return 'Created.';
      case CRUD_ENUM.Delete:
        if (item?.field?.toLocaleLowerCase() === 'item' && item?.msg) {
          return `Deleted ${item?.msg}`;
        }
        if (isNumberField || isMoneyField) {
          return `Set ${item?.field} to 0 from ${fromVal}.`;
        }
        if (fromVal && fromVal !== '""') {
          return `Cleared ${item?.field} from ${fromVal}.`;
        }
        return `Deleted ${fieldLabelToUse}.`;
      case CRUD_ENUM.Update:
        if (item?.msg && !item?.to && !item?.from) {
          return item?.msg;
        }
        return `${fieldLabelToUse} updated from ${fromVal} to ${toVal}.`;
      case CRUD_ENUM.Read:
        return item?.msg || `${fieldLabelToUse} read.`;
      default:
        return `Unknown operation ${operation} for ${fieldLabelToUse}.`;
    }
  }

  switch (operation) {
    case CRUD_ENUM.Create:
      if (toVal === '""' && item?.msg && !item?.field) {
        return item?.msg;
      }
      return `${fieldLabelToUse} created with ${toVal}.`;
    case CRUD_ENUM.Delete:
      return `${fieldLabelToUse} removed (previously ${fromVal}).`;
    case CRUD_ENUM.Update:
      return `${fieldLabelToUse} updated from ${fromVal} to ${toVal}.`;
    case CRUD_ENUM.Read:
      return item?.msg || `${fieldLabelToUse} read.`;
    default:
      return `Unknown operation ${operation} for ${fieldLabelToUse}.`;
  }
}

const activityIcons: {
  [key in TransactionActivityType]: React.ReactNode;
} = {
  [TransactionActivityType.Created]: (
    <ArrowPathIcon
      className="h-4 w-4 text-gray-500 dark:text-white"
      aria-hidden="true"
    />
  ),
  [TransactionActivityType.Updated]: (
    <PencilIcon
      className="h-4 w-4 text-gray-500 dark:text-white"
      aria-hidden="true"
    />
  ),
  [TransactionActivityType.PayorInvoiced]: (
    <ArrowPathIcon className="h-4 w-4 text-gray-500 dark:text-white transform rotate-180" />
  ),
};

type Props = {
  billingKey: string | null | undefined;
  containerClassName?: string;
};

export const TabActivity: React.FC<Props> = ({
  billingKey,
  containerClassName = 'w-full p-4 flex flex-col justify-center',
}) => {
  const { selectedLocationFull } = useContext(MeContext);

  const { isFetching, data } = useTransactionActivity({
    billingKey: billingKey as string,
  });
  // console.log({ TabActivity: { isFetching, data } });

  if (!billingKey) {
    return (
      <div
        className={classNames(
          'text-gray-400 dark:text-darkGray-400 italic text-md flex justify-center px-4 py-2',
          containerClassName,
        )}
      >
        No billing key was provided.
      </div>
    );
  }

  if (!isFetching && !data) {
    return (
      <div
        className={classNames(
          'text-gray-400 dark:text-darkGray-400 italic text-md flex justify-center px-4 py-2',
          containerClassName,
        )}
      >
        'No activity found.'
      </div>
    );
  }

  const description = (activityItem: PatientTransactionActivity) => {
    const displayName =
      activityItem?.updated?.displayName ??
      `${activityItem?.id} (name unavailable)`;
    return `Activity by ${displayName}`;
  };

  return isFetching ? (
    <LoadingPage />
  ) : (
    <ul className="space-y-6">
      {data?.map((activityItem, activityItemIdx) => (
        <li key={activityItem.id} className="relative flex gap-x-4">
          <div
            className={classNames(
              activityItemIdx === data?.length - 1 ? 'h-6' : '-bottom-6',
              'absolute left-0 top-0 flex w-6 justify-center',
            )}
          >
            <div className="w-px bg-gray-300 dark:bg-darkGray-600" />
          </div>
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:bg-darkGray-800">
            {activityIcons[activityItem.type] ? (
              <div className="flex justify-center items-center h-6 w-6 rounded-full bg-gray-300 dark:bg-darkGray-700 ring-1 ring-gray-200 dark:ring-darkGray-600">
                {activityIcons[activityItem.type]}
              </div>
            ) : (
              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 dark:bg-darkGray-400 ring-1 ring-gray-300 dark:ring-darkGray-600" />
            )}
          </div>
          <div className="flex-auto flex flex-col gap-2">
            <div className="flex-auto flex">
              <InfoItemHtml
                className="flex-auto py-0.5 text-xs leading-5 text-gray-500 dark:text-darkGray-400"
                value={description(activityItem)}
              />
              <span className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-darkGray-400">
                <TrivialTooltip
                  text={dayjs(activityItem.createdAt)
                    .tz(selectedLocationFull?.timezone)
                    .format('MM/DD/YYYY [at] h:mm A')}
                  type={`info`}
                />
                {` `}
                {dayjs(activityItem.createdAt).fromNow()}
              </span>
            </div>
            <TabActivityItem activityItem={activityItem} />
          </div>
        </li>
      ))}
    </ul>
  );
};
