import { Currency } from '@chiroup/components';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { PatientInvoiceItem } from '@chiroup/core/types/PatientInvoice.type';

type InvoiceTableRowProps = {
  item: PatientInvoiceItem;
  // showItemAmounts: boolean;
};
const InvoiceItemTableRow: React.FC<InvoiceTableRowProps> = ({
  item,
  // showItemAmounts,
}) => {
  return (
    <tr className="text-xs odd:bg-white even:bg-gray-100 dark:odd:bg-slate-900 dark:even:bg-slate-800">
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.description}
      </td>
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.type}
      </td>
      <td
        className={`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 `}
      >
        {item.units}
      </td>
      <td className="`whitespace-nowrap py-1 pl-4 pr-3 font-medium text-gray-900 sm:pl-6 text-right">
        <Currency
          value={createDecimal(item.amount || 0)
            .times(item.units ?? 1)
            .toNumber()
            .toFixed(2)}
        />
      </td>
    </tr>
  );
};

export default InvoiceItemTableRow;
