import {
  DateTime,
  getNearest,
  getNearestTime,
  Input,
  Select,
  SelectOption,
} from '@chiroup/components';
import { NUMBER_ANY_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpDayJsCommon } from '@chiroup/core/constants/stringConstants';
import { createDayjs } from '@chiroup/core/functions/time';
import {
  Appointment,
  AppointmentStatuses,
} from '@chiroup/core/types/Appointment.type';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { MeContext, SelectedLocationFullType } from '../../contexts/me.context';
import ButtonGroup, { ButtonGroupType } from '../common/buttons/ButtonGroup';
import IconButton from '../common/IconButton';

const negativeOneValueMessage = `Purchase will be assigned to the primary clinician.`;

type Props = {
  ord: number;
  slot: Appointment;
  readonly?: boolean;
  options: any;
  location?: SelectedLocationFullType;
  context: NUMBER_ANY_HASH;
  disableButtonGroup?: boolean;
  setContext: Dispatch<SetStateAction<NUMBER_ANY_HASH>>;
  deleteMySlot?: (ord: number, options: any) => void;
  updateMySlot: ({
    ord,
    key,
    value,
  }: {
    ord: number;
    key: string;
    value: any;
  }) => void;
  addSlotButton?: JSX.Element;
  patientId?: string;
  firstProviderSlotIndex?: { [key: string]: number };
  trackOptions: SelectOption[];
  onClose: () => void;
  setValue: Dispatch<SetStateAction<any>>;
  timezone: string;
  hideCreateCCS?: boolean;
  primaryStatus?: AppointmentStatuses;
  treatmentHash?: any;
  trackHashSendSurveys?: {
    [key: string]: boolean;
  };
  isFetchingPatientTracks?: boolean;
};

const ScheduleAppointmentFormSlot: React.FC<Props> = ({
  ord,
  slot,
  readonly,
  options,
  location,
  context,
  disableButtonGroup = false,
  setContext,
  updateMySlot,
  addSlotButton,
  firstProviderSlotIndex,
  trackOptions,
  deleteMySlot,
  onClose,
  setValue,
  timezone,
  hideCreateCCS = false,
  primaryStatus,
  treatmentHash,
  trackHashSendSurveys,
  isFetchingPatientTracks = false,
}: Props) => {
  /* const trace = { ord, slot, readonly, location } */
  const [enableCustomDuration, setEnableCustomDuration] = useState(false);
  const [localErrors, setLocalErrors] = useState('');
  const { selectedLocationFull } = useContext(MeContext);

  const showTrackInfo = useMemo(() => {
    const hasValidClinician = slot?.clinicianId && slot.clinicianId !== '-1';
    const isFirstProviderSlot =
      firstProviderSlotIndex?.[slot?.clinicianId] === ord;
    const hasTrackOptions = Boolean(trackOptions?.length);

    return hasValidClinician && isFirstProviderSlot && hasTrackOptions;
  }, [slot?.clinicianId, firstProviderSlotIndex, ord, trackOptions]);

  useEffect(() => {
    //Early return
    if (
      !showTrackInfo ||
      (slot.trackId && slot.trackId !== -1) ||
      slot?.createTrackCCSSubmission ||
      isFetchingPatientTracks ||
      readonly
    ) {
      return;
    }

    let newTrackId = slot.trackId;
    const { clinicianId, treatmentId } = slot;

    // Exact match (provider & treatment)
    const exactMatches = trackOptions.filter((option) => {
      const { providers = [], treatments = [] } = option?.data || {};
      return (
        providers.includes(clinicianId) && treatments.includes(treatmentId)
      );
    });

    if (exactMatches.length > 0) {
      newTrackId = exactMatches[0].value;
    } else {
      // Provider-only match
      const providerMatches = trackOptions.filter((option) => {
        const { providers = [] } = option?.data || {};
        return providers.includes(clinicianId);
      });

      if (providerMatches.length > 0) {
        newTrackId = providerMatches[0].value;
      } else {
        // Treatment-only match
        const treatmentMatches = trackOptions.filter((option) => {
          const { treatments = [] } = option?.data || {};
          return treatments.includes(treatmentId);
        });

        if (treatmentMatches.length > 0) {
          newTrackId = treatmentMatches[0].value;
        } else {
          newTrackId = -1;
        }
      }
    }

    // Only update if we're actually changing the value
    if (slot.trackId !== newTrackId) {
      updateMySlot({ ord, key: 'trackId', value: newTrackId });
    }
  }, [
    trackOptions,
    slot,
    updateMySlot,
    ord,
    firstProviderSlotIndex,
    readonly,
    showTrackInfo,
    isFetchingPatientTracks,
  ]);

  const startTimeDayJs = useMemo(() => {
    return createDayjs({ datetime: slot.startTime, timezone });
  }, [slot?.startTime, timezone]);

  const myRef = useRef(null);
  const navigate = useNavigate();

  const getButtons = useMemo(() => {
    if (primaryStatus !== AppointmentStatuses.CheckedIn) {
      return [];
    }
    const buttons: ButtonGroupType[] = [];

    if (firstProviderSlotIndex?.[slot?.clinicianId] === ord) {
      buttons.push({
        label: 'Encounter',
        to: `/patients/${slot?.patientId}/encounters/${slot?.id}`,
      });
    }

    const patientNameWithoutPreferred =
      slot?.displayValues?.patientName?.replace(/\s\(.*\)/g, '');

    const defaultQueryParams = [
      `patient=${slot?.patientId}`,
      `patientName=${encodeURIComponent(
        patientNameWithoutPreferred ?? '- no name -',
      )}`,
      `clinician=${slot?.clinicianId}`,
      `treatment=${slot?.treatmentId}`,
      `track=${slot?.trackId}`,
      `open=add`,
    ];

    let appointmentLabel = 'Next Appointment';
    let nextEncounterDate: dayjs.Dayjs;
    if (
      slot?.displayValues?.clinicianName?.length &&
      slot?.displayValues?.nextRequestedEncounterDate
    ) {
      nextEncounterDate = createDayjs({
        datetime: slot.displayValues.nextRequestedEncounterDate,
        timezone: selectedLocationFull?.timezone as string,
      }) as Dayjs;
      const thisAppointment = createDayjs({
        datetime: slot?.startTime as number,
        timezone: selectedLocationFull?.timezone as string,
      }) as Dayjs;
      const hourOfThisAppointment = thisAppointment.hour() || 0;
      const minuteOfThisAppointment = thisAppointment.minute() || 0;
      const nextEncounterUTC = nextEncounterDate
        .hour(hourOfThisAppointment)
        .minute(minuteOfThisAppointment)
        .valueOf();
      defaultQueryParams.unshift(
        `endDate=${nextEncounterDate.format('YYYY-MM-DD')}`,
      );
      defaultQueryParams.unshift(
        `startDate=${nextEncounterDate.format('YYYY-MM-DD')}`,
      );
      defaultQueryParams.unshift(`startTime=${nextEncounterUTC}`);

      appointmentLabel = `Schedule appointment on ${nextEncounterDate.format(
        'M/DD/YYYY',
      )}`;
    }
    const scheduleUrl = `/schedule?${defaultQueryParams.join('&')}`;
    buttons.push({
      label: appointmentLabel,
      onClick: () => {
        onClose();
        setValue({
          patientId: slot.patientId,
          treatmentId: Number(slot.treatmentId),
          clinicianId: slot.clinicianId,
          startTime: dayjs.tz(
            nextEncounterDate
              ? Number(nextEncounterDate)
              : nextEncounterDate
                ? getNearestTime(nextEncounterDate)
                : getNearest(timezone),
            timezone,
          ),
          duration: Number(slot.duration),
          slots: [
            {
              clinicianId: slot.clinicianId,
              treatmentId: Number(slot.treatmentId),
              duration: Number(slot.duration),
              startTime: dayjs
                .tz(
                  nextEncounterDate
                    ? Number(nextEncounterDate)
                    : nextEncounterDate
                      ? getNearestTime(nextEncounterDate)
                      : getNearest(timezone),
                  timezone,
                )
                .valueOf(),
              roomId: slot.roomId,
              trackId: slot.trackId,
            },
          ],
        });

        navigate(scheduleUrl);
      },
    });
    return buttons;
  }, [
    primaryStatus,
    slot,
    onClose,
    setValue,
    timezone,
    navigate,
    firstProviderSlotIndex,
    ord,
    selectedLocationFull?.timezone,
  ]);

  useEffect(() => {
    setContext((prev) => ({
      ...prev,
      [ord]: {
        ...prev[ord],
        ref: myRef,
      },
    }));
  }, [ord, setContext]);

  const nav = useMemo(() => {
    if (!context) return [];
    return Object.entries(context)
      .map(([key, value], idx) =>
        Number(key) === ord ? (
          <li
            key={`nav-${ord}-${idx}`}
            className="flex w-3 h-3 font-sans text-xs font-semibold border text-white border-primary-600 bg-primary-600 p-3 rounded-full justify-center items-center cursor-pointer"
          >
            {Number(key) + 1}
          </li>
        ) : (
          <li
            key={`nav-${ord}-${idx}`}
            className="flex w-3 h-3 font-sans text-xs font-semibold border text-gray-400 border-gray-400 p-3 rounded-full justify-center items-center hover:text-white hover:bg-primary-500 cursor-pointer"
            onClick={(e: any) => {
              console.log('onClick slot', key);
              e.stopPropagation();
              e.preventDefault();
              context?.[Number(key)]?.ref?.current?.scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            {Number(key) + 1}
          </li>
        ),
      )
      .concat(
        addSlotButton ? (
          <li
            key={`add-slot-btn`}
            style={{ marginLeft: '-3px' }}
            className="pt-2"
          >
            {addSlotButton}
          </li>
        ) : (
          <li key={`add-slot-btn`}></li>
        ),
      );
  }, [addSlotButton, context, ord]);

  const readonlyInputClassName = 'bg-inherit border-gray-100';

  if (readonly) {
    return (
      <div id={`stop-${ord + 1}`} className="mt-4" ref={myRef}>
        <div className="flex flex-row space-x-4 mt-8">
          <div className="flex w-8 justify-end">
            <ul className="space-y-1">{nav.map((item) => item)}</ul>
          </div>
          <div
            className={classNames(
              readonlyInputClassName,
              'flex flex-col space-y-2 w-full border border-gray-300 px-4 pt-5 pb-5 relative rounded-lg',
            )}
          >
            <div>
              <Input
                name="clinicianId"
                label="Clinician"
                value={
                  (
                    options?.clinicians?.find(
                      (c: { value: string | number; text: string }) =>
                        c.value === slot.clinicianId,
                    ) ??
                    ({
                      value: -1,
                      text: `- ${slot?.clinicianId ?? 'primary'} -`,
                    } as any)
                  ).text
                }
                inputClassName={readonlyInputClassName}
                disabled={true}
                inline={true}
                inlineInputWidth="w-1/2"
                inlineLabelWidth="z-20 relative pl-2 w-1/2"
                tooltip={
                  slot?.clinicianId === '-1' ? negativeOneValueMessage : ''
                }
              />
            </div>
            <div>
              <Input
                name="roomId"
                label="Room"
                value={
                  (
                    options?.rooms?.find(
                      (c: { value: string | number; text: string }) =>
                        String(c.value) === String(slot.roomId),
                    ) ??
                    ({
                      value: -1,
                      text: `- none -`,
                    } as any)
                  ).text
                }
                inputClassName={readonlyInputClassName}
                disabled={true}
                inline={true}
                inlineInputWidth="w-1/2"
                inlineLabelWidth="pl-2 w-1/2"
              />
            </div>
            {slot?.clinicianId !== '-1' &&
              firstProviderSlotIndex &&
              firstProviderSlotIndex[slot.clinicianId] === ord && (
                <div>
                  <Input
                    name="trackId"
                    label="Track"
                    value={
                      slot?.createTrackCCSSubmission &&
                      (!slot?.trackId || slot?.trackId === -1)
                        ? 'Created on check-in'
                        : (
                            trackOptions?.find(
                              (c: any) =>
                                String(c.value) === String(slot.trackId),
                            ) ??
                            ({
                              value: -1,
                              text: `- none -`,
                            } as any)
                          ).text
                    }
                    inputClassName={readonlyInputClassName}
                    disabled={true}
                    inline={true}
                    inlineInputWidth="w-1/2"
                    inlineLabelWidth="pl-2 w-1/2"
                    tooltip={
                      slot?.trackId && !trackHashSendSurveys?.[slot?.trackId]
                        ? 'Surveys are not sent for this track'
                        : ''
                    }
                  />
                </div>
              )}

            <Input
              name="treatmentId"
              label="Treatment"
              value={
                (
                  options?.treatments?.find(
                    (c: { value: string | number; text: string }) =>
                      Number(c.value) === slot.treatmentId,
                  ) ?? ({ value: -1, text: `- ${slot.treatmentId} -` } as any)
                ).text
              }
              inputClassName={readonlyInputClassName}
              disabled={true}
              inline={true}
              inlineInputWidth="w-1/2"
              inlineLabelWidth="pl-2 w-1/2"
              tooltip={
                slot?.treatmentId &&
                !!treatmentHash?.objs?.[slot.treatmentId]
                  ?.suppressAutomatedSurveys
                  ? 'Surveys are not sent for this treatment'
                  : ''
              }
            />

            <Input // Display the start time
              name="startTime"
              label="Start and Duration"
              value={`${startTimeDayJs
                ?.tz(location?.timezone)
                ?.format(ChiroUpDayJsCommon.format.time)} for ${
                slot.duration
              } minutes`}
              inputClassName={readonlyInputClassName}
              disabled={true}
              inline={true}
              inlineInputWidth="w-1/2"
              inlineLabelWidth="pl-2 w-1/2"
            />
            {slot.clinicianId && (
              <ButtonGroup
                buttons={getButtons}
                disabled={disableButtonGroup}
                isEmptyOkay={true}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={`stop-${ord + 1}`} className="pt-3 relative" ref={myRef}>
      <div className="flex flex-row space-x-4">
        <div className="flex w-10 justify-center">
          <ul className="space-y-1">{nav.map((item) => item)}</ul>
        </div>
        <div className="flex flex-col space-y-2 w-full bg-white border dark:bg-darkGray-800 dark:border-darkGray-700 py-4 px-6 relative rounded-lg">
          {/* <div
            className="w-24 z-0 bg-gray-50 rounded-lg absolute top-6 border border-gray-200 left-3"
            style={{ height: '5.5rem' }}
          >
            {' '}
          </div> */}
          <Select
            name="clinicianId"
            label="Clinician"
            value={slot.clinicianId}
            onChange={(val) => {
              updateMySlot({ ord, key: 'clinicianId', value: val });
            }}
            options={options.clinicians}
            limit={1}
            disabled={readonly}
            tooltip={
              readonly
                ? 'Provider cannot be changed after the note is signed'
                : slot?.clinicianId === '-1'
                  ? negativeOneValueMessage
                  : ''
            }
            tooltipClassName="w-56"
            inlineSelect={true}
            inlineLabelWidth="w-1/4 relative"
            inlineInputWidth="w-3/4"
            autocomplete
          />
          <Select
            name="treatmentId"
            label="Treatment"
            value={slot.treatmentId}
            onChange={(val) => {
              updateMySlot({ ord, key: 'treatmentId', value: val });
            }}
            options={options.treatments}
            limit={1}
            inlineSelect={true}
            inlineLabelWidth="w-1/4"
            inlineInputWidth="w-3/4"
            tooltip={
              slot?.treatmentId &&
              !!treatmentHash?.objs?.[slot.treatmentId]
                ?.suppressAutomatedSurveys
                ? 'Surveys are not sent for this treatment'
                : ''
            }
          />
          <Select
            name="roomId"
            className="col-span-2"
            label="Room"
            value={slot.roomId}
            onChange={(val: any) => {
              updateMySlot({ ord, key: 'roomId', value: val });
            }}
            options={options.rooms}
            disabled={readonly}
            limit={1}
            inlineSelect={true}
            inlineLabelWidth="w-1/4 relative"
            inlineInputWidth="w-3/4"
            autocomplete
          />
          {showTrackInfo && (
            <Select
              name="trackId"
              label="Track"
              value={slot.trackId}
              onChange={(val) => {
                updateMySlot({ ord, key: 'trackId', value: val });
              }}
              options={trackOptions}
              limit={1}
              disabled={readonly || !trackOptions.length}
              tooltip={
                readonly
                  ? 'Tracks cannot be changed after the note is signed'
                  : slot?.trackId && !trackHashSendSurveys?.[slot?.trackId]
                    ? 'Surveys are not sent for this track'
                    : ''
              }
              inlineSelect={true}
              inlineLabelWidth="w-1/4 relative"
              inlineInputWidth="w-3/4"
              autocomplete
            />
          )}

          <DateTime
            label="Date"
            timeLabel="Time"
            value={startTimeDayJs as Dayjs}
            onChange={(val) => {
              // console.log('onChange dateTime', val.toDate());
              updateMySlot({ ord, key: 'startTime', value: val?.valueOf() });
            }}
            timezone={location?.timezone ?? ChiroUpDayJsCommon.defaultTimezone}
            localErrors={localErrors}
            setLocalErrors={setLocalErrors}
            // startTimeInterval={startTimeInterval}
            omitDate={true}
            timeContainerClassName="flex flex-row w-full"
            labelClassName="mb-1 pt-2.5 text-sm font-medium leading-5 text-gray-900 sm:mt-px dark:text-darkGray-200 flex flex-row space-x-2 w-1/4"
            inputContainerClassName="w-3/4"
          />
          {!enableCustomDuration ? (
            <Select
              name="duration"
              label="Duration"
              value={slot.duration}
              onChange={(val) => {
                // console.log('onChange durationSelect', val);
                updateMySlot({ ord, key: 'duration', value: val });
              }}
              options={options.durations}
              limit={1}
              className="min-w-1/3 w-full"
              labelClassName="mb-1 block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2 dark:text-darkGray-200"
              inlineSelect={true}
              inlineLabelWidth="w-1/4"
              inlineInputWidth="w-3/4"
              hint="In minutes."
            />
          ) : (
            <Input
              name="duration"
              label="Duration"
              hint="In minutes."
              value={slot.duration}
              onChange={(val) => {
                updateMySlot({ ord, key: 'duration', value: val });
                // console.log('onChange durationInput', val);
              }}
              className="w-full flex flex-row"
              //   labelClassName="whitespace-nowrap"
              type="number"
              min="0"
              inline={true}
              inlineLabelWidth="w-1/4"
              inlineInputWidth="w-3/4"
            />
          )}
          <CustomDurationButton
            value={enableCustomDuration}
            toggle={setEnableCustomDuration}
          />
          {/* The length is the # of time slots plus the button to add a new slot.  */}
          {nav.length !== 2 && deleteMySlot ? (
            <div className="flex justify-end cursor-pointer text-primary-500 absolute -top-8 right-0">
              <IconButton
                onClick={(e) => {
                  e?.stopPropagation?.();
                  deleteMySlot?.(ord, options);
                }}
                tooltip={`Delete.`}
                className="text-primary-500 w-5 h-5"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                }
              />
            </div>
          ) : null}
        </div>
      </div>
      {/* <pre>{ChiroUpJSON.pretty({ slot })}</pre> */}
    </div>
  );
};

type ToggleSubComponentProps = {
  value: boolean;
  toggle: Dispatch<SetStateAction<boolean>>;
};
const CustomDurationButton: React.FC<ToggleSubComponentProps> = ({
  value,
  toggle,
}) => {
  return (
    <div
      className="absolute bottom-4"
      onClick={() => {
        toggle((prev) => !prev);
      }}
    >
      <button className="px-2 shadow-md no-underline rounded-full bg-primary-500 text-white font-sans text-xs border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none">
        {!value ? `Custom` : `Preset`}
      </button>
    </div>
  );
};

export default ScheduleAppointmentFormSlot;
