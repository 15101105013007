import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../contexts/me.context';
import invoiceNoteService from '../../../services/invoiceNotes.service';
import { IntegrationInvoice } from '@chiroup/core/types/Invoice.type';

const getQuery = (clinicId: number, invoiceId: number) => {
  return () => {
    return invoiceNoteService.getAll(clinicId, invoiceId);
  };
};

const useInvoiceNotes = ({
  invoiceId = -1,
  disabled = false,
  useIntegrationInvoicesQueryKey = undefined,
}: {
  invoiceId?: number;
  disabled: boolean;
  useIntegrationInvoicesQueryKey?: any[] | null | undefined;
}) => {
  const { me } = useContext(MeContext);
  const clinicId = me.selectedClinic?.ID;
  const queryClient = useQueryClient();

  const { status, data, error, isFetching, refetch } = useQuery<any>(
    [`invoice-notes`, clinicId, invoiceId],
    getQuery(clinicId!, invoiceId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !disabled,
    },
  );

  const save = async (val: Partial<{ note: string }>) => {
    if (!clinicId || !invoiceId) {
      throw new Error('No clinic or invoice selected.');
    }
    const resp = await invoiceNoteService.create(
      clinicId!,
      invoiceId,
      val.note!,
    );

    if (resp && resp?.notes) {
      queryClient.setQueryData(
        ['invoice-notes', clinicId, invoiceId],
        (p: any) => ({ ...p, rows: resp.notes }),
      );
    }
    return resp;
  };

  const remove = async (noteId: number) => {
    if (!clinicId) {
      throw new Error('No clinic selected');
    }
    const resp = (await invoiceNoteService.remove(
      clinicId,
      invoiceId,
      noteId,
    )) as any;

    const json = await resp?.body?.json?.();
    if (json && json?.notes) {
      queryClient.setQueryData(
        ['invoice-notes', clinicId, invoiceId],
        (p: any) => {
          return { ...p, rows: json.notes };
        },
      );
    }
    return resp;
  };

  const toggleFlag = async (isFlagged: boolean) => {
    const resp = await invoiceNoteService.toggleFlag(
      clinicId!,
      invoiceId,
      isFlagged,
    );
    if (resp && resp?.invoice) {
      const invoice = resp.invoice as IntegrationInvoice;
      queryClient.setQueryData(
        ['integrationInvoice', invoiceId],
        () => resp.invoice,
      );
      if (useIntegrationInvoicesQueryKey) {
        const existingData = queryClient.getQueryData(
          useIntegrationInvoicesQueryKey,
        ) as any;
        let touched = false;
        const updatedData = existingData?.data?.map(
          (item: IntegrationInvoice) => {
            if (item.id === invoice.id) {
              touched = true;
              return { ...item, ...invoice };
            }
            return item;
          },
        );
        if (!touched) return;
        queryClient.setQueryData(
          useIntegrationInvoicesQueryKey,
          (oldData: any) => {
            return {
              ...oldData,
              data: updatedData,
              dirty: new Date().getTime(), // to force a re-render if needed
            };
          },
        );
      }
    }
    return resp;
  };

  const downloadPdf = () => {
    return invoiceNoteService.downloadPdf(clinicId!, invoiceId);
  };

  return {
    status,
    data,
    error,
    isFetching,
    refetch,
    save,
    remove,
    toggleFlag,
    downloadPdf,
  };
};

export default useInvoiceNotes;
