import {
  Checkbox,
  OpenClosedStates,
  Select,
  SelectOption,
  Textarea,
} from '@chiroup/components';
import { useForm } from '@chiroup/hooks';
import Modal from '../../../common/Modal';
import React, { SetStateAction, useContext, useEffect } from 'react';
import Button, { ButtonColors } from '../../../common/Button';
import { MeContext } from '../../../../contexts/me.context';
import patientService from '../../../../services/patient.service';
import { ToastContext, ToastTypes } from '../../../../contexts/toast.context';
import { FeatureFlags } from '@chiroup/core/constants/flags';
import {
  NoteCategory,
  NoteLocation,
  NoteImportance,
} from '@chiroup/core/enums/NoteTypes.enum';
import { enumToArrayOfOptions } from '@chiroup/core/functions/enumToArrayOfOptions';
import { titleCase } from '@chiroup/core/functions/titleCase';
import { Macro } from '@chiroup/core/types/Macro';
import { PatientNote as PatientNoteType } from '@chiroup/core/types/PatientNote.type';
import { useQueryClient } from 'react-query';

type Props = {
  state: OpenClosedStates;
  setState: React.Dispatch<SetStateAction<OpenClosedStates>>;
  setSelectedNote: React.Dispatch<SetStateAction<PatientNoteType | null>>;
  patientId: string;
  note: PatientNoteType | null;
  refetch: () => void;
  onDelete: () => void;
  macros: Macro[];
};

const defaultValues = {
  category: NoteCategory.General,
  note: '',
  alert: false,
  notification: false,
  location: [],
};

const addSpaceBeforeFirstCapital = (str: string) => {
  const firstCapitalIndex = str.search(/[A-Z]/);
  if (firstCapitalIndex === -1) {
    return titleCase(str);
  }
  return titleCase(
    str.slice(0, firstCapitalIndex) + ' ' + str.slice(firstCapitalIndex),
  );
};

const categoryOptions: SelectOption[] = Object.values(NoteCategory).map(
  (category) => ({
    value: category,
    text: addSpaceBeforeFirstCapital(category),
  }),
);

const PatientNote: React.FC<Props> = ({
  state,
  setState,
  patientId,
  note,
  refetch,
  setSelectedNote,
  onDelete,
  macros,
}) => {
  const queryClient = useQueryClient();
  const { hasAccess, me } = useContext(MeContext);
  const {
    value,
    onChange,
    patchValue,
    errors,
    registerSubmit,
    isSubmitting,
    setValue,
  } = useForm<PatientNoteType>(defaultValues, {
    note: {
      required: {
        message: 'Note is required.',
      },
    },
    location: {
      function: {
        value: (value: Partial<PatientNoteType>) => {
          const { location } = value;
          if (hasAccess(FeatureFlags.ehrNotes) && !location?.length) {
            return 'Location is required.';
          }
          return false;
        },
      },
    },
  });

  const { createToast } = useContext(ToastContext);

  useEffect(() => {
    const getDismissedNotificationValue = (
      dismissedNotification: string[] | boolean | undefined,
    ): boolean => {
      if (Array.isArray(dismissedNotification)) {
        return dismissedNotification.includes(me.ID);
      }
      return Boolean(dismissedNotification);
    };

    if (note?.id) {
      setValue({
        ...note,
        alert: Boolean(note.alert),
        notification: Boolean(note.notification),
        deactivatedAlert: Boolean(note.deactivatedAlert),
        dismissedNotification: getDismissedNotificationValue(
          note.dismissedNotification,
        ),
      });
    } else {
      setValue(defaultValues);
    }
  }, [me.ID, note, setValue]);

  const onSuccess = (type: 'edit' | 'create') => {
    const messageAction = type === 'edit' ? 'edited' : 'created';
    createToast({
      title: 'Success!',
      description: `Successfully ${messageAction} note!`,
      type: ToastTypes.Success,
      duration: 5000,
    });
  };

  const onFail = (type: 'edit' | 'create') => {
    createToast({
      title: 'Error!',
      description: `Failed to ${type} note!`,
      type: ToastTypes.Fail,
      duration: 5000,
    });
  };

  const onSubmit = async () => {
    try {
      const noteMethod = value.id
        ? patientService.updatePatientNote
        : patientService.createPatientNote;

      await noteMethod(value, me.selectedClinic?.ID, patientId);

      patchValue(defaultValues);
      setSelectedNote(null);
      // refetch();
      setState(OpenClosedStates.Closed);
      queryClient.invalidateQueries(['patientNotes', patientId], {});

      onSuccess(value.id ? 'edit' : 'create');
    } catch (error) {
      onFail(value.id ? 'edit' : 'create');
    }
  };

  return (
    <Modal
      isOpen={state === OpenClosedStates.Open}
      close={() => setState(OpenClosedStates.Closed)}
      fit={false}
    >
      <h3
        className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100 pb-6"
        id="modal-headline"
      >
        Patient Memo
      </h3>
      <form>
        <div className="py-6 space-y-6 sm:space-y-0 sm:py-0">
          <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
            {!hasAccess(FeatureFlags.ehrNotes) ? (
              <Select
                name="category"
                label="Category *"
                className={'col-span-2'}
                onChange={onChange('category')}
                value={value.category}
                options={categoryOptions}
                limit={1}
                errors={errors?.fieldErrors?.category}
              />
            ) : (
              <Select
                name="location"
                label="Location(s) *"
                className={'col-span-2'}
                onChange={onChange('location')}
                value={value.location}
                options={enumToArrayOfOptions(NoteLocation)}
                errors={errors?.fieldErrors?.location}
              />
            )}
            <Textarea
              name="note"
              label="Note *"
              className="col-span-4"
              onChange={onChange('note')}
              value={value.note}
              errors={errors?.fieldErrors?.note}
              macros={macros}
            />
            {hasAccess(FeatureFlags.ehrNotes) && (
              <div className="flex flex-col w-full col-span-4">
                <div className="mt-2 mb-2 flex flex-col gap-2">
                  <Checkbox
                    label={titleCase(NoteImportance.Alert)}
                    value={value.alert as boolean}
                    onChange={onChange('alert')}
                    description="Visible always"
                  />
                  <Checkbox
                    label={titleCase(NoteImportance.Notification)}
                    value={value.notification as boolean}
                    onChange={onChange('notification')}
                    description="Visible until dismissed"
                  />
                  {value.notification && (
                    <Checkbox
                      label="Dismissed"
                      className={'ml-5 mt-2'}
                      value={value.dismissedNotification as boolean}
                      onChange={onChange('dismissedNotification')}
                      description="Not visible in patient record for me only"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row gap-2 mt-10">
          <Button
            text="Cancel"
            fullWidth
            onClick={() => {
              setState(OpenClosedStates.Closed);
            }}
            color={ButtonColors.plainWithBorder}
          />
          {!!value.id && (
            <Button
              fullWidth
              text="Delete"
              color={ButtonColors.red}
              onClick={onDelete}
            />
          )}
          <Button
            text="Save"
            fullWidth
            loading={isSubmitting}
            color={ButtonColors.primary}
            onClick={registerSubmit(onSubmit, {})}
          />
        </div>
      </form>
    </Modal>
  );
};
export default PatientNote;
