import { OpenClosedStates } from '@chiroup/components';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import Modal from '../../../../../common/Modal';
import useClaimSetting from '../../../../../settings/clinic/useBillingSettings';
import { getDefaultValues } from '../../../billing/helpers';
import useInsurance from '../../../billing/hooks/useInsurance';
import PatientInsuranceEditPanelInner from '../../../billing/insurance/PatientInsuranceEditPanelInner';

type Props = {
  isOpen: OpenClosedStates;
  close: () => void;
  patient: Patient;
  billingKey: string;
  onInsuranceAdded?: (insurnace: AppointmentInsuranceType) => void;
  serviceCodes?: string[];
};

const EncounterInsuranceModal: React.FC<Props> = ({
  isOpen,
  close,
  patient,
  billingKey,
  onInsuranceAdded,
  serviceCodes,
}) => {
  const { isFetching, del, isDeleting, save } = useInsurance({
    id: 'add',
    patientId: patient?.ID || '',
    billingKey,
    serviceCodes,
  });
  const { data: claimDefaults } = useClaimSetting();

  const saveInsurance = async (val: any) => {
    const newInsurance = await save(val);
    onInsuranceAdded?.(newInsurance);
  };

  return (
    <Modal isOpen={isOpen === OpenClosedStates.Open} close={close}>
      <div className="w-full">
        <div className="flex w-full items-end justify-center text-center sm:block sm:p-0">
          <div
            className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom transition-all dark:bg-darkGray-800 sm:my-8 sm:w-full sm:max-w-xl sm:p-0 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="text-center">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-100"
                  id="modal-headline"
                >
                  Add Insurance
                </h3>
                <div className="mt-2 text-left">
                  <PatientInsuranceEditPanelInner
                    editSlideOverState={isOpen}
                    updateSlideOverState={close}
                    patient={patient}
                    close={close}
                    insurance={getDefaultValues(claimDefaults)}
                    isFetching={isFetching}
                    del={del}
                    isDeleting={isDeleting}
                    save={saveInsurance}
                    claimDefaults={claimDefaults}
                    isAddOnlyModal
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EncounterInsuranceModal;
