import { classNames } from '@chiroup/core/functions/classNames';
import React from 'react';

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  rightSide?: React.ReactNode;
  children?: React.ReactNode;
  squared?: boolean;
  white?: boolean;
  className?: string;
  divider?: boolean;
  hideTitleOnPrint?: boolean;
};

const SubContainer: React.FC<Props> = ({
  title,
  subtitle,
  rightSide,
  children,
  squared,
  white = true,
  className = '',
  divider = true,
  hideTitleOnPrint = false,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col divide-gray-300 dark:divide-darkGray-700 text-gray-900 dark:text-darkGray-50',
        divider ? 'divide-y' : '',
        squared ? '' : 'rounded-lg',
        white ? 'bg-white dark:bg-darkGray-800' : '',
        className,
      )}
    >
      {!!rightSide || !!subtitle ? (
        <div
          className={classNames(
            'flex flex-wrap items-center justify-between sm:flex-nowrap bg-white dark:bg-darkGray-800 min-h-12',
            hideTitleOnPrint ? 'print:hidden' : 'rounded-lg',
          )}
        >
          {(!!title || !!subtitle) && (
            <div className="px-3 w-full flex flex-col gap-1">
              <h3 className="text-base font-semibold leading-3">{title}</h3>
              <div className="text-xs text-gray-500 leading-3">{subtitle}</div>
            </div>
          )}
          {rightSide && <div className="py-3">{rightSide}</div>}
        </div>
      ) : null}
      <div>{children}</div>
    </div>
  );
};

export default SubContainer;
